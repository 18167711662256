import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <footer className="footer-section section-gap-half">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 footer-left">
                            <Link to="/">
                                <img src="./assets/media/logos/logo-small.png" alt="" />
                            </Link>
                            <p><i className="ti-location-pin"></i> 8 Case Mews Gaithersburgh, MD 20878</p>
                            <a href="mailto:office@neo7logix.com">
                                <p><i className="ti-email"></i> office@neo7logix.com</p>
                            </a>
                            <a href="tel:1235235598">
                                <p><i className="ti-mobile"></i>+ 1235 2355 98</p>
                            </a>
                            <p className="copyright-text"> Copyright � 2020 Neo7Logix - All Rights Reserved.</p>
                        </div>
                        <div className="col-lg-7">
                            <div className="footer-title">
                                <h2>WE'RE HERE TO HELP</h2>
                                <p>Call 1-888-4ASSIST (1-888-427-7478)
Monday to Friday � 9:00 am to 8:00 pm ET</p>
                            </div>
                            <ul className="footer-menu">
                                <li>
                                    <Link to="/ContactUs">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/Sitemap" >Sitemap</Link>
                                </li>
                                <li>
                                    <Link to="/TermsOfUse" >Terms of Use</Link>
                                </li>
                                <li>
                                    <Link to="/PrivacyStatements" >Privacy Statement</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

        );
    }
}
