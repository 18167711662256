import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../style-new.css'
import axios from 'axios';

export class Services extends Component {
    static displayName = Services.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            logout: false,
        };

        this.handleClickLogout = this.handleClickLogout.bind(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleClickLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('Usertoken');
        localStorage.setItem('isLoggedIn', false);
        this.setState({ logout: true });
    }

    renderRedirecttohome() {
        if (this.state.logout) {
            return <Redirect from="/" to="/login" />
        }
    }

    render() {

        return (
            <React.Fragment>

                <header className="header-area" id="header-area">
                    <div className="dope-nav-container breakpoint-off">
                        <div className="container">
                            <div className="row">
                                {/* dope Menu */}
                                <nav className="dope-navbar justify-content-between" id="dopeNav">

                                    {/* Logo */}
                                    <Link to="/" className="nav-brand"><img src="img/header-logo.jpg" alt="" /></Link>

                                    {/* Navbar Toggler */}
                                    <div className="dope-navbar-toggler">
                                        <span className="navbarToggler">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </div>

                                    {/* Menu */}
                                    <div className="dope-menu">

                                        {/* close btn */}
                                        <div className="dopecloseIcon">
                                            <div className="cross-wrap">
                                                <span className="top"></span>
                                                <span className="bottom"></span>
                                            </div>
                                        </div>



                                        {localStorage.getItem('token') != null ?
                                            (
                                                <div className="dopenav">
                                                    <div className="top-rightside">
                                                        {(JSON.parse(localStorage.getItem('Usertoken')) != null ?
                                                            (JSON.parse(localStorage.getItem('Usertoken')).userType != 1 ?
                                                                (JSON.parse(localStorage.getItem('Usertoken')).userType != 2 ?
                                                                    (JSON.parse(localStorage.getItem('Usertoken')).userType != 3 ?
                                                                        (JSON.parse(localStorage.getItem('Usertoken')).userType == 4 ?
                                                                            <Link to="/laboratory/dashboard" className="primary-btn" >Dashboard</Link>

                                                                            :
                                                                            <Link to="/manufacturer/dashboard" className="primary-btn" >Dashboard</Link>

                                                                        )
                                                                        :
                                                                        <Link to="/institute/dashboard" className="primary-btn" >Dashboard</Link>

                                                                    )
                                                                    :
                                                                    <Link to="/practitioner/dashboard" className="primary-btn" >Dashboard</Link>

                                                                )
                                                                :
                                                                <Link to="/patient/dashboard" className="primary-btn" >Dashboard</Link>

                                                            )
                                                            : "")
                                                        }
                                                        <Link onClick={this.handleClickLogout} className="primary-btn">Logout</Link>
                                                    </div>
                                                    <ul id="nav">
                                                        <li>
                                                            <Link to="/Services" >Services</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/HealthcareProfessional" >Healthcare Professionals </Link>

                                                        </li>
                                                        <li>
                                                            <Link to="/Caregivers" >Patients and Caregivers</Link>

                                                        </li>
                                                    </ul>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className="dopenav">
                                                    <div className="top-rightside">
                                                        <div className="">
                                                            <ul id="nav">
                                                                <li>
                                                                    <Link to="/Services" >Services</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/HealthcareProfessional" >Healthcare Professionals</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/Caregivers" >Patients and Caregivers</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/login" className="primary-btn">Login</Link>
                                                                </li>
                                                            </ul>

                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>


                <section className="resources-2">
                    <div className="container margintop">
                        <h3 >Next Generation Sequencing</h3>
                        <div className="span"  >
                            <article>
                                <h4 class="margintop">What is Next Generation Sequencing?</h4>
                                <p>Today, next-generation sequencing (NGS) makes it possible to sequence the entire genome and exome within 24-48 hours, building a robust scalable genetic data library on an individual
                                patient which is useful for developing effective strategies in diagnosis, identification of
                                submicroscopic (unseen) targets in disease, precision therapeutic treatments, more effective
disease surveillance and future genetic breakthroughs in medicine and research.</p>
                                <p>NGS technology workflow involves three main steps, library preparation, sequencing and data
                                bioinformatics. DNA and RNA extraction is the first process in beginning to build the library.
                                Small fragments are isolated from liquid and solid biopsy material isolated from the individual
                                and then amplified to process millions of fragments in parallel producing massive amounts of
                                genetic material generating a quick and cost-effective process. This enables an in-depth
                                complete genetic analysis in medicine and research, allowing for a broad spectrum look at
                                samples and offering analysis of a specialized focus area of interest.
</p>
                                <p>NGS is the future of DNA research and health care; the applications of this technology have the
                                potential to enhance individualized patient care. Costs are coming down, and the rate at which
                                we are able to sequence DNA is rapidly increasing, which makes cutting-edge technology
                                accessible to more researchers and clinicians.
</p>
                            </article>
                            <article>
                                <h4 class="">How It Works and Applications?
                                </h4>
                                <p>NGS allows DNA specialists to gather large amounts of genetic data, which has huge
                                applications to health care. This sophisticated sequencing method is helping us better
                                understand how the expression of genetic variants affects us. For example, NGS allows
                                scientists to more easily identify exome mutations, which are thought to contain the vast majority
                                of mutations that lead to human diseases. With NGS, we can efficiently compare the DNA of
                                thousands of people and explore the individual genes that cause conditions such as cancer,
                                viral related disease and infection, autoimmune disease, neurodegenerative disease,
                                schizophrenia, autism and regenerative health.
</p>
                                <p>Place a process flow figure:

</p>
                                <p><b>Sample Extraction------>Library Preparation------>Amplification----->Sequencing</b>
                                </p>
                            </article>
                            <article>
                                <h4 class="">Clinical Diagnostics
                                </h4>
                                <p><b>NGS allows clinicians to use genetic data related to the individual patient’s disease and
predisposition offering a more precise diagnosis and development of a targeted treatment plan.</b></p>
                                <p>The Centers for Medicare & Medicaid Services (CMS) regulates all clinical laboratory testing
                                performed on humans in the United States through the CLIA. The goal of the CLIA program is to
                                ensure quality laboratory testing. The amendments regulate the entire process within the
                                laboratory for accurate and consistent sequencing results for clinicians and patients.
</p>
                                <p>CLIA certification requires two proficiency tests (PT) every year. Full validation is required for all
                                instruments for clinical testing, and validation is reviewed every two years by CMS surveyors.
</p>
                                <p>In addition, all workflow, standard operating procedures, and personnel (including personnel
                                credentials) are tightly regulated and approved by the CMS. Facilities and instruments are
                                closely monitored with readings recorded automatically 24 hours a day, seven days a week. All
                                corrective actions are documented, including a complete summary of the event, and signed by
                                the Lab Director after the full review. All clinical laboratories must be CLIA certified to receive
                                Medicare or Medicaid payments, though the CLIA has no direct Medicare or Medicaid program
                                responsibilities.
</p>
                                <p>Neo7Logix NGS contracted genetic services powered by Psomagen, Inc comply with CLIA
certification in NGS services approved and marked CLIA certified. </p>
                            </article>
                            <article>
                                <h4 class="">NGS Research and Development</h4>
                                <p>From plasmid sequencing to population-level, whole-genome sequencing service, Neo7logix
                                contracted NGS laboratory does it all. Our contracted NGS laboratory is one of the few
                                whole-genome sequencing service providers offering top-quality sequencing services for all
applications and species.</p>
                                <p>Our services include surveillance and readiness for novel pathogen outbreaks (COVID-19) that
                                integrate population-level susceptibility and novel pathogen exome sequencing to design
                                effective treatment strategies for the infected population.
</p>
                                <p>Advanced personalized analysis and therapeutic treatment design offering genetic and immune
                                editing technology are also examples of our research and development advancements. Our
                                PBIMA (Precision-Based ImmunoMolecular Augmentation) pipeline is an example of these
robust enhancements.</p>
                            </article>
                        </div>
                    </div>
                    <br />
                    <div class="uk-container">
                        <h4 class="">Services Menu</h4>

                        <h4>I.Genetic Sequencing Services</h4>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Clinical Genome (WGS)</p>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Clinical Exome (WES)</p>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Clinical RNA Transcriptome</p>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Epigenome</p>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Metagenome</p>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Gene and GutBiome</p>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Single Cell Sequencing</p>
                        <h4>II.MicroArray Services</h4>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Cell DNA, Early Disease Detection and Precision Medicine</p>
                        <h4>III.Clinical Proteomic Services</h4>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Exosomal Urine Proteomics Analysis</p>
                        <h4>IV.Novel Pathogen Surveillance and Readiness Services</h4>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● <b>PBIMA-SOLVx</b> Program</p>
                        <h4>V.HLA Immune Profiling Services</h4>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Personalized Optitype Immune Susceptibility and Compatibility Typing</p>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Global Population Susceptibility for Novel Pathogens</p>
                        <h4>VI.Molecular Mapping Disease Pathway Services</h4>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● <b>PBIMA-PPAS:</b>Vibrant and Post-Tx Analysis</p>
                        <h4>VII.Clinical Peptide Sequencing and Production Services</h4>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Personalized Peptide Design:<b>PBIMA-PES</b></p>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Therapeutic and Regenerative Peptides:<b>PBIMA-TheraGen</b></p>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Antimicrobial Peptides:<b>PBIMA-MicrobeX</b></p>
                        <p style={{ padding: "0px 0px 0px 30px" }}>● Antiviral Peptides:<b>PBIMA-ViralX</b></p>
                        <h2 class="uk-article-title uk-margin-medium-bottom">I.Genetic Sequencing Services</h2>
                        <h4>Clinical Genome (WGS)</h4>
                        <br />
                        <b>Applications in Medicine</b>
                        <p>The purpose of clinical whole-genome sequencing (WGS) is to reveal the unique differences in
                        base pair mutations and help physicians interpret how certain combinations of DNA impact an
                        individual’s growth and survival. These patterns reveal inherited disorders, define the prognosis
                        of an individual’s medical anomaly, and predict a patient’s genetic response to a drug. This
helps a physician make the best recommendations for an individual’s treatment.</p>
                        <b>Benefits of Clinical WGS</b>
                        <p>Through early detection, whole-genome sequencing can increase a patient’s chance of reducing
                        or eliminating disease — or at least its negative effects. By using whole-genome sequencing to
                        characterize the traits of a disease, physicians can advise patients (and the wider public) on
                        how to limit their risk based on their genetic predispositions and lifestyle habits.
</p>
                        <b>Accurate Diagnosis</b>
                        <p>Base pair mutations and unique DNA combinations provide useful medical insights on how
                        these genetic sequences affect a person’s development and well-being. This information can
                        help a doctor predict how a patient will respond to a drug and allows them to provide better care
                        accordingly. As such, clinical whole-genome sequencing ensures physicians and patients can
                        have confidence in both the diagnosis and the treatment of their genetic disorders.
</p>
                        <b>Clinical Applications</b>
                        <p>Whole-genome sequencing maps a patient’s entire set of genes, including their expression and
                        regulation. With the ability to process such large sets of DNA in such a short time, the medical
                        industry and patients have experienced a plethora of benefits. Clinical whole-genome
                        sequencing allows medical professionals to discover illness and disease risks that could not be
                        identified by traditional medical approaches — sometimes even before a patient has displayed
                        any symptoms. This approach to medical care has also yielded the following results:
</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Identification of differences in the mutational landscape in disease</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Faster and more affordable medical experiences for patients</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Creation of personalized treatment plans based on a patient’s DNA and how they will
respond to treatments</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Identification of specific genes that contribute to disease and advancement of the
healthcare system’s &nbsp;&nbsp;&nbsp;expertise on genetic variation</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Custom-tailored patient education on which lifestyle habits to moderate in order to
counter &nbsp; &nbsp;  genetic predisposition to disease</p>
                        <b>Clinical WGS Safety and Quality Assurance</b>
                        <p>Genetic processes are not taken lightly in the healthcare industry. In fact, the Centers for
                        Medicare & Medicaid Services (CMS) have imposed a number of regulations for human sample
                        research called the Clinical Laboratory Improvement Amendments (CLIA). This program makes
                        sure that quality standards are met during testing by requiring laboratories to meet the following
                        conditions during their studies:
</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Lab access is restricted to authorized personnel only</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● CLIA technical processes are followed</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Data is protected on a secure on-site server</p>
                        <p>These regulations ensure that patient information is not only safe but also that the results are
                        precise and accurate. With this strict, regular quality control, patients and physicians can have
                        trust in their diagnoses and know that the laboratory in question is certified and the personnel
                        meets CLIA standards. Neo7logix NGS contracted laboratory services has been accredited by
                        the College of American Pathologists (CAP). In order to receive CAP accreditation, a laboratory
                        must demonstrate that it operates with the highest standards of quality, accuracy and
                        consistency.
</p>
                        <b>Enhanced Analysis Expansion</b>
                        <p>Medical science is far from understanding all the ways that clinical WGS can benefit modern
                        healthcare. Research is still yearning to learn more about the specific genetics behind certain
                        cancers and potential preventative measures. Neo7logix is working around the clock to make
                        these discoveries possible and to provide patients, hospitals, and universities around the world
with life-changing WGS services.</p>
                        <b>Clinical WGS Services</b>
                        <p>At Neo7logix, we offer an all-inclusive assessment of patient DNA by our CLIA-certified and
                        CAP-accredited laboratory facilities using approved methods of WGS. We can test for
                        undiagnosed or misdiagnosed disease or simply assist healthcare practitioners with the
                        sequencing of a patient’s genetic data. Team up with us in a mutual endeavor to improve global
health through genomics studies. <Link style={{ color: '#00A1E2' }} to="/ContactUs" target='blank'>Contact Us</Link>  to learn more or to order tests.</p>

                        <h4>Clinical Exome (WES)</h4>
                        <br />
                        <b>Benefits of WES</b>
                        <p>Each person’s DNA is broken up into about 3 billion nucleotides, which are the building blocks
                        of a person’s genetic code. Exons are the pieces of a person’s DNA that make—or
                        code—proteins. When put all together, these exons are known as the exome, which takes up
                        just 1 percent of a person’s entire genome. These functional proteins are essential in
maintaining health and vitality of the individual.</p>
                        <p>Whole exome sequencing is a targeted laboratory approach that makes it possible to identify
                        variations in the protein-coding part of a selected gene. This can be especially valuable because
                        most known genetic variants actually occur in exons. With whole exome sequencing, a patient’s
                        DNA is mapped out, and specific genetic mutations and disease-causing variants are identified.
                        Whole exome sequencing looks at genetic variants to determine or identify the reasons for a
                        patient’s genetic disorder or predisposition to certain genetic disorders.
</p>
                        <p>For example, if a patient is experiencing signs and symptoms of a genetic disorder, whole
                        exome sequencing could identify the possible variants that could be contributing to these
                        symptoms, as well as other genetic issues such as how a patient reacts to a certain medication.
</p>
                        <p>Whole exome sequencing can give physicians a clear idea of how a patient is affected by
                        certain genetic disorders or predispositions. From there, physicians can use information gained
from whole exome sequencing to create a patient’s treatment plan going forward.</p>
                        <b>Early Detection and Better Treatments</b>
                        <p>Early detection of genetic disorders or predispositions is extremely important—and whole
                        exome sequencing can help prevent health issues before they even happen. Because many
                        genetic disorders don’t show symptoms for years to come, a patient might not even be
                        diagnosed with the disorder or a predisposition for it.
</p>
                        <p>For example, whole exome sequencing can reveal if a patient is predisposed to diabetes, and
                        the patient can take steps to improve their lifestyle to prevent a diabetes diagnosis down the
                        line. Whole exome sequencing can also be extremely cost-effective for patients. Other methods
                        of DNA sequencing are more time-consuming for lab technicians. Whole exome sequencing
                        takes less time to complete, as it targets specific nucleotides found in the exome. This is much
                        more cost-effective than focusing on the entire genome, which is involved in whole genome
sequencing.</p>
                        <p>Whole exome sequencing can be extremely valuable for patients, but it can also be valuable for
                        researchers. For example, whole exome sequencing can identify if certain genetic variations are
                        associated with certain health issues. This can help improve patient diagnosis in years to come.
                        The main goal of whole exome sequencing is identifying molecular diagnosis for patients who
                        know or suspect they have a genetic disorder. Because whole exome sequencing targets the
                        part of the genome that typically contains a large portion of known disease-related variants, it
                        can quickly and cost-effectively identify a patient’s potential variants.
                        Whole exome sequencing holds many benefits for patients, hospitals, and clinics:
</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Provides a better understanding of patient health history and diagnosis</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Identifies rare genetic variations such as genetic disorders</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Tests at-risk family members to identify the presence of disease</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Can be more cost-effective than whole genome sequencing</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Identifies and assesses the risk of recurrence</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Identifies biomarkers of disease and treatment targets</p>
                        <p>Our WES is 200X coverage with a turnaround time of 3-5 weeks. <Link style={{ color: '#00A1E2' }} to="/ContactUs" target='blank'>Contact Us</Link> to learn more or to
order tests.</p>
                        <h4>Clinical RNA Transcriptome</h4>
                        <br />
                        <b>What is RNA Transcriptome?</b>
                        <p>A transcriptome is a collection of all the transcripts present in a given cell. An RNA sequence
                        mirrors the sequence of the DNA from which it was transcribed, so by analyzing the entire
                        collection of RNAs or transcriptomes in a cell, researchers can determine when and where each
                        gene is turned on or off in the cells and tissues of an organism. In cancer, chronic disease and
                        general health, RNA transcriptome offers the potential of submicroscopic assessment producing
                        a viable prediction and precision treatment design for the individual patient.
</p>
                        <b>Why is RNA Transcriptome Important?</b>
                        <p>RNA sequencing gives researchers more visibility into previously undetected changes that
                        occur in certain states of diseases. Using RNA sequencing, researchers can learn more about a
                        transcriptome’s new and existing qualities in just one assessment. They can also see how a
                        transcriptome reacts to certain therapies, environmental situations, and other conditions. RNA
                        sequencing even reveals alternative gene-spliced transcripts, gene fusion, mutations and
variants, post-transcriptional modifications, and other gene expressions.</p>
                        <p>As DNA sequencing technology advances, RNA / transcriptome sequencing becomes more
                        affordable and widely available. This has made RNA sequencing an excellent tool for RNA
                        editing, RNA profiling, and allele-specific expression.
</p>
                        <b>Types of RNA Transcriptome Sequencing</b>
                        <p>NGS science utilizes many different methods of RNA and transcriptome sequencing to identify
                        existing and novel types of gene expression. At Psomagen Inc., we use only the most advanced
                        and industry-standard methods, including the following:
</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● mRNA Sequencing: This type of RNA sequencing is one of the most popular methods of
                        analyzing disease &nbsp; &nbsp;states and biological processes in the study of transcriptomes. This
                        method is extremely in-depth and allows &nbsp; &nbsp;researchers to find new genes that were
previously imperceptible.</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Small RNA Sequencing: This type of sequencing targets and isolates small RNA,
                        including microRNA, to discover&nbsp; &nbsp; how noncoding RNA affects different types of gene
expressions.</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Whole RNA Sequencing: Whole RNA sequencing is a comprehensive method that aims
                        to accurately measure &nbsp; &nbsp;the number of genes and transcripts in the transcriptome. It
identifies existing and novel coding and noncoding RNA.</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Targeted RNA Sequencing: This sequencing method focuses on two different types of
targeted transcriptome &nbsp; &nbsp;assessment: targeted enrichment and targeted amplicon.</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● RNA Exome Capture Sequencing: This method of RNA sequencing identifies different
types of RNA exomes by &nbsp; &nbsp;examining specific regions of RNA coding.</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Single-Cell RNA Sequencing: This type of RNA sequencing studies individual cells,
                        specifically the &nbsp; &nbsp;transcriptomes and genomes. It allows researchers to examine the small
                        complexities of each region of a &nbsp; &nbsp;transcriptome.
</p>
                        <b>Benefits of RNA Transcriptome</b>
                        <p>Because this form of sequencing does not require prior knowledge about the transcript, it can
identify information that might be overlooked by other methods. Some of its benefits include:</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● The ability to capture both previously known and novel transcriptome features</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Close, accurate studies of a variety of gene expression</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Reliable results — often at a lower price point per sample</p>
                        <p>RNA sequencing is precise in measuring transcripts and identifying complexities. It can more
                        accurately determine gene expressions than many other methods of arrays.
</p>
                        <b>How Neo7logix’s RNA Transcriptome Sequence Services Work?</b>
                        <p>RNA / transcriptome sequencing services include the following:</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Transcript isoform discovery and validation</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Coding and annotation with SNPs</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Profiling and characterization junctions</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Quantitative determination and identification of regulatory transcripts</p>
                        <p>We take great care of your samples from beginning to end, regardless of which of our services
                        you use. Our expert lab staff ensures quality results by adhering to strict quality assurance
                        processes. Learn a little more about our RNA sequencing workflow here:
</p>
                        <p></p>
                        <p>Sample Prep------>Library Prep----->Sequencing----->Raw Data Output / Bioinformatics Analysis</p>
                        <p><b>Sample Prep:</b> Before RNA can be sequenced, the sample must be fragmented for
next-generation sequencing (NGS).</p>
                        <p><b>Library Prep:</b> The next step involves attaching adapters to the ends of the fragments, such as
the primary sequencing site and the amplification element, to make sequencing possible.</p>
                        <p><b>Sequencing: </b>Using NGS, the library can be sequenced. The extent of the library sequencing
depends on what the data will be used for.</p>
                        <p><b>Raw Data / Bioinformatics Analysis:</b> In the last step, researchers conduct an RNA
sequencing analysis to identify genes that are expressed in a unique way based on
environment, biological factors, and more and draw hypotheses from the evidence.
</p>
                        <Link style={{ color: '#00A1E2' }} to="/ContactUs" target='blank'>Contact Us</Link> to learn more or to order tests.
<br />
                        <br />
                        <h4>Epigenome</h4>

                        <p>Epigenome sequencing is the process of examining epigenomic modifications within our DNA.
                        These genetic modifiers are tiny chemical compounds that attach to our DNA, and they play a
                        crucial role in regulating gene activity. Epigenomic modifications, for example, may shut off or
                        turn on a gene. Genetic disorders, metabolic disorders, cancers and degenerative diseases
                        have all been linked to epigenomic errors, and therefore, examining the epigenome may help
                        predict or confirm the cause of a disease or disorder. In our tests, we can analyze these
                        epigenomic errors, which can help to identify irregular gene activity that may cause or increase
                        the risk for genetic disorders or disease.
</p>
                        <h4>Metagenome</h4>
                        <br />
                        <b>The Origin of Metagenomics</b>
                        <p>Early metagenomic research proposed that many environments have groups of microorganisms
                        that can’t be cultured or sequenced. There was abundant evidence to suggest that most
                        organisms on Earth are comprised of these uncultured microorganisms. Thus, researchers
                        began to conduct direct genetic analyses of the genomes of environmental samples.
                        They began cloning DNA from the environment and later screened it to discover how the genes
                        expressed themselves functionally. Eventually, metagenomic next-generation sequencing
                        (NGS) was applied to these microorganisms, and this allowed them to study these complex
                        species without needing to isolate or cultivate a species within the confines of a laboratory.
</p>
                        <b>Why Metagenomics Matters?</b>
                        <p>Metagenomic methods are helping to further reduce the time and money needed to sequence
                        DNA. They allow a small and simple lab to sequence the whole genome of an environmental
                        species. Metagenomics also offers new information about the diversity of the environments of
                        the world and reveals the biological diversity of the microbes of the earth. This information,
                        which was previously unknown, provides us with a new way to view the world and gives us an
entirely new understanding of the biosphere.</p>
                        <p>Metagenomic sequencing is one method that we use, and it follows a specialized workflow that
                        involves several steps; including genetic extraction, preparation, amplicon or shotgun
                        sequencing, assembly, annotation, and metagenomic data analysis. We provide our clients with
                        the opportunity to have their genome sequenced by one of two methods, which are described
below:</p>
                        <p>The amplicon method of sequencing enables a geneticist to identify microorganism diversity in a
                        specific environment. It takes a targeted approach to variation analysis based on genomic
                        regions, using amplicons to identify variant traits and characteristics. Amplicon sequencing is a
                        great way to find abnormal mutations in complex specimens. This method is favored for being
fast and affordable. Below are some of the types of amplicons used:</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● 16S rDNA sequencing</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Full-length 16S rRNA sequencing</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● 18S rDNA/ITS sequencing</p>
                        <b>Shotgun Metagenomic Sequencing</b>
                        <p>Shotgun metagenomic sequencing is an effective way to get a representative mixed sample of
                        all the genes of every organism present in the sample. It makes it easier to evaluate the
                        diversity among microbes and to discover species diversity. In shotgun metagenomic
                        sequencing, DNA is put through a purification process and randomly shaved down into smaller
                        fragments. Then, it is sequenced and analyzed to extract data about the presence of organisms
                        and the metabolic processes that are occurring and possible within the community represented
                        by the sample.
</p>
                        <b>Metagenomics Data Analysis Methods</b>
                        <p>Different types of information can be extracted from metagenomic data. For example, by using
                        amplicon-derived data, a researcher can determine which organisms the sample sequences
                        have come from. The two main methods of analysis, available at Psomagen, and their subtypes
are listed below:</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Basic analysis</p>
                        <p style={{ padding: "0px 0px 0px 100px" }}>● Gene prediction and annotation</p>
                        <p style={{ padding: "0px 0px 0px 100px" }}>● Probiotics analysis</p>
                        <p style={{ padding: "0px 0px 0px 100px" }}>● Assembly</p>
                        <p style={{ padding: "0px 0px 0px 100px" }}>● Taxonomy analysis</p>
                        <p style={{ padding: "0px 0px 0px 100px" }}>● Community diversity analysis (OTU)</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Advanced analysis</p>
                        <p style={{ padding: "0px 0px 0px 100px" }}>● Phylogenic tree</p>
                        <p style={{ padding: "0px 0px 0px 100px" }}>● PCA biplot</p>
                        <p style={{ padding: "0px 0px 0px 100px" }}>● Hierarchical taxonomy graph</p>
                        <p style={{ padding: "0px 0px 0px 100px" }}>● Heat map</p>

                        <p>The future of this next-generation sequencing technique is bright and promises to provide
valuable insight into evolution, microbial communities, and functions of our ecosystem. <Link style={{ color: '#00A1E2' }} to="/ContactUs" target='blank'>Contact Us</Link> to learn more or to order tests.</p>
                        <h4>Gene and GutBiome</h4>
                        <p>Everyone’s DNA tells a story. What does yours say? It’s time to find out through a Psomagen
                        DNA testing kit. With simple saliva sampling, this personal DNA test reveals:
</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● More than 100 personal genetic traits or tendencies</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Nutritional deficits and dietary traits</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Physical traits, ex. early hair loss in men, bitter taste, fear of public speaking, etc.</p>
                        <p>Share your results with friends and family to find out how you compare! GutBiome is an
                        excellent tool for those interested in improving their digestive health and overall well-being.
                        When you provide us with a stool sample, we use 16S metagenomic sequencing to:
</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Analyze the microorganisms in your gut</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Tell you about your microbe profile, metabolism, nutrition utility, lifestyle status, gut type,
and provide you with a full probiotics profile</p>
                        <p>We recommend doing personal testing on a somewhat regular basis to keep tabs on your gut
                        health as you make changes to your lifestyle.
</p>
                        <p>Every cell in your body contains DNA, the genetic information that tells the story of you — your
                        physical characteristics, personality traits and tendencies, nutritional deficiencies, and so much
                        more. A genetic trait test (“Gene”) is a great way to get to know yourself better. Meanwhile,
there are trillions of microbes in your gut that impacts metabolism, sleep, fiber breakdown, etc.</p>
                        <p>This personal DNA test uniquely explores both the human host’s gene AND the microbial genes
                        found in your gut. By sending saliva and stool samples to Psomagen, you can find out more
                        about what makes you unique, obtain answers to questions about your current health status,
                        and even discover which traits you’re likely to pass on to your children. More than 12 million
                        people have already embarked on the journey of genetic self-discovery. Begin your own journey
today and learn what makes you unique by ordering your test now.</p>
                        <p>Link: Please put this video on website<a href="https://youtu.be/FFU402UZZTE?t=29" target='blank'> https://youtu.be/FFU402UZZTE?t=29</a> </p>
                        <p><Link style={{ color: '#00A1E2' }} to="/ContactUs" target='blank'>Contact Us</Link> to learn more or to order tests.</p>
                        <h4>Single Cell Sequencing</h4>
                        <br />
                        <h4 class="">II.MicroArray Services</h4>
                        <p>Every cell in our bodies contains copies of all of the genes; there are more than 20,000. Yet, not
                        every gene is “expressed” in each cell. In healthy cells, specific genes are turned “on,” which
                        enable proper cell function. Muscle cells, for example, express muscle protein genes. Many
                        diseases, though, like cancer and hereditary illnesses, disrupt normal cell activity, causing
                        alterations in gene expression.
</p>
                        <b>Benefits of MicroArray Services</b>
                        <p>Microarray analysis was first developed in the mid-1990s, and since then, the technology has
                        advanced greatly. Today, this form of genetic analysis offers researchers more accurate results
                        that are extremely useful for disease detection, diagnoses, prevention, and treatment planning.
                        By measuring and examining gene expression, researchers can:
</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Monitor cell response to certain treatments</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Test and enhance treatment plans that address specific gene mutations</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Develop and test new methods for detecting diseases earlier</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Investigate the development of a disease and how it alters gene expression</p>
                        <p>Microarray gene research is a process for comparatively analyzing the DNA in cells to a
                        microarray of control DNA. Researchers, in turn, are able to quantify cell gene expression.
                        Psomagen Inc. conducts comprehensive microarray research. Using this advanced process, our
                        research team can examine gene expression and quantify disease-related mutations caused by
                        cancer and other illnesses. Through our research, we can help develop more accurate disease
detection methods, more accurate diagnoses, and individualized treatment planning.</p>
                        <p><Link style={{ color: '#00A1E2' }} to="/ContactUs" target='blank'>Contact Us</Link> to learn more or to order tests.</p>
                        <h4 class="">III.Clinical Proteomic Services</h4>
                        <p>The urine contains thousands of unique proteins that are key biomarkers and potential
                        therapeutic targets that can identify disease and potentially be used as a treatment target with a
                        higher specificity and efficacy. Personalized / precision medicine recognizes the importance of
                        proteomic urine analysis to develop better diagnostic and treatment approaches for the
individual patient.</p>
                        <p>Neo7Logix comprehensive exosomal proteomics analysis is integrated with our NGS genetic
                        services in order to create a robust predictive model. This precision model is key in creating a
                        personalized design that will yield a successful outcome in disease free survival and vibrant
health.</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Our approach is the optimal method for successfully demonstrating the possibility of
                        developing urine-derived &nbsp;&nbsp;EV phosphoproteins for disease profiling, and we’re sharing
our technology with other researchers.</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● With just 10mL of urine, you can routinely identify more than 3,200 unique
phosphopeptides, representing more &nbsp;&nbsp;than 1,200 unique phosphoproteins.</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● The identification numbers are significantly better than anything previously reported (the
previous best ID &nbsp;&nbsp;result was 14 urine EV phosphoproteins).</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● We’ve based our analysis on comprehensive profiling of EVs secreted into urine by all
cells, with diseased cells &nbsp;&nbsp;and tumors producing unique EVs with specialized cargo.</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● Our EVtrap method allows you to extract more than 95% of all urine EVs with very low
contamination levels.</p>
                        <p style={{ padding: "0px 0px 0px 50px" }}>● With our EVtrap method, researchers like you can profile diseases more
accurately—which leads to better &nbsp;&nbsp; treatments.</p>
                        <p><Link style={{ color: '#00A1E2' }} to="/ContactUs" target='blank'>Contact Us</Link> to learn more or to order tests.
</p>
                        <h4 class="">IV.Novel Pathogen Surveillance and Readiness Services</h4>
                        <b>Why Surveillance?</b>
                        <p>Surveillance is a fundamental tool for public health, producing information to guide actions.
                        Modern surveillance tends to follow health measures such as the incidence of a disease or
                        syndrome or even the occurrence of health-related behaviors. There are many reasons for
                        conducting surveillance, and the data collected and the approach taken to analyzing those data
are both influenced by the overall goal of a surveillance system.</p>
                        <p>Surveillance systems aims mainly at detection also provide information that may be useful for
                        other purposes. The goal of detecting an outbreak of a newly emerging virus, places specific
                        demands on the type of data collected and the types of analysis performed. All approaches to
                        surveillance share some common principles. While some of the underlying methods used in
                        public health surveillance have evolved considerably in recent years, the general approach to
                        surveillance has remained relatively constant.
</p>
                        <p>At a fundamental level, surveillance aims to (1) identify individual cases, (2) detect population
                        patterns in identified cases, (3) convey information to decision-makers about population health
patterns, and finally (4) develop a precision target antiviral treatment readiness plan.</p>

                        <b>PBIMA-SOLVx Readiness Program</b>
                        <p><b>PBIMA-SOLVx</b> is a readiness program that determines viral infection outbreak and human HLA
immune susceptibility patterns engineering a multi-mechanistic antiviral treatment design to
address all stages of viral infection.</p>
                        <p>Neo7logix integrated viral genome sequencing, virome, immunome, peptidome, HLA
                        compatibility / susceptibility and bioinformatics / biocomputation as part of the robust
surveillance and delivery system.</p>
                        <p>Neo7Logix’s aim is on natural defense mechanisms and mimicry to mount an efficient defense
                        and regulation response against the virus initiating antiviral immune cascade response using
antiviral (AVP) / antimicrobial peptide (AMP) technology, development and production.</p>
                        <p>This approach has proven to be effective, less toxic without serious adverse reactions and
effective in treating and offering protection in viral disease outbreaks.</p>
                        <p><Link style={{ color: '#00A1E2' }} to="/ContactUs" target='blank'>Contact Us</Link> to learn more about Neo7Logix Surveillance and Readiness SOLVx Program</p>

                    </div>
                </section>


                <footer className="footer-section section-gap-half">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 footer-left">
                                <Link to="/">
                                    <img src="img/header-logo.jpg" alt="" />
                                </Link>
                                <p><i className="ti-location-pin"></i> 539 W. Commerce St #2886<br /> Dallas, TX 75208 </p>

                                <p className="copyright-text"> Copyright © 2020 Neo7Logix LLC- All Rights Reserved.</p>
                            </div>
                            <div className="col-lg-7">
                                <div className="footer-title">
                                    <h2>WE'RE HERE TO HELP</h2>
                                    <a href="tel:+12067185467">
                                        <p><i className="ti-mobile"></i>+1-206-718-5467</p>
                                    </a>
                                    <a href="mailto:office@neo7logix.com">
                                        <p><i className="ti-email"></i> office@neo7logix.com</p>
                                    </a>
                                </div>
                                <ul className="footer-menu">
                                    <li>
                                        <Link to="/Sitemap" >Sitemap</Link>
                                    </li>
                                    <li>
                                        <Link to="/TermsOfUse" >Terms of Use</Link>
                                    </li>
                                    <li>
                                        <Link to="/PrivacyStatements" >Privacy Statement</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>

                <div className="scroll-top">
                    <i className="ti-angle-up"></i>
                </div>
            </React.Fragment>

        );
    }
}
