import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import MyModal from './../../CustomModal/CustomModal';
import Moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";

export class PratitionerPatientModify extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            PatientId: 0,
            PractitionerId: 0,
            PractitionerPatientId: 0,
            UserId: 0,
            FirstName: "",
            MiddleName: "",
            LastName: "",
            UserName: "",
            Email: "",
            CountryId: 233,
            OCountryId: "",
            countries: [],
            StateId: "",
            states: [],
            CityId: "",
            cities: [],
            Mobile: "",
            DateOfBirth: "",
            Age: "0",
            Address: "",
            Sex: "M",
            PhoneNumber: "",
            PostalCode: "",
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            orderOfCountries: [],
            errors: {
                FirstName: '',
                MiddleName: '',
                LastName: '',
                CountryId: '',
                StateId: '',
                CityId: '',
                Mobile: '',
                DateOfBirth: '',
                //Age: '',
                Address: '',
                PhoneNumber: '',
                PostalCode: '',
                UserName: '',
                Email: '',
                //OCountryId: '',
            },
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/practitioner/patient/list');
    //    }
    //}

    componentDidMount() {
        this.getCountry();
    }

    getCountry() {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/CognitoUserStore/getPatientDropdown';
        let data = JSON.stringify({
            isDeleted: true,
            searchString: ''
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                console.log("dsada", result.data.outdata);
                this.setState({
                    countries: result.data.outdata.countryData,
                    disease: result.data.outdata.diseaseData,
                    orderOfCountries: result.data.outdata.ocountryData,
                    //allTissues: result.data.outdata.tissueData
                }, () => {
                    const param = this.props.match.params;

                    if (param.id != undefined) {
                        this.setState({ PatientId: param.id });
                        this.getData(param.id);
                    }
                    else { this.setState({ loading: false }); }
                });
            }
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
        });
    }

    //get detail(for update)
    getData(id) {

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PractitionerPatient/GetPatientDetailByPDId?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    console.log(result.data.outdata);
                    var rData = result.data.outdata;
                    this.setState({
                        PractitionerId: rData.practitionerId, PractitionerPatientId: rData.practitionerPatientId, UserId: rData.userId,
                        PatientId: rData.patientId, FirstName: rData.firstName, MiddleName: rData.middleName,
                        LastName: rData.lastName, UserName: rData.userName, Email: rData.email, Mobile: rData.mobile,
                        // DateOfBirth: (rData.dateOfBirth == null ? "" : Moment(rData.dateOfBirth.slice(0, 10), "MM-DD-YYYY").format('YYYY-MM-DD')), 
                        DateOfBirth: (rData.dateOfBirth == null ? "" : Moment(rData.dateOfBirth)._d),
                        Age: rData.age, Address: rData.address, Sex: rData.sex,
                        PhoneNumber: rData.phoneNumber, PostalCode: rData.postalCode, CountryId: rData.countryId, OCountryId: rData.oCountryId
                        //, StateId: rData.StateId, CityId: rData.CityId
                    }, () => {
                        if (rData.countryId != null) {
                            this.getStateData(rData.countryId, rData.stateId, rData.cityId)
                        }
                        else {
                            this.setState({ CountryId: 233, loading: false }, () => { this.getStateData(233, "", "") });
                        }
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getStateData(CountryId, StateId, CityId) {

        const apiroute = window.$APIPath;

        const url = apiroute + '/api/CognitoUserStore/getallstate?Id=' + CountryId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    //console.log(result.data);
                    this.setState({ states: result.data.outdata, StateId: StateId }, () => {
                        if (StateId != "" || StateId != null) {
                            this.getCityData(StateId, CityId)
                        }
                        else { this.setState({ loading: false }); }
                    });
                }
                else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    getCityData(StateId, CityId) {
        const apiroute = window.$APIPath;

        const url = apiroute + '/api/CognitoUserStore/getallcity?Id=' + StateId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                //console.log(result.data);
                this.setState({ cities: result.data.outdata, CityId: CityId, loading: false });
            }
            else { this.setState({ loading: false }); }
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }


    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.FirstName == undefined || this.state.FirstName == '') {
            errors.FirstName = 'Please enter first name.';
        }
        //if (this.state.MiddleName == undefined || this.state.MiddleName == '') {
        //    errors.MiddleName = 'Please enter middle name.';
        //}
        if (this.state.LastName == undefined || this.state.LastName == '') {
            errors.LastName = 'Please enter last name.';
        }

        //if (this.state.OCountryId == undefined || this.state.OCountryId == '') {
        //    errors.OCountryId = 'Please select order of country.';
        //}
        //if (this.state.CountryId == undefined || this.state.CountryId == '') {
        //    errors.CountryId = 'Please select country.';
        //}
        //if (this.state.StateId == undefined || this.state.StateId == '') {
        //    errors.StateId = 'Please select state.';
        //}
        //if (this.state.CityId == undefined || this.state.CityId == '') {
        //    errors.CityId = 'Please select city.';
        //}
        if (this.state.Mobile == undefined || this.state.Mobile == '') {
            errors.Mobile = 'Please enter primary phone.';
        }
        if (this.state.DateOfBirth == undefined || this.state.DateOfBirth == '') {
            errors.DateOfBirth = 'Please enter date of birth.';
        }
        //if (this.state.Age == undefined || this.state.Age == '') {
        //    errors.Age = 'Please enter age.';
        //}
        //if (this.state.Address == undefined || this.state.Address == '') {
        //    errors.Address = 'Please enter address.';
        //}
        // if (this.state.PhoneNumber == undefined || this.state.PhoneNumber == '') {
        //    errors.PhoneNumber = 'Please enter secondary phone.';
        // }
        //if (this.state.PostalCode == undefined || this.state.PostalCode == '') {
        //    errors.PostalCode = 'Please enter postal code.';
        //}
        if (this.state.PatientId == 0) {
            //if (this.state.UserName == undefined || this.state.UserName == '') {
            //    errors.UserName = 'Please enter user name.';
            //}
            if (this.state.Email == undefined || this.state.Email == '') {
                errors.Email = 'Please enter email.';
            }
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleDateChange(date) {
        var newAge = (date) ? this.calculate_age(date) : 0;
        this.setState({ DateOfBirth: date, Age: newAge })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        const validMobileRegex = RegExp(/^[0-9+() -]+$/);
        const validNumberRegex = RegExp(/^[0-9]+$/);
        const validAlphaRegex = RegExp(/^[a-zA-Z \b]+$/);
        const validAlphaNoRegex = RegExp(/^[a-zA-Z0-9 \b]+$/);
        const validEmailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        switch (name) {
            case 'FirstName':
                errors.FirstName = (!value) ? 'Please enter firstname.' : (validAlphaNoRegex.test(value) ? '' : 'Only alphanumeric allowed.');
                this.setState({ FirstName: value.replace(/[^a-zA-Z0-9 \b]+$/, '') })
                break;

            case 'MiddleName':
                errors.MiddleName = (value) ? (validAlphaNoRegex.test(value) ? '' : 'Only alphanumeric allowed.') : '';
                this.setState({ MiddleName: value.replace(/[^a-zA-Z0-9 \b]+$/, '') })
                break;

            case 'LastName':
                errors.LastName = (!value) ? 'Please enter lastname.' : (validAlphaNoRegex.test(value) ? '' : 'Only alphanumeric allowed.');
                this.setState({ LastName: value.replace(/[^a-zA-Z0-9 \b]+$/, '') })
                break;


            //case 'OCountryId':
            //    errors.OCountryId = (!value) ? 'Please select order of country.' : '';
            //    this.setState({ OCountryId: value });
            //    break;

            case 'Mobile':
                errors.Mobile = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : 'Please enter primary phone.';
                this.setState({ Mobile: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            case 'DateOfBirth':
                var newAge = (value) ? this.calculate_age(value) : 0;
                this.setState({ Age: newAge });
                break;

            //case 'Address':
            //    errors.Address = (!value) ? 'Please enter Address.' : '';
            //    break;

            case 'PhoneNumber':
                errors.PhoneNumber = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ PhoneNumber: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;

            case 'PostalCode':
                errors.PostalCode = (value) ? (validNumberRegex.test(value) ? '' : 'Only numbers allowed.') : '';
                this.setState({ PostalCode: value.replace(/[^0-9]+$/, '') })
                break;

            case 'Email':
                errors.Email = (!value) ? 'Please enter email.' : (validEmailRegex.test(value) ? '' : 'Invalid Email.');
                break;

            //case 'UserName':
            //    errors.UserName = this.state.PatientId == 0 ? ((!value) ? 'Please enter username.' : '') : '';
            //    break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors }, () => {

            if (name == 'CountryId') {
                if (value != '') {
                    this.getStateData(value, "", "");
                    this.setState({ cities: [], CityId: "" });
                }
                else {
                    this.setState({ states: [], StateId: "", cities: [], CityId: "" });
                }
            }
            if (name == 'StateId') {
                if (value != '') {
                    this.getCityData(value, "");
                }
                else {
                    this.setState({ cities: [], CityId: "" });
                }
            }

        })
    }

    calculate_age = (dob1) => {
        var today = new Date();
        var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        console.log(age_now);
        return age_now;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        let uid = 0;
        if (userToken != null) {
            id = (userToken.practitionerId == null ? 0 : userToken.practitionerId);
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            if (this.state.PatientId == 0) {
                url = apiroute + '/api/PractitionerPatient/SavePatient';
            }
            else {
                url = apiroute + '/api/PractitionerPatient/UpdatePatient';
            }

            let data = JSON.stringify({
                PractitionerPatientId: parseInt(this.state.PractitionerPatientId),
                PractitionerId: parseInt(id),
                PatientId: parseInt(this.state.PatientId),
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                MiddleName: this.state.MiddleName,
                UserName: this.state.Email,
                Email: this.state.Email,
                Address: this.state.Address,
                PhoneNumber: this.state.PhoneNumber,
                Mobile: this.state.Mobile,
                PostalCode: this.state.PostalCode,
                Sex: this.state.Sex,
                DateOfBirth: (this.state.DateOfBirth != null && this.state.DateOfBirth != "" ? this.state.DateOfBirth : null),
                CityId: ((this.state.CityId == "" || this.state.CityId == 0) ? null : parseInt(this.state.CityId)),
                CountryId: ((this.state.CountryId == "" || this.state.CountryId == 0) ? null : parseInt(this.state.CountryId)),
                StateId: ((this.state.StateId == "" || this.state.StateId == 0) ? null : parseInt(this.state.StateId)),
                //Age: parseInt(this.state.Age),
                Age: ((this.state.Age == '' || this.state.Age == null || this.state.Age == '0') ? null : this.state.Age.toString()),
                UserId: parseInt(this.state.UserId),
                CreatedBy: parseInt(uid),
                OCountryId: this.state.OCountryId
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            redirect: true
                        });
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    //console.log(error);
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            this.setState({
                //authError: true,
                //errorType: 'danger',
                //error: "Please fill all the fields.",
                loading: false
            });
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/practitioner/dashboard" />
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, PatientId, PractitionerId, PractitionerPatientId, Sex, DateOfBirth,
            Age, FirstName, MiddleName, LastName, UserName, Email, CountryId, countries,
            StateId, states, CityId, cities, Mobile, Address, PhoneNumber, PostalCode,
            Qualification, errors, error, errorType, authError, orderOfCountries, OCountryId } = this.state;

        return (

            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                                <Link to="/practitioner/dashboard" className="kt-subheader__title">
                                                    Dashboard
                                                </Link>
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="/practitioner/patient/detail" className="kt-subheader__breadcrumbs-link">
                                                    Patient Detail                      </Link>
                                            </div>
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/practitioner/dashboard" className="btn btn-primary btnBorder">
                                                Dashboard
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-4">
                                                                <label className="col-12 col-form-label">First Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="1" placeholder="Enter patient first name" name="FirstName" value={FirstName} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.FirstName.length > 0 && <span className='error'>{errors.FirstName}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label className="col-12 col-form-label">Middle Name</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="2" placeholder="Enter patient middle name" name="MiddleName" value={MiddleName} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.MiddleName.length > 0 && <span className='error'>{errors.MiddleName}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label className="col-12 col-form-label">Last Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="3" placeholder="Enter patient last name" name="LastName" value={LastName} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.LastName.length > 0 && <span className='error'>{errors.LastName}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">

                                                            {/*<div className="col-md-6">
                                                                <label className="col-12 col-form-label">User Name</label>
                                                                <div className="col-12">
                                                                    {PatientId != 0 ?
                                                                        <Input className="form-control here" type="text" maxLength="100" tabIndex="4" placeholder="Enter patient user name" name="UserName" value={UserName} onChange={this.handleInputChange.bind(this)} autoComplete="UserName" disabled />
                                                                        :
                                                                        <Input className="form-control here" type="text" maxLength="100" tabIndex="4" placeholder="Enter patient user name" name="UserName" value={UserName} onChange={this.handleInputChange.bind(this)} autoComplete="UserName" />
                                                                    }
                                                                    {PatientId == 0 ?
                                                                        errors.UserName.length > 0 && <span className='error'>{errors.UserName}</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>*/}
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Email<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    {PatientId != 0 ?
                                                                        <Input className="form-control here" type="text" maxLength="50" tabIndex="3" name="Email" placeholder="Enter patient valid email address" value={Email} onChange={this.handleInputChange.bind(this)} autoComplete="Email" disabled />
                                                                        :
                                                                        <Input className="form-control here" type="text" maxLength="50" tabIndex="3" name="Email" placeholder="Enter patient valid email address" value={Email} onChange={this.handleInputChange.bind(this)} autoComplete="Email" />
                                                                    }
                                                                    {PatientId == 0 ?
                                                                        errors.Email.length > 0 && <span className='error'>{errors.Email}</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <label className="col-12 col-form-label">Sex</label>
                                                                <div className="col-12">
                                                                    <div className="kt-radio-inline">
                                                                        {Sex == "M" ?
                                                                            <label className="kt-radio">
                                                                                <input type="radio" value="M" onChange={this.handleInputChange.bind(this)} checked id="Male" name="Sex" tabIndex="4" /> Male
                                                                                <span></span>
                                                                            </label>
                                                                            :
                                                                            <label className="kt-radio">
                                                                                <input type="radio" value="M" onChange={this.handleInputChange.bind(this)} id="Male" name="Sex" tabIndex="4" /> Male
                                                                                <span></span>
                                                                            </label>
                                                                        }
                                                                        {Sex == "F" ?
                                                                            <label className="kt-radio">
                                                                                <input type="radio" value="F" onChange={this.handleInputChange.bind(this)} checked id="Female" name="Sex" tabIndex="5" /> Female
                                                                                <span></span>
                                                                            </label>
                                                                            :
                                                                            <label className="kt-radio">
                                                                                <input type="radio" value="F" onChange={this.handleInputChange.bind(this)} id="Female" name="Sex" tabIndex="5" /> Female
                                                                                <span></span>
                                                                            </label>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Date Of Birth <span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    {/* <Input type="date" max="9999-12-31" name="DateOfBirth" tabIndex="6" min="1000-01-01" className="form-control" value={DateOfBirth} onChange={this.handleInputChange.bind(this)} placeholder="Enter patient date of birth" /> */}
                                                                    <DatePicker
                                                                        selected={DateOfBirth}
                                                                        onChange={this.handleDateChange.bind(this)}
                                                                        dateFormat="MM/dd/yyyy"
                                                                        placeholderText="mm/dd/yyyy"
                                                                        className="form-control"
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                    {!DateOfBirth && <span className='error'>{errors.DateOfBirth}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Age</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="3" tabIndex="7" name="Age" placeholder="Enter patient age" value={Age} onChange={this.handleInputChange.bind(this)} disabled />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Primary Phone <span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="8" name="Mobile" placeholder="Enter primary phone" value={Mobile} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.Mobile.length > 0 && <span className='error'>{errors.Mobile}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Secondary Phone</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="9" name="PhoneNumber" placeholder="Enter secondary phone" value={PhoneNumber} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.PhoneNumber.length > 0 && <span className='error'>{errors.PhoneNumber}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">

                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Resident Country</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="10" name="CountryId" value={CountryId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Resident Country</option>
                                                                        {countries
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.CountryId.length > 0 && <span className='error'>{errors.CountryId}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Resident State</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="11" name="StateId" value={StateId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Resident State</option>
                                                                        {states
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.StateId.length > 0 && <span className='error'>{errors.StateId}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Resident City</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="12" name="CityId" value={CityId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Resident City</option>
                                                                        {cities
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.CityId.length > 0 && <span className='error'>{errors.CityId}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Resident Postal code</label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="20" tabIndex="13" name="PostalCode" placeholder="Enter patient residential postal code" value={PostalCode} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.PostalCode.length > 0 && <span className='error'>{errors.PostalCode}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-12">
                                                                <label className="col-12 col-form-label">Resident Address</label>
                                                                <div className="col-12">
                                                                    <textarea className="form-control here" type="text" maxLength="500" tabIndex="14" name="Address" placeholder="Enter patient residential address" value={Address} onChange={this.handleInputChange.bind(this)}></textarea>
                                                                    {errors.Address.length > 0 && <span className='error'>{errors.Address}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Order of Country</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" name="OCountryId" value={OCountryId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Order of Country</option>
                                                                        {orderOfCountries
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.OCountryId.length > 0 && <span className='error'>{errors.OCountryId}</span>}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {loading ?
                                                            <button tabIndex="17" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                            :
                                                            <button tabIndex="17" type="submit" className="btn btn-primary">Submit</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
                    {this.loading()}
                </div>
            </React.Fragment >
        );
    }
}