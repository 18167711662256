import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../../CustomModal/CustomModal';

export class PatientInsuranceDetail extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            PatientId: 0,
            PatientInsuranceId: 0,
            InsuranceCompany: "",
            HealthPlan: "",
            MemberID: "",
            GroupNumber: "",
            PhoneNumber: "",
            Address: "",
            PolicyNumber: "",
            docFiles: [],
            DocumentFile: "",
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            errors: {
                InsuranceCompany: '',
                HealthPlan: '',
                MemberID: '',
                GroupNumber: '',
                PhoneNumber: '',
                Address: '',
                PolicyNumber: ''
            },

            deleteId: '',
            modal: false
        };
        this.state = this.initialState;
        this.toggle = this.toggle.bind(this);

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/patient/insurance/list');
    //    }
    //}

    componentDidMount() {
        const param = this.props.match.params;

        if (param.id != undefined) {
            this.getData(param.id);
        }
        else {
            this.setState({ loading: false });
        }
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientInsurance/GetById?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata.insuranceData;
                    var dData = result.data.outdata.docData;
                    console.log(dData);
                    this.setState({
                        PatientId: rData.patientId, InsuranceCompany: rData.insuranceCompany, HealthPlan: rData.healthPlan,
                        GroupNumber: rData.groupNumber, PhoneNumber: rData.phoneNumber, Address: rData.address,
                        MemberID: rData.memberID, PolicyNumber: rData.policyNumber, PatientInsuranceId: rData.patientInsuranceId,
                        loading: false, docFiles: dData
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        const validNumberRegex = RegExp(/^[0-9]+$/);
        const validMobileRegex = RegExp(/^[0-9+() -]+$/);

        switch (name) {
            case 'HealthPlan':
                errors.HealthPlan = (!value) ? 'Please enter health plan.' : '';
                break;
            case 'GroupNumber':
                errors.GroupNumber = (!value) ? 'Please enter group number.' : '';
                break;
            case 'PhoneNumber':
                errors.PhoneNumber = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : 'Please enter phone.';
                this.setState({ PhoneNumber: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                //errors.PhoneNumber = (!value) ? 'Please enter phone number.' : '';
                break;
            case 'Address':
                errors.Address = (!value) ? 'Please enter address.' : '';
                break;
            case 'InsuranceCompany':
                errors.InsuranceCompany = (!value) ? 'Please enter insurance company name.' : '';
                break;
            case 'MemberID':
                errors.MemberID = (!value) ? 'Please enter member id.' : '';
                break;
            case 'PolicyNumber':
                errors.PolicyNumber = (!value) ? 'Please enter policy number.' : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors}, () => {

        })
    }

    handleFileInputChange(event) {
        const target = event.target;
        const value = target.files;

        this.setState({
            DocumentFile: value
        });
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.InsuranceCompany == undefined || this.state.InsuranceCompany == '') {
            errors.InsuranceCompany = 'Please enter insurance company name.';
        }
        if (this.state.MemberID == undefined || this.state.MemberID == '') {
            errors.MemberID = 'Please enter member id.';
        }
        if (this.state.HealthPlan == undefined || this.state.HealthPlan == '') {
            errors.HealthPlan = 'Please select health plan.';
        }
        if (this.state.PolicyNumber == undefined || this.state.PolicyNumber == '') {
            errors.PolicyNumber = 'Please enter policy number.';
        }
        if (this.state.GroupNumber == undefined || this.state.GroupNumber == '') {
            errors.GroupNumber = 'Please enter group number.';
        }
        if (this.state.PhoneNumber == undefined || this.state.PhoneNumber == '') {
            errors.PhoneNumber = 'Please enter phone.';
        }
        if (this.state.Address == undefined || this.state.Address == '') {
            errors.Address = 'Please enter address.';
        }
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        if (this.validateForm(this.state.errors)) {
            var userToken = JSON.parse(localStorage.getItem('Usertoken'));

            let pid = (userToken.patientId == null ? 0 : userToken.patientId);

            const apiroute = window.$APIPath;
            if (this.state.PatientInsuranceId == 0) {
                url = apiroute + '/api/PatientInsurance/Save';
            }
            else {
                url = apiroute + '/api/PatientInsurance/Update';
            }

            let data = JSON.stringify({
                PatientId: parseInt(pid),
                InsuranceCompany: this.state.InsuranceCompany,
                HealthPlan: this.state.HealthPlan,
                MemberID: this.state.MemberID,
                PolicyNumber: this.state.PolicyNumber,
                GroupNumber: this.state.GroupNumber,
                PhoneNumber: this.state.PhoneNumber,
                Address: this.state.Address,
                PatientInsuranceId: parseInt(this.state.PatientInsuranceId),
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        if (this.state.DocumentFile != "") {
                            this.filesUploadDoc(result.data.outdata.patientInsuranceId, result.data.message)
                        }
                        else {
                            this.setState({
                                // authError: true,
                                // errorType: 'success',
                                // error: result.data.message,
                                loading: false,
                                redirect: true
                            });
                            toast.success(result.data.message)
                        }
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        } else {
            this.setState({ loading: false });
        }
    }

    filesUploadDoc(id, msg) {
        const apiroute = window.$APIPath;
        let url = apiroute + '/api/PatientInsuranceFile/Save?id=' + id + '';
        //alert(this.state.DocumentFile)
        let files = this.state.DocumentFile;
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append(`files[${i}]`, files[i])
        }
        axios.post(url, data, {
            // receive two    parameter endpoint url ,form data
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    // authError: true,
                    // errorType: 'success',
                    // error: msg,
                    redirect: true
                });
                toast.success(msg)
            }
            else {
                this.setState({
                    // authError: true,
                    // errorType: 'danger',
                    // error: result.data.message,
                    loading: false
                });
                toast.error(result.data.message)
            }
        }).catch(error => {
            console.log(error.message);
            this.setState({ loading: false });
        });
    }

    //delete(active/inactive) button click
    deleteRow(e, id) {
        e.preventDefault();

        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientInsuranceFile/Delete?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.success(result.data.message)
                    const param = this.props.match.params;
                    
                    if (param.id != undefined) {
                        this.getData(param.id);
                    }
                    this.toggle()
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/patient/insurance/list" />
        }
    }

    toggle(e, id) {
        this.setState({
          modal: !this.state.modal,
          deleteId: id
        });
      }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, InsuranceCompany, HealthPlan, MemberID, PolicyNumber, Address, GroupNumber, PhoneNumber,
            docFiles, DocumentFile, errors, error, errorType, authError, modal, deleteId } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                                <Link to="/patient/insurance/list" className="kt-subheader__title">
                                                    Insurance
                                                </Link>
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="/patient/insurance/list" className="kt-subheader__breadcrumbs-link">
                                                    Insurance Information                       </Link>
                                            </div>
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/patient/insurance/list" className="btn btn-primary btnBorder">
                                                Insurance
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Insurance Company Name<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="200" tabIndex="1" placeholder="Enter your insurance company name" name="InsuranceCompany" value={InsuranceCompany} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.InsuranceCompany.length > 0 && <span className='error'>{errors.InsuranceCompany}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Policy Number<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="text" className="form-control here" maxLength="50" tabIndex="2" name="PolicyNumber" placeholder="Enter a policy number" value={PolicyNumber} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.PolicyNumber.length > 0 && <span className='error'>{errors.PolicyNumber}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Health Plan<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="text" name="HealthPlan" tabIndex="3" maxLength="200" className="form-control" value={HealthPlan} onChange={this.handleInputChange.bind(this)} placeholder="Enter a health plan" />
                                                                    {errors.HealthPlan.length > 0 && <span className='error'>{errors.HealthPlan}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Member ID<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="text" className="form-control here" maxLength="200" tabIndex="4" name="MemberID" placeholder="Enter a member id" value={MemberID} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.MemberID.length > 0 && <span className='error'>{errors.MemberID}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Group Number<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="text" name="GroupNumber" tabIndex="5" maxLength="200" className="form-control" value={GroupNumber} onChange={this.handleInputChange.bind(this)} placeholder="Enter a group number" />
                                                                    {errors.GroupNumber.length > 0 && <span className='error'>{errors.GroupNumber}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Phone<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="text" className="form-control here" maxLength="200" tabIndex="6" name="PhoneNumber" placeholder="Enter your phone" value={PhoneNumber} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.PhoneNumber.length > 0 && <span className='error'>{errors.PhoneNumber}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-12">
                                                                <label className="col-12 col-form-label">Address<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="textarea" name="Address" tabIndex="7" maxLength="200" className="form-control" value={Address} onChange={this.handleInputChange.bind(this)} placeholder="Enter a address" />
                                                                    {errors.Address.length > 0 && <span className='error'>{errors.Address}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Policy File<span style={{ "color": "red","font-size": "16px","margin-left": "3px" }}>*</span><span className='error'> Multiple file upload</span></label>
                                                                <div className="col-12">
                                                                    <Input type="file" name="DocumentFile" id="File" className="form-control" multiple="multiple" tabIndex="8" onChange={this.handleFileInputChange.bind(this)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4" style={{ "marginLeft":"1%"}}>
                                                            {docFiles.length > 0 ? (
                                                                docFiles
                                                                    .map((data, i) => {
                                                                        return (
                                                                            <div className="kt-widget4 mr-20">
                                                                                <div className="kt-widget4__item" key={i}>
                                                                                    <div className="kt-widget4__pic kt-widget4__pic--icon">
                                                                                        <h5>{i + 1}.)</h5>{" "}
                                                                                    </div>
                                                                                    <a href={data.filePath} download className="kt-widget4__title">
                                                                                        <i className="flaticon2-download" />
                                                                                    </a>
                                                                                    <Link to="#" onClick={e => this.toggle(e, data.patientInsuranceFileId)}>
                                                                                        <i className="flaticon2-delete" />
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })) : (null)}
                                                        </div>
                                                        {loading ?
                                                            <button tabIndex="9" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                            :
                                                            <button tabIndex="9" type="submit" className="btn btn-primary">Submit</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
                    {this.loading()}
                    {modal && 
                        <div style={{ marginLeft: "36%" }}>    
                            <Modal isOpen={modal} style={{ width: "500px" }} >
                                <ModalHeader>Confirm</ModalHeader>
                                <ModalBody>Are you sure want to delete this insurance detail?</ModalBody>
                                <ModalFooter>
                                    <Button outline color="danger" onClick={e => this.deleteRow(e, deleteId)}>Delete</Button>
                                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    }
                </div>
            </React.Fragment >
        );
    }
}
