import React, { Component } from 'react';
import { ToastContainer } from "react-toastify";
//import { DiagnosticDetail } from './components/DiagnosticDetail';
import Routes from './routes';
import './custom.css'
import "react-datepicker/dist/react-datepicker.css";

export default class App extends Component {
    static displayName = App.name;
    
    render() {
        return (
            <>
              <ToastContainer 
                theme='colored' 
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Routes />
            </>
        );
    }
}
//<Route exact path="/login" component={Login} />
//    <Route exact path="/register" component={Register} />
//    <Route exact path="/patient-profile" component={PatientProfile} />
//    <Route exact path="/practitioner-profile" component={PractitionerProfile} />
//    <Route exact path="/confirmation" component={Confirmation} />
//    <Route exact path="/diagnostic-detail" component={DiagnosticDetail} />
//    <Layout>
//        <Route exact path='/' component={Home} />
//    </Layout>