import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../style-new.css'
import axios from 'axios';
import {  toast } from 'react-toastify';
//import MyModal from './../CustomModal/CustomModal';

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loading: false,
            // authError: false,
            error: '',
            usertype: 0,
            redirect: false,
            redirectr: false,
            redirectc: false,
            redirectf: false,
            isForgetPassword: false,
            forgetusername: '',
            errors: {
                username: '',
                password: '',
                forgetusername: ''
            },
        };

    }

    componentDidMount() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            var uType = userToken.userType;
            this.setState({ usertype: uType });
        }
    }
    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //}

    //input handle input change and validation
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'username':
                errors.username = (!value) ? 'Please enter email.' : ''
                break;
            case 'password':
                errors.password = (!value) ? 'Please enter password.' : ''
                break;
            case 'forgetusername':
                errors.forgetusername = (!value) ? 'Please enter email.' : ''
                break;
            default:

                //(!value) ? '' :'This field is required.'
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            //console.log(errors)

        })
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.username == undefined || this.state.username == '') {
            errors.username = 'Please enter email.';
        }
        if (this.state.password == undefined || this.state.password == '') {
            errors.password = 'Please enter password.';
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        if (this.validateForm(this.state.errors)) {

            const apiroute = window.$APIPath;
            const url = apiroute + '/api/CognitoUserStore/signin';
            const username = this.state.username;
            const password = this.state.password;

            let data = JSON.stringify({
                password: password.trim(),
                username: username.trim()
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                    //'Access-Control-Allow-Origin': '*',
                    //'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                    //'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }
            }).then(result => {
                if (result.data.flag) {
                    localStorage.setItem('token', JSON.stringify(result.data.token));
                    localStorage.setItem('Usertoken', JSON.stringify(result.data.outdata));
                    //console.log(JSON.stringify(result.data.outdata));
                    localStorage.setItem('isLoggedIn', true);
                    this.setState({ redirect: true, loading: false, usertype: result.data.outdata.userType });
                    // toast.success(result.data.message)
                }
                else {
                    //this.setState({
                    //    modal: !this.state.modal,
                    //    modalTitle: 'Error',
                    //    modalBody: result.data.message,
                    //    loading: false
                    //});
                    if (result.data.message) {
                        if (result.data.message.includes("Please reset your password.")) {
                            this.setState({ redirectr: true, loading: false, username: this.state.username });
                        }
                        else if (result.data.message.includes("Please verify your account.") || result.data.message.includes("User is not confirmed.")) {
                            this.setState({ redirectc: true, loading: false, username: this.state.username });
                        }
                        else {
                            this.setState({ 
                                // authError: true, error: result.data.message, 
                                loading: false });
                                toast.error(result.data.message)
                        }
                    }
                    else {
                        this.setState({ 
                            // authError: true, error: "Something went wrong.", 
                            loading: false });
                        toast.error("Something went wrong.")
                    }
                }
            }).catch(error => {
                console.log(error);
                this.setState({ 
                    // authError: true, error: error.message, 
                    loading: false });
                toast.error("Invalid Credentials!");
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    setForgetPass() {
        this.setState({ isForgetPassword: true });
    }

    removeForgetPass() {
        this.setState({ isForgetPassword: false });
    }

    //reset form validation
    validateForgetForm = (errors) => {
        let valid = true;

        if (this.state.forgetusername == undefined || this.state.forgetusername == '') {
            errors.forgetusername = 'Please enter email.';
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleForgetSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        if (this.validateForgetForm(this.state.errors)) {

            const apiroute = window.$APIPath;
            const url = apiroute + '/api/CognitoUserStore/ForgetPassword';
            const forgetusername = this.state.forgetusername;

            let data = JSON.stringify({
                username: forgetusername.toLowerCase()
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                    //'Access-Control-Allow-Origin': '*',
                    //'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                    //'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }
            }).then(result => {
                if (result.data.flag) {
                    //console.log(JSON.stringify(result.data.outdata));
                    this.setState({ 
                        // authError: true, error: result.data.message, 
                        loading: false });
                        toast.success(result.data.message)
                    setTimeout(() => this.setState({ redirectf: true }), 2000);
                }
                else {
                    //this.setState({
                    //    modal: !this.state.modal,
                    //    modalTitle: 'Error',
                    //    modalBody: result.data.message,
                    //    loading: false
                    //});
                    if (result.data.message) {
                        this.setState({ 
                            // authError: true, error: result.data.message, 
                            loading: false });
                            toast.error(result.data.message)
                    }
                    else {
                        this.setState({ 
                            // authError: true, error: "Something went wrong.", 
                            loading: false });
                            toast.error("Something went wrong.")
                    }
                }
            }).catch(error => {
                console.log(error);
                this.setState({ 
                    // authError: true, error: error.message, 
                    loading: false });
                    toast.error(error.message)
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            //{ return <Redirect from="/" to="/" /> } 

            if (this.state.usertype == 1) { return <Redirect from="/" to="/patient/dashboard" /> } //Patient
            else if (this.state.usertype == 2) { return <Redirect from="/" to="/practitioner/profile" /> }  //Practitioner
            else if (this.state.usertype == 3) { return <Redirect from="/" to="/institute/dashboard" /> } //Institution
            else if (this.state.usertype == 6) { return <Redirect from="/" to="/manufacturer/dashboard" /> } //Manufacturer
            else { return <Redirect from="/" to="/laboratory/dashboard" /> }//NeoLab

        }
    }

    renderRedirectReset() {
        if (this.state.redirectr) {
            return <Redirect from="/" to={"/setpassword/" + this.state.username + ""} />
        }
    }
    renderRedirectConfirm() {
        if (this.state.redirectc) {
            return <Redirect from="/" to={"/confirmation/" + this.state.username + ""} />
        }
    }
    renderRedirectForget() {
        if (this.state.redirectf) {
            return <Redirect from="/" to={"/forgetpassword/" + this.state.forgetusername + ""} />
        }
    }
    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        if (localStorage.getItem('Usertoken') == null) {
        }
        else {
            if (this.state.usertype == 1) { return <Redirect from="/" to="/patient/dashboard" /> } //Patient
            else if (this.state.usertype == 2) { return <Redirect from="/" to="/practitioner/profile" /> }  //Practitioner
            else if (this.state.usertype == 3) { return <Redirect from="/" to="/institute/dashboard" /> } //Institution
            else if (this.state.usertype == 6) { return <Redirect from="/" to="/manufacturer/dashboard" /> } //Manufacturer
            else { return <Redirect from="/" to="/laboratory/dashboard" /> }//NeoLab
        }
        const { username, password, forgetusername, isForgetPassword, loading, authError, error, errors } = this.state;
        return (
            <div className="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--transparent kt-aside--enabled kt-aside--fixed kt-page--loading">
                <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
                    <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed left-0">
                        <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand" kt-hidden-height="65">
                            <div className="kt-aside__brand-logo">
                                <Link to="/" >  <img alt="Logo" src="./assets/media/logos/logo-small.png" className="width-40" /></Link>
                            </div>
                        </div>
                        <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper opacity-1">
                            <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
                            </div>
                        </div>

                        {/*<div className="kt-header__topbar">
                            <div className="kt-header__topbar-item kt-header__topbar-item--user logo-menu">
                                <div className="kt-header__topbar-wrapper">
                                    <div className="kt-header__topbar-user">
                                        <Link to="/Services" >Services</Link>
                                    </div>
                                </div>
                                <div className="kt-header__topbar-wrapper">
                                    <div className="kt-header__topbar-user">
                                        <Link to="/HealthcareProfessional" >Healthcare Professionals</Link>
                                    </div>
                                </div>
                                <div className="kt-header__topbar-wrapper"> 
                                    <div className="kt-header__topbar-user">
                                        <Link to="/Caregivers" >Patients and Caregivers</Link>
                                    </div>
                                </div>
                            </div>
                        </div>*/}

                    </div>

                    <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v5 kt-login--signin" id="kt_login">
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile" style={{ backgroundImage: 'url(./assets/media/bg/bg-3.jpg)' }}>
                            <div className="kt-login__left">
                                <div className="kt-login__wrapper">
                                    <div className="kt-login__content">
                                        <Link to="/" className="kt-login__logo"> <img src="./assets/media/logos/logo-small.png" /></Link>

                                        <h3 className="kt-login__title">JOIN OUR NEO7LOGIX PORTAL</h3>
                                        {/*<div className="form-group">
                                            <label htmlFor="exampleSelectd">Click Here to Get Full Prescribing Information</label>
                                            <select className="form-control" id="exampleSelectd">
                                                <option>Select Prodcut</option>
                                                <option>PBIMA - PES</option>
                                                <option>SOLVx-COVID-19</option>
                                            </select>
                                        </div>*/}
                                        <ul className="location-link">
                                            <li className="item">
                                                <i className="fa fa-map-marker"></i>
                                                <span style={{ "color": "#000", "fontWeight": "400" }}>539 W. Commerce St #2886 Dallas, TX 75208</span>
                                            </li>
                                            {/*<li className="item">
                                                <i className="fa fa-clock" aria-hidden="true"></i>
                                                <span>
                                                    <p>Monday - Friday</p>
                                                    <p>09.00AM to 08:00PM</p>
                                                </span>
                                            </li>*/}
                                            <li className="item">
                                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                                <span><a href="mailto:office@neo7logix.com">office@neo7logix.com</a></span>
                                            </li>
                                            <li className="item">
                                                <i className="fa fa-phone" aria-hidden="true"></i>
                                                <span style={{ "color": "#000", "fontWeight": "400" }}><a href="tel:+4696197252">+‪(469) 619-7252</a></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="kt-login__divider"><div></div></div>

                            <div className="kt-login__right">
                                <div className="kt-login__wrapper">
                                    {!isForgetPassword ?
                                        (
                                            <div className="kt-login__signin">
                                                <div className="kt-login__head">
                                                    <h3 className="kt-login__title">Login To Your Account</h3>
                                                </div>
                                                <div className="kt-login__form">
                                                    <Form className="kt-form" onSubmit={this.handleSubmit.bind(this)}>
                                                        {/* {authError ?
                                                            <div className="alert alert-danger alert-dismissible" role="alert">
                                                                <div className="alert-text">{error}</div>
                                                                <div className="alert-close">
                                                                    <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                                    </div>
                                                                </div>
                                                            : null} */}
                                                        <div className="form-group mb-4">
                                                            <Input type="text" tabIndex="1" maxLength="100" placeholder="Email" name="username" onChange={this.handleInputChange.bind(this)} autoComplete="false" />
                                                            {errors.username.length > 0 && <span className='error'>{errors.username}</span>}
                                                        </div>
                                                        <div className="form-group">
                                                            <Input type="password" placeholder="Password" maxLength="100" tabIndex="2" name="password" onChange={this.handleInputChange.bind(this)} autoComplete="false" />
                                                            {errors.password.length > 0 && <span className='error'>{errors.password}</span>}
                                                        </div>
                                                        <div className="row kt-login__extra">
                                                            {/* <div className="col kt-align-left">
                                                        <label className="kt-checkbox">
                                                            <input type="checkbox" name="remember" /> Remember me
                                              <span></span>
                                                        </label>
                                                    </div>*/}
                                                            <div className="col kt-align-right">
                                                                <a style={{ "color": "#3641de" }} onClick={this.setForgetPass.bind(this)} id="kt_login_forgot" className="kt-link">Forgot Password ?</a>
                                                            </div>
                                                        </div>
                                                        <div className="kt-login__actions">
                                                            {loading ?
                                                                <button tabIndex="3" type="submit" className="btn btn-brand btn-elevate kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Log In</button>
                                                                :
                                                                <button tabIndex="3" type="submit" className="btn btn-brand btn-elevate">Log In</button>
                                                                /*btn-pill */
                                                            }
                                                        </div>
                                                    </Form>
                                                    {/* <div className="kt-login__actions">
                                                        <label htmlFor="">
                                                            <p className="font-weight-bold">Don't have an account?
                                                    <Link className="text-blue" to="/register" > Register</Link>
                                                            </p>
                                                        </label>
                                                    </div> */}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="kt-login__signin">
                                                <div className="kt-login__head">
                                                    <h3 className="kt-login__title">Forgotten Password ?</h3>
                                                    <div className="kt-login__desc">Enter your email to reset your password</div>
                                                </div>
                                                <div className="kt-login__form">
                                                    <form className="kt-form" action="">
                                                        {/* {authError ?
                                                            <div className="alert alert-danger alert-dismissible" role="alert">
                                                                <div className="alert-text">{error}</div>
                                                                <div className="alert-close">
                                                                    <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                                </div>
                                                            </div>
                                                            : null} */}
                                                        <div className="form-group mb-4">
                                                            <Input type="text" tabIndex="1" maxLength="100" placeholder="Email" name="forgetusername" onChange={this.handleInputChange.bind(this)} autoComplete="current-email" />
                                                            {errors.forgetusername.length > 0 && <span className='error'>{errors.forgetusername}</span>}
                                                        </div>
                                                        <div className="kt-login__actions">
                                                            {loading ?
                                                                <button id="kt_login_forgot_submit" className="btn btn-brand btn-elevate kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light">Request</button>
                                                                :
                                                                <button onClick={this.handleForgetSubmit.bind(this)} id="kt_login_forgot_submit" className="btn btn-brand btn-elevate">Request</button>
                                                                /*btn-pill */
                                                            }

                                                            <button onClick={this.removeForgetPass.bind(this)} id="kt_login_forgot_cancel" className="btn btn-outline-brand">Cancel</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.loading()}
                {this.renderRedirect()}
                {this.renderRedirectReset()}
                {this.renderRedirectConfirm()}
                {this.renderRedirectForget()}
            </div>
        );
    }
}
