import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import MyModal from './../../CustomModal/CustomModal';

export class PatientDiseaseDetail extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            PatientId: 0,
            PatientDiagnosticId: 0,
            DiseaseId: "",
            diseases: [],
            errors: {
                DiseaseId: '',
            },
            redirect: false,
            modal: false,
            modalTitle: '',
            modalBody: ''
        };
        this.state = this.initialState;

    }

    //modal close button event
    handleModalClose = () => {
        this.setState({
            modal: false,
            modalTitle: '',
            modalBody: ''
        });
        if (this.state.redirect) {
            this.props.history.push('/patient/disease/list');
        }
    }

    componentDidMount() {


        const apiroute = window.$APIPath;
        const url = apiroute + '/api/Patientdisease/getalldisease';
        let data = JSON.stringify({
            isDeleted: true,
            searchString: ''
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    diseases: result.data.outdata
                }, () => { this.getData() });
            }
        }).catch(error => {
            console.log(error);
        });


        const param = this.props.match.params;

        if (param.id != undefined) {
            this.getData(param.id);
        }
        else {
            this.setState({ loading: false });
        }
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientDisease/GetById?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;
                    this.setState({
                        PatientId: rData.patientId, PatientDiseaseId: rData.patientDiseaseId, DiseaseId: rData.diseaseId, loading: false,
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.DiseaseId == undefined || this.state.DiseaseId == '') {
            errors.DiseaseId = 'Please select disease.';
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'DiseaseId':
                errors.DiseaseId = (!value) ? 'Please select disease.' : '';

                break;

            default:
                break;
        }
    }



    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";
        let pid = 0;
        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));

        if (userToken != null) {
            pid = (userToken.patientId == null ? 0 : userToken.patientId);
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }
        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            if (this.state.PatientDiseaseId == 0) {
                url = apiroute + '/api/PatientDisease/Save';
            }
            else {
                url = apiroute + '/api/PatientDisease/Update';
            }

            let data = JSON.stringify({
                PatientId: parseInt(pid),
                DiseaseId: this.state.DiseaseId,
                PatientDiseaseId: parseInt(this.state.PatientDiseaseId),
                createdBy: uid,
                createdByFlag: "S"
            })

            console.log(data);
        }
        else {
            this.setState({
                modal: !this.state.modal,
                modalTitle: 'Error',
                modalBody: "Please fill all the fields."
            });
            this.setState({ loading: false });
        }
    }



    render() {
        const { loading, diseases, errors } = this.state;

        return (
            <div className="mt-100">
                <div className="profile-pg">
                    <div className="container-fluid">
                      
                        <div className="row">
                            <div className="col-md-3">
                                <div className="user-profile-inner">
                                    <div className="list-group ">

                                        <a href="/patient/dashboard" className="list-group-item list-group-item-action">Dashboard</a>
                                        <a href="/patient/profile" className="list-group-item list-group-item-action">Patient Information</a>
                                        <a href="/patient/diagnostic/list" className="list-group-item list-group-item-action">Diagnostic Information</a>
                                        <a href="/patient/disease/list" className="list-group-item list-group-item-action active">Disease Information</a>
                                        <a href="/patient/treatment/list" className="list-group-item list-group-item-action">Treatment Information</a>
                                        <a href="/patient/prescription/list" className="list-group-item list-group-item-action">Prescription Information</a>
                                        <a href="/patient/emergency/list" className="list-group-item list-group-item-action">Emergency Contact</a>
                                        <a href="/patient/insurance/list" className="list-group-item list-group-item-action">Insurance Information</a>
                                        <a href="/patient/payment/detail" className="list-group-item list-group-item-action">Payment Details</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="user-profile-inner">

                                    <div className="widgets-main bootstrap snippet p-3 m-0">
                                        <Form onSubmit={this.handleSubmit.bind(this)}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-9">
                                                                    <h4>Patient Disease Detail</h4>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <a href="/patient/disease/list" className="primary-btn addButton">List</a>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div className="col-md-6">
                                                                    <label className="col-12 col-form-label">Disease</label>
                                                                    <div className="col-12">
                                                                        <Input type="select" className="custom-select mb-3" tabIndex="6" name="CountryId" value={diseases} onChange={this.handleInputChange.bind(this)}>
                                                                            <option value="">Select Disease</option>
                                                                            {diseases
                                                                                .map((data, i) => {
                                                                                    return (<option key={i} value={data.DiseaseId}>{data.diseaseName}</option>);
                                                                                })}
                                                                        </Input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6"></div>
                                                                {/* {loading ?
                                                                    <div className="form-group row my-4 mx-0">
                                                                        <div className="animated fadeIn pt-1 text-center">Loading...</div>
                                                                    </div>
                                                                    : */}
                                                                    <div className="form-group row my-4 mx-0">
                                                                        <button className="primary-btn" type="submit" tabIndex="4" >Submit</button>
                                                                    </div>
                                                                {/* } */}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MyModal
                    handleModal={this.handleModalClose.bind(this)}
                    //modalAction={this.state.modalAction}
                    isOpen={this.state.modal}
                    modalBody={this.state.modalBody}
                    modalTitle={this.state.modalTitle}
                    modalOptions={this.state.modalOptions}
                />
            </div >
        );
    }
}
