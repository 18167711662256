import React, { Component } from 'react';
import { Form, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../../CustomModal/CustomModal';

export class PractitionerContactDetail extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            practitionerId: 0,
            practitionerContactPersonId: 0,
            name: "",
            mobile: "",
            email: "",
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            errors: {
                name: '',
                mobile: '',
                email: '',
            },
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/patient/insurance/list');
    //    }
    //}

    componentDidMount() {
        const param = this.props.match.params;

        if (param.id != undefined) {
            this.getData(param.id);
        }
        else {
            this.setState({ loading: false });
        }
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PractitionerContactPerson/GetById?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;
                    this.setState({
                        practitionerId: rData.practitionerId, name: rData.name, mobile: rData.mobile,
                        email: rData.email, practitionerContactPersonId: rData.practitionerContactPersonId,
                        loading: false, 
                    });
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        const validMobileRegex = RegExp(/^[0-9+() -]+$/);
        const validAlphaRegex = RegExp(/^[a-zA-Z \b]+$/);
        const validEmailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        switch (name) {
            case 'mobile':
                errors.mobile = (value) ? (validMobileRegex.test(value) ? '' : 'Only numbers allowed.') : 'Please enter phone';
                this.setState({ mobile: value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') })
                break;
            case 'name':
                errors.name = (!value) ? 'Please enter name.' : (validAlphaRegex.test(value) ? '' : 'Only alphabets allowed.');
                this.setState({ name: value.replace(/[^a-zA-Z \b]+$/, '') })
                break;
            case 'email':
                errors.email = (!value) ? 'Please enter email.' : (validEmailRegex.test(value) ? '' : 'Invalid Email.');
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors }, () => {

        })
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.name == undefined || this.state.name == '') {
            errors.name = 'Please enter name.';
        }
        //if (this.state.mobile == undefined || this.state.mobile == '') {
        //    errors.mobile = 'Please enter mobile.';
        //}
        if (this.state.email == undefined || this.state.email == '') {
            errors.email = 'Please enter email.';
        }
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        if (this.validateForm(this.state.errors)) {
            var userToken = JSON.parse(localStorage.getItem('Usertoken'));
            let pid = (userToken.practitionerId == null ? 0 : userToken.practitionerId);
            let uid = (userToken.userId == null ? 0 : userToken.userId);

            const apiroute = window.$APIPath;
            if (this.state.practitionerContactPersonId == 0) {
                url = apiroute + '/api/PractitionerContactPerson/Save';
            }
            else {
                url = apiroute + '/api/PractitionerContactPerson/Update';
            }

            let data = JSON.stringify({
                practitionerId: parseInt(pid),
                name: this.state.name,
                mobile: this.state.mobile,
                email: this.state.email,
                practitionerContactPersonId: parseInt(this.state.practitionerContactPersonId),
                createdBy: parseInt(uid),
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            redirect: true
                        });
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        } else {
            this.setState({ loading: false });
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/practitioner/contact/list" />
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, name, mobile, email, errors, error, errorType, authError } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                                <Link to="/practitioner/contact/list" className="kt-subheader__title">
                                                    Contact Persons
                                                </Link>
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="/practitioner/contact/detail" className="kt-subheader__breadcrumbs-link">
                                                    Contact Person Detail                      
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/practitioner/contact/list" className="btn btn-primary btnBorder">
                                                Contact Persons
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="120" tabIndex="1" placeholder="Enter name" name="name" value={name} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.name.length > 0 && <span className='error'>{errors.name}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Phone</label>
                                                                <div className="col-12">
                                                                    <Input type="text" name="mobile" tabIndex="2" maxLength="20" className="form-control" value={mobile} onChange={this.handleInputChange.bind(this)} placeholder="Enter phone" />
                                                                    {errors.mobile.length > 0 && <span className='error'>{errors.mobile}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Email<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="text" className="form-control here" maxLength="100" tabIndex="3" name="email" placeholder="Enter email" value={email} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.email.length > 0 && <span className='error'>{errors.email}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {loading ?
                                                            <button tabIndex="4" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                            :
                                                            <button tabIndex="4" type="submit" className="btn btn-primary">Submit</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
                    {this.loading()}
                </div>
            </React.Fragment >
        );
    }
}
