import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input, Button } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
//import MyModal from './../../CustomModal/CustomModal';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import downloadIcon from '../../../assets/svg/download.svg';
import closeIcon from '../../../assets/svg/x.svg';

export class PatientDashboard extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            patientAccession: [],
            patientAccessionId:"",
            patienttimeline: [],
            patientSampleData: [],
            patientSampleDataFilter: [],
            patientDesignData: "",
            patientManufactureData: "",
            patientShippingData: [],
            SampleData: [],
            sampleTypeId: "",
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            patientCurrentFlag: false,
            patientCurrentStatus: "",
            preview: false,
            url: "",

        };
        this.state = this.initialState;

    }

    componentDidMount() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            this.getListData();
        }
        else {
            return <Redirect to="/" />
        }
    }

    getListData() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.patientId == null ? 0 : userToken.patientId);

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/Patient/getListOfAccessionNoByPatientId?id=' + pid;


        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    console.log(result.data.outdata);
                    if (result.data.outdata.length == 1)
                    {
                        this.getData(parseInt(result.data.outdata[0].patientAccessionId));
                    }
                    this.setState({
                        
                        patientAccession: result.data.outdata,
                        loading: false
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    getData() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.patientId == null ? 0 : userToken.patientId);

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientTimelineChart/GetPatientTimelineChart?id=' + pid + '';

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;
                    var patientData = rData.patientTimelineChartData;
                    console.log(rData);
                    this.setState({
                        patienttimeline: patientData,
                        patientSampleData: patientData.ngsLaboratoryPatientActivity,
                        patientDesignData: patientData.designActivity,
                        patientManufactureData: patientData.manufacturingActivity,
                        SampleData: rData.sampleTypeData,
                        patientCurrentFlag: true,
                        loading: false
                    });

                    if (rData.sampleTypeData.length > 0) {
                        let sampleval = rData.sampleTypeData[0].sampleTypeId;
                        let showdata = patientData.ngsLaboratoryPatientActivity.filter(pa => pa.sampleTypeId == parseInt(sampleval));
                        this.setState({
                            sampleTypeId: sampleval,
                            patientSampleDataFilter: showdata
                        });
                    }
                }
                else {
                    this.setState({ patientCurrentFlag: result.data.flag, patientCurrentStatus: result.data.message, loading: false });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name == "sampleTypeId") {
            if (value != "" && value != "0") {
                let showdata = this.state.patientSampleData.filter(pa => pa.sampleTypeId == parseInt(value));
                console.log(showdata);
                this.setState({
                    patientSampleDataFilter: showdata
                });
            }
            else {
                this.setState({
                    patientSampleDataFilter: []
                });
            }
        }
    }

    handleInputPatientChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name == "patientAccessionId") {
            if (value != "" && value != "0") {
                this.getData(parseInt(value))
            }
            else {
                this.setState({
                    patienttimeline: [],
                    patientSampleData: [],
                    patientSampleDataFilter: [],
                    patientDesignData: "",
                    patientManufactureData: "",
                    patientShippingData: [],
                    SampleData: [],
                    sampleTypeId: "",
                });
            }
        }
    }

    ProfileClick() {
        this.setState({
            redirect: true
        });
    }

    //download
    DownloadFile(e, filepath) {
        //alert(filename);
        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        axios({
            url: apiroute + '/api/CognitoUserStore/downloadFile?fileName=' + filepath + '',
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            console.log(response);
            var fname = filepath.substring(filepath.lastIndexOf('/') + 1);
            //alert(fname);
            var fext = fname.substring(fname.lastIndexOf('.'));
            //alert(fext);
            var filename = "Patient_Report" + fext;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            this.setState({ loading: false });
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });

    }

    //file preview
    previewToggle (e, file) {
        this.setState({
        preview: !this.state.preview,
        url: window.$FileUrl + file
        })
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect to="/patient/profile" />
        }
    }

    render() {
        const { loading, authError, errorType, error, patienttimeline, patientSampleData, SampleData, sampleTypeId, preview, url,
            patientDesignData, patientSampleDataFilter, patientManufactureData, patientShippingData, patientCurrentFlag,
            patientCurrentStatus, patientAccession, patientAccessionId } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                                Dashboard
                           </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link href="/patient/dashboard" className="kt-subheader__breadcrumbs-link">
                                                    Dashboard                        </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        {/* {authError ?
                                            <div>
                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                    <div className="alert-text">{error}</div>
                                                    <div className="alert-close">
                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                                        <div className="kt-portlet__body">{/*kt-portlet__body--fit*/}
                                            <React.Fragment>
                                                <div className="form-group row my-4">
                                                    <div className="col-4">
                                                        {(patientAccession.length > 1 ?
                                                            <Input type="select" name="patientAccessionId" value={patientAccessionId} onChange={this.handleInputPatientChange.bind(this)}>
                                                                <option value="">Select Accession Number</option>
                                                                {patientAccession
                                                                    .map((data, i) => {
                                                                        return (<option key={i} value={data.patientAccessionId}>{data.accessionNo.replace(/-/g, "")}</option>);
                                                                    })}

                                                                {/*return (<option key={i} value={data.patientId}>{data.firstName + " " + data.lastName + " (" + data.accessionNo + ")"}</option>);*/}
                                                            </Input>
                                                            : null)}
                                                    </div>
                                                </div>
                                                <div className="kt-grid  kt-wizard-v1 kt-wizard-v1--white" id="kt_wizard_v1" data-ktwizard-state="step-first">
                                                    {!patientCurrentFlag ?
                                                        (patientCurrentStatus != "" && patientCurrentStatus != null ?
                                                            <React.Fragment>
                                                                <div>
                                                                    <div className={"alert alert-danger alert-dismissible"} role="alert">
                                                                        <div className="alert-text">{patientCurrentStatus}</div>
                                                                    </div>
                                                                </div>
                                                                {(patientCurrentStatus == "Your profile is not fully updated." ?
                                                                    <div style={{ "textAlign": "center" }}>
                                                                        <Button onClick={this.ProfileClick.bind(this)} color="primary" className="px-4">
                                                                            Patient Information                        </Button>
                                                                    </div>
                                                                    : null)}
                                                            </React.Fragment>
                                                            :
                                                            null)
                                                        :
                                                        <React.Fragment>
                                                            <div className="kt-grid__item">
                                                                <div className="kt-wizard-v1__nav">
                                                                    <div className="kt-wizard-v1__nav-items">
                                                                        <a className="kt-wizard-v1__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
                                                                            <div className="kt-wizard-v1__nav-body">
                                                                                <div className="kt-wizard-v1__nav-icon">
                                                                                    {/*  <i className="flaticon-bus-stop"></i> */}
                                                                                    <img src="./assets/media/logos/phase1.png" className="phase-icn" />
                                                                                </div>
                                                                                <div className="kt-wizard-v1__nav-label">
                                                                                    Analysis
                                                                    </div>
                                                                                {patienttimeline.sampleStatus != "Pending" ?
                                                                                    patienttimeline.sampleStatus != "Running"
                                                                                        ?
                                                                                        <span className="status-phase status-phase-c">Completed</span>
                                                                                        :
                                                                                        <span className="status-phase status-phase-r">Running</span>
                                                                                    :
                                                                                    <span className="status-phase status-phase-p">Pending</span>
                                                                                }
                                                                            </div>

                                                                        </a>
                                                                        {patienttimeline.currentPhase > 1 ?
                                                                            <a className="kt-wizard-v1__nav-item" data-ktwizard-type="step">
                                                                                <div className="kt-wizard-v1__nav-body">
                                                                                    <div className="kt-wizard-v1__nav-icon">
                                                                                        {/*  <i className="flaticon-list"></i> */}
                                                                                        <img src="./assets/media/logos/phase2.png" className="phase-icn" />
                                                                                    </div>
                                                                                    <div className="kt-wizard-v1__nav-label">
                                                                                        Design
                        </div>
                                                                                </div>
                                                                                {patienttimeline.designStatus != "Pending" ?
                                                                                    patienttimeline.designStatus != "Running"
                                                                                        ?
                                                                                        <span className="status-phase status-phase-c">Completed</span>
                                                                                        :
                                                                                        <span className="status-phase status-phase-r">Running</span>
                                                                                    :
                                                                                    <span className="status-phase status-phase-p">Pending</span>
                                                                                }
                                                                            </a>
                                                                            :
                                                                            <a className="kt-wizard-v1__nav-item" disabled>
                                                                                <div className="kt-wizard-v1__nav-body">
                                                                                    <div className="kt-wizard-v1__nav-icon">
                                                                                        {/*  <i className="flaticon-list"></i> */}
                                                                                        <img src="./assets/media/logos/phase2.png" className="phase-icn" />
                                                                                    </div>
                                                                                    <div className="kt-wizard-v1__nav-label">
                                                                                        Design
                                                                            </div>
                                                                                </div>
                                                                                <span className="status-phase status-phase-p">Pending</span>
                                                                            </a>
                                                                        }

                                                                        {patienttimeline.currentPhase > 2 ?
                                                                            <a className="kt-wizard-v1__nav-item" data-ktwizard-type="step">
                                                                                <div className="kt-wizard-v1__nav-body">
                                                                                    <div className="kt-wizard-v1__nav-icon">
                                                                                        {/*  <i className="flaticon-responsive"></i> */}
                                                                                        <img src="./assets/media/logos/phase3.png" className="phase-icn" />
                                                                                    </div>
                                                                                    <div className="kt-wizard-v1__nav-label">
                                                                                        Manufacturing
                        </div>
                                                                                </div>
                                                                                {patienttimeline.manufactureStatus != "Pending" ?
                                                                                    patienttimeline.manufactureStatus != "Running"
                                                                                        ?
                                                                                        <span className="status-phase status-phase-c">Completed</span>
                                                                                        :
                                                                                        <span className="status-phase status-phase-r">Running</span>
                                                                                    :
                                                                                    <span className="status-phase status-phase-p">Pending</span>
                                                                                }
                                                                            </a>
                                                                            :
                                                                            <a className="kt-wizard-v1__nav-item" disabled>
                                                                                <div className="kt-wizard-v1__nav-body">
                                                                                    <div className="kt-wizard-v1__nav-icon">
                                                                                        {/*  <i className="flaticon-responsive"></i> */}
                                                                                        <img src="./assets/media/logos/phase3.png" className="phase-icn" />
                                                                                    </div>
                                                                                    <div className="kt-wizard-v1__nav-label">
                                                                                        Manufacturing
                        </div>
                                                                                </div>
                                                                                <span className="status-phase status-phase-p">Pending</span>
                                                                            </a>
                                                                        }

                                                                        {patienttimeline.currentPhase > 3 ?
                                                                            <a className="kt-wizard-v1__nav-item" data-ktwizard-type="step">
                                                                                <div className="kt-wizard-v1__nav-body">
                                                                                    <div className="kt-wizard-v1__nav-icon">
                                                                                        {/* <i className="flaticon-truck"></i> */}
                                                                                        <img src="./assets/media/logos/phase4.png" className="phase-icn" />
                                                                                    </div>
                                                                                    <div className="kt-wizard-v1__nav-label">
                                                                                        Shipping
                        </div>
                                                                                </div>
                                                                                {patienttimeline.shippingStatus != "Pending" ?
                                                                                    patienttimeline.shippingStatus != "Running"
                                                                                        ?
                                                                                        <span className="status-phase status-phase-c">Completed</span>
                                                                                        :
                                                                                        <span className="status-phase status-phase-r">Running</span>
                                                                                    :
                                                                                    <span className="status-phase status-phase-p">Pending</span>
                                                                                }
                                                                            </a>
                                                                            :
                                                                            <a className="kt-wizard-v1__nav-item" disabled>
                                                                                <div className="kt-wizard-v1__nav-body">
                                                                                    <div className="kt-wizard-v1__nav-icon">
                                                                                        {/* <i className="flaticon-truck"></i> */}
                                                                                        <img src="./assets/media/logos/phase4.png" className="phase-icn" />
                                                                                    </div>
                                                                                    <div className="kt-wizard-v1__nav-label">
                                                                                        Shipping
                        </div>
                                                                                </div>
                                                                                <span className="status-phase status-phase-p">Pending</span>
                                                                            </a>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper">
                                                                <form className="kt-form" id="kt_form">
                                                                    <div className="kt-wizard-v1__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                                                                        <div className="kt-heading kt-heading--md row">
                                                                            <div className="col-8">Analysis
                                                                    </div>
                                                                            <div className="col-4">
                                                                                <Input type="select" name="sampleTypeId" value={sampleTypeId} onChange={this.handleInputChange.bind(this)}>
                                                                                    <option value="">Select Status</option>
                                                                                    {SampleData
                                                                                        .map((data, i) => {
                                                                                            return (<option key={i} value={data.sampleTypeId}>{data.sampleTypeName}</option>);
                                                                                        })}
                                                                                </Input>
                                                                            </div>
                                                                        </div>
                                                                        <div className="kt-form__section kt-form__section--first">
                                                                            <div className="kt-wizard-v1__form">
                                                                                <div className="kt-form__section kt-form__section--first">
                                                                                    <div className="kt-wizard-v2__form">
                                                                                        <div className="kt-timeline-v2">
                                                                                            <div className="kt-timeline-v2__items  kt-padding-top-25 kt-padding-bottom-30">
                                                                                                {patientSampleDataFilter.length > 0 ?
                                                                                                    patientSampleDataFilter
                                                                                                        .map((data, i) => {
                                                                                                            return (
                                                                                                                <React.Fragment>
                                                                                                                    {data.sampleReceived ?
                                                                                                                        <div className="kt-timeline-v2__item">
                                                                                                                            <span className="kt-timeline-v2__item-time">
                                                                                                                                < Moment format="DD MMM YYYY">
                                                                                                                                    {data.sampleReceivedDate}
                                                                                                                                </Moment>
                                                                                                                            </span>
                                                                                                                            <div className="kt-timeline-v2__item-cricle">
                                                                                                                                <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                                            </div>
                                                                                                                            <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                                Sample Received
                                                                                                </div>
                                                                                                                        </div>
                                                                                                                        : ""}

                                                                                                                    {data.sampleQCPassed ?
                                                                                                                        <div className="kt-timeline-v2__item">
                                                                                                                            <span className="kt-timeline-v2__item-time">
                                                                                                                                <Moment format="DD MMM YYYY">
                                                                                                                                    {data.sampleQCPassedDate}
                                                                                                                                </Moment>
                                                                                                                            </span>
                                                                                                                            <div className="kt-timeline-v2__item-cricle">
                                                                                                                                <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                                            </div>
                                                                                                                            <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                                Sample QC Passed
                                                                                                </div>
                                                                                                                        </div>
                                                                                                                        : (data.sampleQCFailed ?
                                                                                                                            (data.newSampleRequested ?
                                                                                                                                <div className="kt-timeline-v2__item">
                                                                                                                                    <span className="kt-timeline-v2__item-time">
                                                                                                                                        <Moment format="DD MMM YYYY">
                                                                                                                                            {data.newSampleRequestedDate}
                                                                                                                                        </Moment>
                                                                                                                                    </span>
                                                                                                                                    <div className="kt-timeline-v2__item-cricle">
                                                                                                                                        <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                                                    </div>
                                                                                                                                    <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                                        New Sample Requested
                                                                                                </div>
                                                                                                                                </div> :
                                                                                                                                <div className="kt-timeline-v2__item">
                                                                                                                                    <span className="kt-timeline-v2__item-time">
                                                                                                                                        <Moment format="DD MMM YYYY">
                                                                                                                                            {data.sampleQCFailedDate}
                                                                                                                                        </Moment>
                                                                                                                                    </span>
                                                                                                                                    <div className="kt-timeline-v2__item-cricle">
                                                                                                                                        <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                                                    </div>
                                                                                                                                    <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                                        Sample QC Failed
                                                                                                </div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                            : "Sample QC Pending")}

                                                                                                                    {data.analysisStarted ?
                                                                                                                        <div className="kt-timeline-v2__item" >
                                                                                                                            <span className="kt-timeline-v2__item-time">
                                                                                                                                <Moment format="DD MMM YYYY">
                                                                                                                                    {data.analysisStartedDate}
                                                                                                                                </Moment>
                                                                                                                            </span>
                                                                                                                            <div className="kt-timeline-v2__item-cricle">
                                                                                                                                <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                                            </div>
                                                                                                                            <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                                Sample Analysis Started
                                                                                                    </div>
                                                                                                                        </div>
                                                                                                                        : ""}

                                                                                                                    {data.analysisQCPassed ?
                                                                                                                        <div className="kt-timeline-v2__item" >
                                                                                                                            <span className="kt-timeline-v2__item-time">
                                                                                                                                <Moment format="DD MMM YYYY">
                                                                                                                                    {data.analysisQCPassedDate}
                                                                                                                                </Moment>
                                                                                                                            </span>
                                                                                                                            <div className="kt-timeline-v2__item-cricle">
                                                                                                                                <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                                            </div>
                                                                                                                            <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                                Sample Analysis QC Passed
                                                                                                </div>
                                                                                                                        </div>
                                                                                                                        : ""}

                                                                                                                    {data.analysisCompleted ?
                                                                                                                        <div className="kt-timeline-v2__item">
                                                                                                                            <span className="kt-timeline-v2__item-time">
                                                                                                                                <Moment format="DD MMM YYYY">
                                                                                                                                    {data.analysisCompletedDate}
                                                                                                                                </Moment>
                                                                                                                            </span>
                                                                                                                            <div className="kt-timeline-v2__item-cricle">
                                                                                                                                <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                                            </div>
                                                                                                                            <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                                Sample Analysis Completed
                                                                                                </div>
                                                                                                                        </div>
                                                                                                                        : ""}

                                                                                                                    {data.dataFileReady ?
                                                                                                                        <div className="kt-timeline-v2__item">
                                                                                                                            <span className="kt-timeline-v2__item-time">
                                                                                                                                <Moment format="DD MMM YYYY">
                                                                                                                                    {data.dataFileReadyDate}
                                                                                                                                </Moment>
                                                                                                                            </span>
                                                                                                                            <div className="kt-timeline-v2__item-cricle">
                                                                                                                                <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                                            </div>
                                                                                                                            <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                                Analysis data ready to send
                                                                                                </div>
                                                                                                                        </div>
                                                                                                                        : ""}


                                                                                                                    {data.analysisDataAndFileTransferred ?
                                                                                                                        <div className="kt-timeline-v2__item">
                                                                                                                            <span className="kt-timeline-v2__item-time">
                                                                                                                                <Moment format="DD MMM YYYY">
                                                                                                                                    {data.fileTransferredDate}
                                                                                                                                </Moment>
                                                                                                                            </span>
                                                                                                                            <div className="kt-timeline-v2__item-cricle">
                                                                                                                                <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                                            </div>
                                                                                                                            <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                                Data delivered
                                                                                                </div>
                                                                                                                        </div>
                                                                                                                        : ""}
                                                                                                                </React.Fragment>
                                                                                                            )
                                                                                                        }) : ""}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="kt-wizard-v1__content" data-ktwizard-type="step-content">
                                                                        <div className="kt-heading kt-heading--md">Design</div>
                                                                        <div className="kt-form__section kt-form__section--first">
                                                                            <div className="kt-wizard-v1__form">
                                                                                <div className="kt-timeline-v2">
                                                                                    <div className="kt-timeline-v2__items  kt-padding-top-25 kt-padding-bottom-30">
                                                                                        {patientDesignData != null && patientDesignData != "" ?
                                                                                            <React.Fragment>
                                                                                                {patientDesignData.designStarted ?
                                                                                                    <div className="kt-timeline-v2__item" >
                                                                                                        <span className="kt-timeline-v2__item-time">
                                                                                                            <Moment format="DD MMM YYYY">
                                                                                                                {patientDesignData.designStartedDate}
                                                                                                            </Moment>
                                                                                                        </span>
                                                                                                        <div className="kt-timeline-v2__item-cricle">
                                                                                                            <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                        </div>
                                                                                                        <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                            Design Started
                                                                                        </div>
                                                                                                    </div>
                                                                                                    : ""}

                                                                                                {patientDesignData.designCompleted ?
                                                                                                    <div className="kt-timeline-v2__item">
                                                                                                        <span className="kt-timeline-v2__item-time">
                                                                                                            <Moment format="DD MMM YYYY">
                                                                                                                {patientDesignData.designCompletedDate}
                                                                                                            </Moment>
                                                                                                        </span>
                                                                                                        <div className="kt-timeline-v2__item-cricle">
                                                                                                            <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                        </div>
                                                                                                        <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                            Design Completed
                                                                                                </div>
                                                                                                    </div>
                                                                                                    : ""}

                                                                                                {patientDesignData.designDeliveredToMfg ?
                                                                                                    <React.Fragment>
                                                                                                        <div className="kt-timeline-v2__item">
                                                                                                            <span className="kt-timeline-v2__item-time">
                                                                                                                <Moment format="DD MMM YYYY">
                                                                                                                    {patientDesignData.designDeliveredDate}
                                                                                                                </Moment>
                                                                                                            </span>
                                                                                                            <div className="kt-timeline-v2__item-cricle">
                                                                                                                <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                            </div>
                                                                                                            <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                Design delivered to Manufacturer
                                                                                                </div>
                                                                                                        </div>
                                                                                                        <div className="kt-timeline-v2__item">
                                                                                                            <span className="kt-timeline-v2__item-time">
                                                                                                            </span>
                                                                                                            <div className="kt-timeline-v2__item-cricle">
                                                                                                            </div>
                                                                                                            <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                                <Link style={{ "cursor": "pointer", "color": "#20A8D8" }} onClick={e => this.previewToggle(e, patientDesignData.patientReport)}><i className="flaticon2-download"></i> Download Report</Link>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </React.Fragment>
                                                                                                    : ""}
                                                                                            </React.Fragment>

                                                                                            : ""}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="kt-wizard-v1__content" data-ktwizard-type="step-content">
                                                                        <div className="kt-heading kt-heading--md">Manufacturing</div>
                                                                        <div className="kt-form__section kt-form__section--first">
                                                                            <div className="kt-wizard-v1__form">
                                                                                <div className="kt-timeline-v2">
                                                                                    <div className="kt-timeline-v2__items  kt-pReview your Details and Submitadding-top-25 kt-padding-bottom-30">
                                                                                        {patientManufactureData != null && patientManufactureData != "" ?
                                                                                            <React.Fragment>
                                                                                                {patientManufactureData.readyToMfg ?
                                                                                                    <div className="kt-timeline-v2__item" >
                                                                                                        <span className="kt-timeline-v2__item-time">

                                                                                                        </span>
                                                                                                        <div className="kt-timeline-v2__item-cricle">
                                                                                                            <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                        </div>
                                                                                                        <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                            Ready to Manufacture
                                                                                                </div>
                                                                                                    </div>
                                                                                                    : ""}

                                                                                                {patientManufactureData.mfgStarted ?
                                                                                                    <div className="kt-timeline-v2__item">
                                                                                                        <span className="kt-timeline-v2__item-time">
                                                                                                            <Moment format="DD MMM YYYY">
                                                                                                                {patientManufactureData.MfgStartedDate}
                                                                                                            </Moment>
                                                                                                        </span>
                                                                                                        <div className="kt-timeline-v2__item-cricle">
                                                                                                            <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                        </div>
                                                                                                        <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                            Started Manufacturing
                                                                                                </div>
                                                                                                    </div>
                                                                                                    : ""}

                                                                                                {patientManufactureData.mfgQcPassed ?
                                                                                                    <div className="kt-timeline-v2__item">
                                                                                                        <span className="kt-timeline-v2__item-time">
                                                                                                            <Moment format="DD MMM YYYY">
                                                                                                                {patientManufactureData.MfgQcPassedDate}
                                                                                                            </Moment>
                                                                                                        </span>
                                                                                                        <div className="kt-timeline-v2__item-cricle">
                                                                                                            <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                        </div>
                                                                                                        <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                            Manufacturing QC Passed
                                                                                                </div>
                                                                                                    </div>
                                                                                                    : ""}

                                                                                                {patientManufactureData.mfgCompleted ?
                                                                                                    <div className="kt-timeline-v2__item">
                                                                                                        <span className="kt-timeline-v2__item-time">
                                                                                                            <Moment format="DD MMM YYYY">
                                                                                                                {patientManufactureData.MfgCompletedDate}
                                                                                                            </Moment>
                                                                                                        </span>
                                                                                                        <div className="kt-timeline-v2__item-cricle">
                                                                                                            <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                        </div>
                                                                                                        <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                            Manufacturing Completed
                                                                                                </div>
                                                                                                    </div>
                                                                                                    : ""}

                                                                                                {patientManufactureData.mfgShipped ?
                                                                                                    <div className="kt-timeline-v2__item">
                                                                                                        <span className="kt-timeline-v2__item-time">
                                                                                                            <Moment format="DD MMM YYYY">
                                                                                                                {patientManufactureData.MfgShippedDate}
                                                                                                            </Moment>
                                                                                                        </span>
                                                                                                        <div className="kt-timeline-v2__item-cricle">
                                                                                                            <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                        </div>
                                                                                                        <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                            Product ready for shipped
                                                                                                </div>
                                                                                                    </div>
                                                                                                    : ""}

                                                                                                {patientManufactureData.mfgDelivered ?
                                                                                                    <div className="kt-timeline-v2__item">
                                                                                                        <span className="kt-timeline-v2__item-time">
                                                                                                            <Moment format="DD MMM YYYY">
                                                                                                                {patientManufactureData.MfgDeliveredDate}
                                                                                                            </Moment>
                                                                                                        </span>
                                                                                                        <div className="kt-timeline-v2__item-cricle">
                                                                                                            <i className="fa fa-genderless kt-font-brand"></i>
                                                                                                        </div>
                                                                                                        <div className="kt-timeline-v2__item-text  kt-padding-top-5">
                                                                                                            Product delivered
                                                                                                </div>
                                                                                                    </div>
                                                                                                    : ""}
                                                                                            </React.Fragment>
                                                                                            : ""}


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="kt-wizard-v1__content" data-ktwizard-type="step-content">
                                                                        <div className="kt-heading kt-heading--md">Shipping</div>
                                                                        <div className="kt-form__section kt-form__section--first">
                                                                            <div className="kt-wizard-v1__form">
                                                                                <div className="kt-timeline-v2">
                                                                                    <div className="kt-timeline-v2__items  kt-padding-top-25 kt-padding-bottom-30">

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/*<div className="kt-form__actions">
                                                            <button className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-prev">
                                                                Previous
                  </button>
                                                            <button className="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-submit">
                                                                Submit
                  </button>
                                                            <button className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                                                                Next Step
                  </button>
                                                        </div>*/}
                                                                </form>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </React.Fragment>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.loading()}
                    {this.renderRedirect()}
                    {preview &&
                        <>
                            <div className='preview-popup'>
                                <div className='preview-popup-modal'>
                                    <div className='preview-popup-header'>
                                        {url.split(".").splice(-1)[0] === "pdf" ? null : 
                                            // <a href={url} download target={`_blank`}>
                                                <img src={downloadIcon} style={{ margin:"0 12px", cursor: "pointer" }} alt='download' onClick={e => this.DownloadFile(e, url)} />
                                            // </a>
                                        }
                                        <img src={closeIcon} style={{ cursor: "pointer" }} alt='close' onClick={e => this.previewToggle(e, "")} />
                                    </div>
                                    <iframe src={url} title="previewFile" width="100%" height="90%" />
                                </div>
                            </div>
                        </>
                    }
                </div>

            </React.Fragment >
        );
    }
}
