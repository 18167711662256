import React, { Component } from 'react';
import {
    Collapse, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Navbar,
    NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input, Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import downloadIcon from '../../../assets/svg/download.svg';
import closeIcon from '../../../assets/svg/x.svg';
//import MyModal from './../../CustomModal/CustomModal';


export class ManufacturerDashboard extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            patients: [],
            //sampletypes: [],
            patientRefNo: "",
            ManufacturerPatientId: "",
            // authError: false,
            error: '',
            errorType: '',
            tablecolumnLength: 8,
            errors: {
                patientRefNo: ''
            },
            show: false,
            showInvoice: false,
            patientId: '',
            patientAccessionId: '',
            patientPaymentId: '',
            invoicefile: '',
            invoiceNo: '',
            showInvoiceErr: '',
            currentPage: 0,
            currentIndex: 0,
            pagesCount: 0,
            pageSize: window.$TotalRecord,
            deleteId: '',
            modal: false,
            preview: false,
            url: "",
            number: "",
        };
        this.state = this.initialState;
        this.toggle = this.toggle.bind(this);

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //}
    handleClose = () => {
        this.setState({
            show: false
        });
    }

    handleShow = (id) => {
        this.setState({
            ManufacturerPatientId: id,
            show: true,
        });
    }

    componentDidMount() {
        this.getListData(0);
    }

    //get detail(for update)
    getListData(pageNo) {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.manufacturerId == null ? 0 : userToken.manufacturerId);
        }
        else {
            return <Redirect to="/" />
        }

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/ManufacturerPatient/getManufacturerPatientsPaging';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            Id: id,
            pageNo: pageNo,
            totalNo: window.$TotalRecord,
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    console.log(result.data.outdata);
                    let rData = result.data.outdata;
                    //let slength = rData.patientSample.length;
                    this.setState({
                        patients: rData,
                        //sampletypes: rData.patientSample,
                        //tablecolumnLength: (8 + parseInt(slength)),
                        pagesCount: Math.ceil(result.data.totalRecord / window.$TotalRecord),
                        loading: false
                    });
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //pagination
    handleClick(e, index, currIndex) {
        e.preventDefault();
        var pgcount = this.state.pagesCount - 1;
        var pgCurr = (index >= pgcount ? pgcount : index);
        this.setState({
            loading: true,
            currentPage: pgCurr,
            currentIndex: currIndex
        }, function () { this.getListData(pgCurr); });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'patientRefNo':
                errors.patientRefNo = (!value) ? "Please enter patient ref number." : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {

        })
    }

    //Add Patient ref no
    AddPatientRefNo() {

        this.setState({
            loading: true
        });
        let errors = this.state.errors;

        if (this.state.patientRefNo != null && this.state.patientRefNo != "") {
            var userToken = JSON.parse(localStorage.getItem('Usertoken'));
            let lid = (userToken.manufacturerId == null ? 0 : userToken.manufacturerId);

            const apiroute = window.$APIPath;
            let url = apiroute + '/api/ManufacturerPatient/AddPatientRefNo';

            let data = JSON.stringify({
                manufacturerId: parseInt(lid),
                patientRefNo: this.state.patientRefNo,
                ManufacturerPatientId: parseInt(this.state.ManufacturerPatientId),
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            show: false
                        }, this.getListData());
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false,
                            show: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false, show: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            errors.patientRefNo = "Please enter patient ref number.";
            this.setState({ loading: false });
        }
    }


    UpdatePaymentReq(e, id) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        const apiroute = window.$APIPath;
        url = apiroute + '/api/ManufacturerPayment/InsertPaymentReq?id=' + id + '';

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData());
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    handleInvoiceClose = () => {
        this.setState({
            showInvoice: false
        });
    }

    handleInvoiceShow = (e, id, aid, pid) => {
        this.setState({
            patientId: id,
            patientAccessionId: aid,
            patientPaymentId: pid,
            showInvoice: true,
        });
    }

    handleInvoiceInputChange(event) {
        this.setState({ showInvoiceErr: '' });

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            showInvoiceErr: (!value) ? "Please enter invoice number." : ''
        });
    }

    handleFileInputChange(event) {
        const target = event.target;
        const value = target.files[0];
        //alert(target.files[0]);
        this.setState({
            invoicefile: value
        });
    }

    UpdateInvoice(e) {
        e.preventDefault();
        this.setState({ loading: true, showInvoiceErr: '' });

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let lid = (userToken.manufacturerId == null ? 0 : userToken.manufacturerId);
        let pid = this.state.patientId;
        let paid = this.state.patientAccessionId;
        let ppid = this.state.patientPaymentId;

        //alert(this.state.analysisdatafile)
        if (this.state.invoicefile != "" && this.state.invoiceNo != "") {

            const apiroute = window.$APIPath;
            //let url = apiroute + '/api/ManufacturerPayment/InsertInvoice?pid=' + pid + '&lid=' + lid + '&ppid=' + ppid + '';
            let url = apiroute + '/api/ManufacturerPayment/InsertInvoiceNew'
            let files = this.state.invoicefile;
            const data = new FormData();
            data.append(`PatientId`, pid);
            data.append(`PatientAccessionId`, paid);
            data.append(`ManufacturerId`, lid);
            data.append(`PatientPaymentId`, ppid);
            data.append(`InvoiceNo`, this.state.invoiceNo);
            data.append(`file`, files);
            //for (let i = 0; i < files.length; i++) {
            //    data.append(`files[${i}]`, files[i])
            //}
            axios.post(url, data, {
                // receive two    parameter endpoint url ,form data
            }).then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false,
                        patientId: "",
                        patientAccessionId: "",
                        patientPaymentId: "",
                        invoicefile: "",
                        showInvoice: false,
                    }, this.getListData());
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        showInvoiceErr: result.data.message,
                        loading: false
                    });
                }
            }).catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', showInvoiceErr: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
        }
        else {
            if (this.state.invoiceNo == "") {
                this.setState({
                    showInvoiceErr: "Please enter invoice number.",
                    loading: false
                });
            } else {
                this.setState({
                    showInvoiceErr: "Please select invoice file to upload.",
                    loading: false
                });
            }
        }
    }

    //delete invoice file
    DeleteInvoice(e, id) {
        e.preventDefault();

        this.setState({ loading: true });

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/ManufacturerPayment/DeleteInvoiceFile?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData());
                    toast.success(result.data.message)
                    this.toggle()
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //download PO/Invoice
    DownloadFile(e, filepath, filename) {
        //alert(filename);
        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        axios({
            url: apiroute + '/api/CognitoUserStore/downloadFile?fileName=' + filepath + '',
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            console.log(response);
            var fname = filepath.substring(filepath.lastIndexOf('/') + 1);
            //alert(fname);
            var fext = fname.substring(fname.lastIndexOf('.'));
            //alert(fext);
            filename = filename + fext;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            //const blob = new Blob([response.data], { type: 'application/pdf' });
            //const url = window.URL.createObjectURL(blob);
            //const link = document.createElement('a');
            //link.href = url;
            //link.setAttribute('download', filename);
            //document.body.appendChild(link);
            //link.click();
            //link.remove();
            //window.URL.revokeObjectURL(url);
            this.setState({ loading: false });
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    //file preview
    previewToggle (e, file, number) {
        this.setState({
        preview: !this.state.preview,
        url: window.$FileUrl + file,
        number: number
        })
    }

    toggle(e, id) {
        this.setState({
          modal: !this.state.modal,
          deleteId: id
        });
      }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, show, patientId, patients, patientRefNo, ManufacturerPatientId, number, url, preview,
            error, errorType, authError, errors, tablecolumnLength, patientPaymentId, showInvoice,
            invoicefile, showInvoiceErr, modal, deleteId } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                            Dashboard
                           </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/manufacturer/dashboard" className="kt-subheader__breadcrumbs-link">
                                                    Dashboard                       </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        {/* {authError ?
                                            <div>
                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                    <div className="alert-text">{error}</div>
                                                    <div className="alert-close">
                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                                        <div className="kt-portlet__body">
                                            <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                <thead>
                                                    <tr>
                                                        <th>Patient Accession Number</th>
                                                        {/*<th>Patient Name</th>
                                                        <th>Diesease</th>
                                                        <th>Practioner Name</th>
                                                        <th>Manufacture Ref No</th>*/}
                                                        {/*<th>Payment</th>*/}
                                                        <th>Quotation</th>
                                                        <th>PO</th>
                                                        {/*<th>Email <br /> Mobile</th>*/}
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    // !loading ? (
                                                        //employees.map(function (data,i) {
                                                        patients.length > 0 ? (
                                                            patients
                                                                .map((data, i) => {
                                                                    return (<tr key={i}>
                                                                        <td>
                                                                            <span style={{ "color": "#0379DB" }}>{data.accessionNo.replace(/-/g, "")}</span>
                                                                            {/*<Link to={'/manufacturer/patient/' + data.patientId}>{data.accessionNo.replace("-", "")}</Link>*/}
                                                                        </td>
                                                                        {/*<td>{data.firstName} {data.middleName != null && data.middleName != "" ? "(" + data.middleName + ")" : ""} {"(" + data.lastName + ")"} </td>*/}
                                                                        {/*<td>{data.diseaseName}<br />{"(" + data.diseaseCode + ")"}</td>*/}
                                                                        {/*<td>{data.practionerName}</td>
                                                                        <td>{data.patientRefNo == "" || data.patientRefNo == null
                                                                            ?
                                                                            <React.Fragment>
                                                                                <Button className="btn btn-bold btn-label-brand btn-sm" onClick={() => this.handleShow(data.manufacturerPatientId)}>
                                                                                    Add
      </Button>
                                                                            </React.Fragment>
                                                                            : data.patientRefNo
                                                                        }</td>*/}
                                                                        {/*<td>{data.email}<br />{data.mobile}</td>
                                                                        <td>
                                                                            {data.patientRefNo != "" && data.patientRefNo != null ?
                                                                                <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>{data.currentStatus == null || data.currentStatus == "" ? "Design not delivered" : data.currentStatus}</span>
                                                                                :
                                                                                <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Not Available</span>
                                                                            }
                                                                        </td>*/}
                                                                        <td>
                                                                            {(data.paymentStatus != "Payment Pending" && !data.invoiceFile ?
                                                                                <a className="btn btn-outline-info btn-pd" onClick={e => { if (window.confirm('Are you sure want to send invoice?')) this.handleInvoiceShow(e, data.patientId, data.patientAccessionId, data.patientPaymentId) }}>Send Invoice</a>
                                                                                :
                                                                                //(data.paymentStatus == "Invoice Send" ?
                                                                                (data.invoiceFile ?
                                                                                    <React.Fragment>
                                                                                        <Link style={{ "cursor": "pointer", "color": "#20A8D8" }} onClick={e => this.previewToggle(e, data.invoiceFile, data.invoiceNo)}>{data.invoiceNo ? "Invoice - " + data.invoiceNo : "Invoice"}</Link>
                                                                                        <br />
                                                                                        <a className="btn btn-outline-info btn-pd" onClick={e => this.toggle(e, data.manufacturerPaymentId)}>Delete Invoice</a>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    null
                                                                                    //(data.paymentStatus == "PO Send" ?
                                                                                    //    <a href={data.invoiceFile} download>{"Invoice - " + data.invoiceNo}</a>
                                                                                    //    :
                                                                                    //    (data.paymentStatus == "Payment Requested" ?
                                                                                    //        <a href={data.invoiceFile} download>{"Invoice - " + data.invoiceNo}</a>
                                                                                    //        :
                                                                                    //        <React.Fragment>
                                                                                    //            <a href={data.invoiceFile} download>{"Invoice - " + data.invoiceNo}</a>
                                                                                    //        </React.Fragment>
                                                                                    //    )
                                                                                    //)
                                                                                )
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {(data.paymentStatus == "Payment Pending" ?
                                                                                <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Patient Payment Pending</span>
                                                                                :
                                                                                (data.paymentStatus == "Patient Paid" ?
                                                                                    <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>PO Pending</span>
                                                                                    :
                                                                                    //(data.paymentStatus == "Invoice Send" ?
                                                                                    //    <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>PO Pending</span>
                                                                                    //    :
                                                                                    (data.paymentStatus == "PO Send" ?
                                                                                        <React.Fragment>
                                                                                            <Link style={{ "cursor": "pointer", "color": "#20A8D8" }} onClick={e => this.previewToggle(e, data.poFile, data.poNo)}>{data.poNo ? "PO - " + data.poNo : "PO"}</Link>
                                                                                            <br />
                                                                                            <a className="btn btn-outline-info btn-pd" onClick={e => { if (window.confirm('Are you sure want to send payment request?')) this.UpdatePaymentReq(e, data.manufacturerPaymentId) }}>Send Payment Req</a>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        (data.paymentStatus == "Payment Requested" ?
                                                                                            <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Payment Requested</span>
                                                                                            :
                                                                                            <React.Fragment>
                                                                                                <Link style={{ "cursor": "pointer", "color": "#20A8D8" }} onClick={e => this.previewToggle(e, data.poFile, data.poNo)}>{data.poNo ? "PO - " + data.poNo : "PO"}</Link>
                                                                                                <br />
                                                                                                <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Payment Done</span>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    )
                                                                                    //)
                                                                                )
                                                                            )}
                                                                        </td>
                                                                        {/*<td>
                                                                            {(data.paymentStatus == "Patient Paid" ?
                                                                                <a className="btn btn-outline-info btn-pd" onClick={e => { if (window.confirm('Are you sure want to send invoice?')) this.handleInvoiceShow(e, data.patientId, data.patientPaymentId) }}>Send Invoice</a>
                                                                                :
                                                                                (data.paymentStatus == "Invoice Send" ?
                                                                                    <React.Fragment>
                                                                                        <a href={data.invoiceFile} download>Invoice File</a>
                                                                                        <a className="btn btn-outline-info btn-pd" onClick={e => { if (window.confirm('Are you sure want to delete invoice?')) this.DeleteInvoice(e, data.manufacturerPaymentId) }}>Delete Invoice</a>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    (data.paymentStatus == "PO Send" ?
                                                                                        <React.Fragment>
                                                                                            <a href={data.poFile} download>PO File</a>
                                                                                            <a className="btn btn-outline-info btn-pd" onClick={e => { if (window.confirm('Are you sure want to send payment request?')) this.UpdatePaymentReq(e, data.manufacturerPaymentId) }}>Send Payment Req</a>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        (data.paymentStatus == "Payment Requested" ?
                                                                                            <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Payment Requested</span>
                                                                                            :
                                                                                            <React.Fragment>
                                                                                                <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Paid</span>
                                                                                                <br />
                                                                                                <a href={data.invoiceFile} download>Invoice File</a>
                                                                                                <br />
                                                                                                <a href={data.poFile} download>PO File</a>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )}
                                                                        </td>*/}
                                                                        <td>
                                                                            {//data.patientRefNo != "" && data.patientRefNo != null ?
                                                                                <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>{data.currentStatus == null || data.currentStatus == "" ? "Design Not Delivered" : data.currentStatus}</span>
                                                                                //:
                                                                                //<span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Not Available</span>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {data.currentStatus != "" && data.currentStatus != null ?
                                                                                <Link to={"/manufacturer/analysis/" + data.patientAccessionId} className="btn btn-outline-info btn-pd">
                                                                                    Edit Status</Link>
                                                                                : null}
                                                                        </td>
                                                                    </tr>);
                                                                })
                                                        ) : (
                                                                <tr>
                                                                    <td colSpan={tablecolumnLength} className="tdCenter">No patients found.</td></tr>
                                                            // )) : (
                                                            // <tr>
                                                            //     <td colSpan={tablecolumnLength} className="tdCenter">Loading...</td></tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {modal && 
                        <div style={{ marginLeft: "36%" }}>    
                            <Modal isOpen={modal} style={{ width: "500px" }} >
                                <ModalHeader>Confirm</ModalHeader>
                                <ModalBody>Are you sure want to delete this Invoice?</ModalBody>
                                <ModalFooter>
                                    <Button outline color="danger" onClick={e => this.DeleteInvoice(e, deleteId)}>Delete</Button>
                                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    }

                    <Modal isOpen={show} className="modal-dialog modal-sm">
                        <ModalHeader>
                            Add Patient Ref number
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Reference No:</label>
                                <input type="text" className="form-control" value={patientRefNo} name="patientRefNo" id="patientRefNo" onChange={this.handleInputChange.bind(this)} />
                                {errors.patientRefNo.length > 0 && <span className='error'>{errors.patientRefNo}</span>}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleClose}>
                                Close
          </Button>
                            <Button color="primary" onClick={this.AddPatientRefNo.bind(this)}>
                                Add
          </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={showInvoice} className="modal-dialog modal-lg">
                        <ModalHeader>
                            Upload Invoice
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Invoice No</label>
                                <Input type="text" name="invoiceNo" id="invoiceNo" className="form-control" tabIndex="1" onChange={this.handleInvoiceInputChange.bind(this)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Invoice File</label>
                                <Input type="file" name="invoicefile" id="File" className="form-control" multiple="multiple" tabIndex="2" onChange={this.handleFileInputChange.bind(this)} accept=".pdf,.doc,.docx" />
                            </div>
                            {showInvoiceErr != "" &&
                                <div>
                                    <span className='error'>{showInvoiceErr}</span>
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleInvoiceClose}>
                                Close
          </Button>
                            <Button color="primary" onClick={this.UpdateInvoice.bind(this)}>
                                Add
          </Button>
                        </ModalFooter>
                    </Modal>

                    {this.loading()}
                    {preview &&
                    <>
                        <div className='preview-popup'>
                            <div className='preview-popup-modal'>
                                <div className='preview-popup-header'>
                                    {url.split(".").splice(-1)[0] === "pdf" ? null : 
                                        // <a href={url} download target={`_blank`}>
                                            <img src={downloadIcon} style={{ margin:"0 12px", cursor: "pointer" }} alt='download' onClick={e => this.DownloadFile(e, url, number)} />
                                        // </a>
                                    }
                                    <img src={closeIcon} style={{ cursor: "pointer" }} alt='close' onClick={e => this.previewToggle(e, "", "")} />
                                </div>
                                <iframe src={url} title="previewFile" width="100%" height="90%" />
                            </div>
                        </div>
                    </>
                }
                </div>


            </React.Fragment >
        );
    }
}
