import React, { Component } from 'react';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, 
    NavItem, FormGroup, Input, Label, Collapse, Container, Navbar, Button, NavbarBrand, NavbarToggler, NavLink } from 'reactstrap';
import Image from '../assets/img/6.jpg';
import { Link, Redirect } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            profile: false,
            changePassword: false,
            logout: false,
        };

        this.handleProfile = this.handleProfile.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleClickLogout = this.handleClickLogout.bind(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleProfile() {
        this.setState({ profile: true });
    }

    renderRedirecttoprofile() {
        if (this.state.profile) {
            this.setState({ profile: false, changePassword: false })
                
                var uType = this.props.userToken.userType;
                
                if (uType == 1) {
                    return <Redirect  to="/patient/profile" />
                }
                else if (uType == 2) {
                    return <Redirect to="/practitioner/profile" />
                }
                else if (uType == 3) {
                    return <Redirect to="/institute/profile" />
                }
                else if (uType == 6) {
                    return <Redirect to="/manufacturer/profile" />
                }
                else if (uType == 4) {
                    return <Redirect to="/laboratory/profile" />
                }
        }
    }
    
    handleChangePassword() {
        this.setState({ changePassword: true });
    }

    renderRedirecttochangepassword() {
        if (this.state.changePassword) {
            this.setState({ changePassword: false, profile: false })
            return <Redirect to="/changepassword" />
        }
    }

    handleClickLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('Usertoken');
        localStorage.setItem('isLoggedIn', false);
        this.setState({ logout: true });
    }

    renderRedirecttohome() {
        if (this.state.logout) {
            return <Redirect from="/" to="/login" />
        }
    }

    render() {

        return (
            <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed left-0">
                <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand" kt-hidden-height="65">
                    <div className="kt-aside__brand-logo">
                        <Link to="/">
                            <img alt="Logo" src="./assets/media/logos/logo-small.png" className="width-40" />
                        </Link>
                    </div>
                </div>
                <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i className="la la-close"></i></button>
                <div className="kt-header-menu-wrapper opacity-1" id="kt_header_menu_wrapper">
                    <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
                    </div>
                </div>
                <div className="kt-header__topbar">
                    <div className="kt-header__topbar-item kt-header__topbar-item--user logo-menu">
                        {/*
                         * <div className="kt-header__topbar-wrapper">
                            <div className="kt-header__topbar-user">
                                <a href="#">Healthcare Professional</a>
                            </div>
                        </div>
                        <div className="kt-header__topbar-wrapper"> 
                            <div className="kt-header__topbar-user">
                                <a href="#">Patient and Caregivers</a>
                            </div>
                    </div>
                    */}

                        {/*<div className="kt-header__topbar-wrapper"> 
                            <div className="kt-header__topbar-user">
                                {(JSON.parse(localStorage.getItem('Usertoken')) != null ?
                                    (JSON.parse(localStorage.getItem('Usertoken')).userType != 1 ?
                                        (JSON.parse(localStorage.getItem('Usertoken')).userType != 2 ?
                                            (JSON.parse(localStorage.getItem('Usertoken')).userType != 3 ?
                                                (JSON.parse(localStorage.getItem('Usertoken')).userType == 4 ?
                                                    <a href="/laboratory/dashboard" className="btn btn-brand btn-pill btn-elevate">Dashboard</a>
                                                    :
                                                    <a href="/manufacturer/dashboard" className="btn btn-brand btn-pill btn-elevate">Dashboard</a>
                                                )
                                                :
                                                <a href="/institute/dashboard" className="btn btn-brand btn-pill btn-elevate">Dashboard</a>
                                            )
                                            :
                                            <a href="/practitioner/dashboard" className="btn btn-brand btn-pill btn-elevate">Dashboard</a>
                                        )
                                        :
                                        <a href="/patient/dashboard" className="btn btn-brand btn-pill btn-elevate">Dashboard</a>
                                    )
                                    : "")
                                }
                            </div>
                        </div>
                        <div className="kt-header__topbar-wrapper"> 
                            <div className="kt-header__topbar-user">
                                <Link to="/changepassword" className="primary-btn">Change Password</Link>
                            </div>
                    </div>*/}
                        <div className="kt-header__topbar-wrapper"> {/*data-toggle="dropdown" data-offset="0px,0px"*/}
                                <div className="kt-header__topbar-user">
                                {/* <Button onClick={this.handleClickLogout} className="btn btn-primary">Logout</Button> */}
                                    <Nav className="ml-auto" navbar>
                                        <UncontrolledDropdown nav direction="down">
                                            <DropdownToggle nav>
                                                <img src={Image} className="img-avatar" alt="admin@bootstrapmaster.com" />
                                                {this.props.userToken?.firstName}{" "}
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#74788D">
                                                    <path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/>
                                                    <path d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z"/>
                                                </svg>

                                            </DropdownToggle>
                                            <DropdownMenu right>
                                            <DropdownItem onClick={this.handleProfile}><i className="fa fa-user"></i> Profile </DropdownItem>
                                            <DropdownItem onClick={this.handleChangePassword}><i className="kt-menu__link-icon flaticon2-lock"></i> Change Password </DropdownItem>
                                            <DropdownItem onClick={this.handleClickLogout}> <i className="fa fa-lock"></i> Logout</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Nav>
                                </div>
                        </div>
                    </div>
                </div>
                {this.renderRedirecttohome()}
                {this.renderRedirecttoprofile()}
                {this.renderRedirecttochangepassword()}
            </div>
        );
    }
}
