import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../CustomModal/CustomModal';

export class ChangePassword extends Component {
    static displayName = ChangePassword.name;

    constructor(props) {
        super(props);

        this.state = {
            confirmpassword: '',
            password: '',
            oldpassword: '',
            loading: false,
            // authError: false,
            error: '',
            errorType: '',
            redirect: false,
            errors: {
                confirmpassword: '',
                password: '',
                oldpassword: ''
            },
            pCurrentFlag: false,
            pCurrentStatus: ""
        };

        this.handleClickLogout = this.handleClickLogout.bind(this);
    }

    handleClickLogout() {
        localStorage.removeItem('token');
        localStorage.removeItem('Usertoken');
        localStorage.setItem('isLoggedIn', false);
        this.setState({ redirect: true });
    }

    renderRedirecttohome() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/login" />
        }
    }
    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/login');
    //    }
    //}

    componentDidMount() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            var uType = userToken.userType;
            var irb = userToken.irbStatus;
            var irbno = userToken.irbNo;
            var firstnm = userToken.firstName;
            var lastnm = userToken.lastName;
            var phnno = userToken.phoneNumber;
            var postalcd = userToken.residentPostalCode;

            if (uType == 2) {
                if ((irbno != null && irbno != "") && (firstnm != null && firstnm != "")
                    && (lastnm != null && lastnm != "") && (phnno != null && phnno != "")
                    && (postalcd != null && postalcd != "")) {
                    if (irb != "P" && irb != "R") {
                        this.setState({ pCurrentFlag: true });
                    } else {
                        if (irb == "P") {
                            this.setState({ loading: false, pCurrentStatus: "Your IRB No is not approved yet!!..", pCurrentFlag: false });
                        }
                        if (irb == "R") {
                            this.setState({ loading: false, pCurrentStatus: "Your IRB No is Rejected!!..", pCurrentFlag: false });
                        }
                    }
                } else {
                    this.setState({ loading: false, pCurrentStatus: "Your profile is not updated.Please provide required details!!..", pCurrentFlag: false });
                }
            }
        }
        else {
            this.setState({ redirect: true });
        }
    }

    //input handle input change and validation
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'confirmpassword':
                errors.confirmpassword = (!value) ? 'Please enter confirm password.' : ''
                break;
            case 'password':
                errors.password = (!value) ? 'Please enter new password.' : ''
                break;
            case 'oldpassword':
                errors.oldpassword = (!value) ? 'Please enter old password.' : ''
                break;
            default:
                //(!value) ? '' :'This field is required.'
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            //console.log(errors)

        })
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.oldpassword == undefined || this.state.oldpassword == '') {
            errors.oldpassword = 'Please enter old password.';
        }
        if (this.state.password == undefined || this.state.password == '') {
            errors.password = 'Please enter new password.';
        }
        if (this.state.confirmpassword == undefined || this.state.confirmpassword == '') {
            errors.confirmpassword = 'Please enter confirm password.';
        }
        else {
            const Password = this.state.password;
            const CPassword = this.state.confirmpassword;

            if (Password != CPassword) {
                errors.confirmpassword = 'Password and confirm password are not same.';
            }
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleSubmit(e) {
        debugger;
        e.preventDefault();

        this.setState({ loading: true });

        if (this.validateForm(this.state.errors)) {
            const apiroute = window.$APIPath;
            const url = apiroute + '/api/CognitoUserStore/ChangePassword';
            const password = this.state.password;
            const oldpass = this.state.oldpassword;
            var userToken = JSON.parse(localStorage.getItem('Usertoken'));
            const userId = userToken.userId;

            let data = JSON.stringify({
                oldPassword: oldpass,
                newPassword: password,
                userId: userId
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                    //'Access-Control-Allow-Origin': '*',
                    //'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                    //'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }
            })
                .then(result => {
                    debugger;
                    console.log(result);
                    if (result.data.flag) {
                        //localStorage.setItem('token', JSON.stringify(result.data.outdata));
                        //this.setState({
                        //    modal: !this.state.modal,
                        //    modalTitle: 'Success',
                        //    modalBody: "Confirmed Successfully.",
                        //    redirect: true,
                        //    loading: false
                        //});
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            password: '',
                            oldpassword: '',
                            loading: false
                        });
                        toast.success(result.data.message)

                    } else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)

                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({ 
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false 
                    });
                    toast.error(error.message)
                });
        }
        else {
            this.setState({ loading: false });
        }
    }


    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { confirmpassword, password, oldpassword, loading, authError, error, errorType, errors, pCurrentFlag, pCurrentStatus } = this.state;
        return (

            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        {/* <div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
                            <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
                                <div className="kt-aside__brand-logo">
                                    <Link to="/" style={{ "textAlign": "center" }}>
                                        <img alt="Logo" src="./assets/media/logos/logo-small.png" />
                                    </Link>
                                </div>
                                <div className="kt-aside__brand-tools">
                                    <button className="kt-aside__brand-aside-toggler" id="kt_aside_toggler"><span></span></button>
                                </div>
                            </div>
                            <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                                <div
                                    id="kt_aside_menu"
                                    className="kt-aside-menu "
                                    data-ktmenu-vertical="1"
                                    data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
                                    {(JSON.parse(localStorage.getItem('Usertoken')) != null ?
                                        (JSON.parse(localStorage.getItem('Usertoken')).userType != 1 ?
                                            (JSON.parse(localStorage.getItem('Usertoken')).userType != 2 ?
                                                (JSON.parse(localStorage.getItem('Usertoken')).userType != 3 ?
                                                    (JSON.parse(localStorage.getItem('Usertoken')).userType == 4 ?
                                                        <ul className="kt-menu__nav">
                                                            <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/laboratory/patientanalysisprogress" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-chart"></i><span className="kt-menu__link-text">Patient Analysis Progress</span></Link>
                                                            </li>
                                                            <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/laboratory/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                                            </li>
                                                            <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/laboratory/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Laboratory Information</span></Link>
                                                            </li>
                                                            <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/laboratory/contact/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-phone"></i><span className="kt-menu__link-text">Contact Persons</span></Link>
                                                            </li>
                                                            <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/laboratory/payment" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></Link>
                                                            </li>
                                                            <li className="kt-menu__item  kt-menu__item--active" aria-haspopup="true"><Link to="/changepassword" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-lock"></i><span className="kt-menu__link-text">Change Password</span></Link>
                                                            </li>
                                                        </ul>

                                                        :
                                                        <ul className="kt-menu__nav">
                                                            <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/manufacturer/patientanalysisprogress" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-chart"></i><span className="kt-menu__link-text">Patient Analysis Progress</span></Link>
                                                            </li>
                                                            <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/manufacturer/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                                            </li>
                                                            <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/manufacturer/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Manufacturer Information</span></Link>
                                                            </li>
                                                            <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/manufacturer/payment" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></Link>
                                                            </li>
                                                            <li className="kt-menu__item  kt-menu__item--active" aria-haspopup="true"><Link to="/changepassword" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-lock"></i><span className="kt-menu__link-text">Change Password</span></Link>
                                                            </li>
                                                        </ul>

                                                    )
                                                    :
                                                    <ul className="kt-menu__nav">
                                                        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/institute/patient/analysisprogress" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-chart"></i><span className="kt-menu__link-text">Patient Analysis Progress</span></Link>
                                                        </li>
                                                        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/institute/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                                        </li>
                                                        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/institute/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Institution Information</span></Link>
                                                        </li>
                                                        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/institute/contact/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-phone"></i><span className="kt-menu__link-text">Contact Persons</span></Link>
                                                        </li>
                                                        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/institute/practitioner/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user"></i><span className="kt-menu__link-text">Practioners</span></Link>
                                                        </li>
                                                        <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/institute/patient/payment" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></Link>
                                                        </li>
                                                        <li className="kt-menu__item  kt-menu__item--active" aria-haspopup="true"><Link to="/changepassword" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-lock"></i><span className="kt-menu__link-text">Change Password</span></Link>
                                                        </li>
                                                    </ul>

                                                )
                                                :
                                                <ul className="kt-menu__nav">
                                                    <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/practitioner/patient/analysisprogress" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-chart"></i><span className="kt-menu__link-text">Patient Analysis Progress</span></Link>
                                                    </li>
                                                    <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link style={pCurrentStatus ? { pointerEvents: "none" } : null} to="/practitioner/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                                    </li>
                                                    <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/practitioner/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Practitioner Information</span></Link>
                                                    </li>
                                                    <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link style={pCurrentStatus ? { pointerEvents: "none" } : null} to="/practitioner/patient/payment" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></Link>
                                                    </li>
                                                    <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link style={pCurrentStatus ? { pointerEvents: "none" } : null} to="/practitioner/patient/samplecollection" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-document"></i><span className="kt-menu__link-text">Sample Collection</span></Link>
                                                    </li>
                                                    <li className="kt-menu__item  kt-menu__item--active" aria-haspopup="true"><Link to="/changepassword" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-lock"></i><span className="kt-menu__link-text">Change Password</span></Link>
                                                    </li>
                                                </ul>

                                            )
                                            :
                                            <ul className="kt-menu__nav">
                                                <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/patient/dashboard" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-dashboard"></i><span className="kt-menu__link-text">Dashboard</span></Link>
                                                </li>
                                                <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/patient/profile" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-user-outline-symbol"></i><span className="kt-menu__link-text">Patient Information</span></Link>
                                                </li>
                                                <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/patient/emergency/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-phone"></i><span className="kt-menu__link-text">Emergency Contact</span></Link>
                                                </li>
                                                <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/patient/insurance/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-document"></i><span className="kt-menu__link-text">Insurance Information</span></Link>
                                                </li>
                                                <li className="kt-menu__item  kt-menu__item" aria-haspopup="true"><Link to="/patient/payment/list" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-information"></i><span className="kt-menu__link-text">Payment Transaction</span></Link>
                                        </li>
                                                <li className="kt-menu__item  kt-menu__item--active" aria-haspopup="true"><Link to="/changepassword" className="kt-menu__link"><i className="kt-menu__link-icon flaticon2-lock"></i><span className="kt-menu__link-text">Change Password</span></Link>
                                                </li>
                                            </ul>

                                        )
                                        : "")
                                    }
                                   
                                </div>
                            </div>
                        </div> */}
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                                {/* {(JSON.parse(localStorage.getItem('Usertoken')) != null ?
                                                    (JSON.parse(localStorage.getItem('Usertoken')).userType != 1 ?
                                                        (JSON.parse(localStorage.getItem('Usertoken')).userType != 2 ?
                                                            (JSON.parse(localStorage.getItem('Usertoken')).userType != 3 ?
                                                                (JSON.parse(localStorage.getItem('Usertoken')).userType == 4 ?
                                                                    "Laboratory"
                                                                    :
                                                                    "Manufacturer"
                                                                )
                                                                :
                                                                "Institution"
                                                            )
                                                            :
                                                            "Practitioner"
                                                        )
                                                        :
                                                       "Patient"
                                                    )
                                                    : "")
                                                } */}
                                                Change Passsword
                           </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <span className="kt-subheader__breadcrumbs-separator">
                                                    Change Passsword                       </span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                        <div className="alert-text">{error}</div>
                                                        <div className="alert-close">
                                                            <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="form-group col-md-6 mb-4">
                                                    <Input type="password" tabIndex="1" placeholder="old password" maxLength="20" name="oldpassword" onChange={this.handleInputChange.bind(this)} />
                                                    {errors.oldpassword.length > 0 && <span className='error'>{errors.oldpassword}</span>}
                                                </div>
                                                <div className="form-group col-md-6 mb-4">
                                                    <Input type="password" tabIndex="2" placeholder="password" maxLength="20" name="password" onChange={this.handleInputChange.bind(this)} />
                                                    {errors.password.length > 0 && <span className='error'>{errors.password}</span>}
                                                </div>
                                                <div className="form-group col-md-6 mb-4">
                                                    <Input tabIndex="3" type="password" maxLength="20" placeholder="confirm password" value={confirmpassword} name="confirmpassword" onChange={this.handleInputChange.bind(this)} />
                                                    {errors.confirmpassword.length > 0 && <span className='error'>{errors.confirmpassword}</span>}
                                                </div>
                                                <div className="kt-login__actions form-group col-md-6 mb-4">
                                                    {loading ?
                                                        <button tabIndex="4" type="submit" className="btn btn-brand btn-elevate kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                        :
                                                        <button tabIndex="4" type="submit" className="btn btn-brand btn-elevate">Submit</button>
                                                    }
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.loading()}
                    {this.renderRedirecttohome()}
                </div>
            </React.Fragment >
        );
    }
}
