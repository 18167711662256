import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../CustomModal/CustomModal';

export class ContactUs extends Component {
    static displayName = ContactUs.name;

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loading: false,
            // authError: false,
            error: '',
            usertype: 0,
            redirect: false,
            errors: {
                username: '',
                password: ''
            },
        };

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //}

    //input handle input change and validation
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'username':
                errors.username = (!value) ? 'Please enter username.' : ''
                break;
            case 'password':
                errors.password = (!value) ? 'Please enter password.' : ''
                break;
            default:
                //(!value) ? '' :'This field is required.'
                break;
        }

        this.setState({ errors, [name]: value }, () => {
            //console.log(errors)

        })
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.username == undefined || this.state.username == '') {
            errors.UserName = 'Please enter username.';
        }
        if (this.state.password == undefined || this.state.password == '') {
            errors.password = 'Please enter password.';
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        if (this.validateForm(this.state.errors)) {

            const apiroute = window.$APIPath;
            const url = apiroute + '/api/CognitoUserStore/signin';
            const username = this.state.username;
            const password = this.state.password;

            let data = JSON.stringify({
                password: password,
                username: username
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                    //'Access-Control-Allow-Origin': '*',
                    //'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                    //'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }
            }).then(result => {
                if (result.data.flag) {
                    localStorage.setItem('token', JSON.stringify(result.data.token));
                    localStorage.setItem('Usertoken', JSON.stringify(result.data.outdata));
                    //console.log(JSON.stringify(result.data.outdata));
                    localStorage.setItem('isLoggedIn', true);
                    this.setState({ redirect: true, loading: false, usertype: result.data.outdata.userType });
                }
                else {
                    //this.setState({
                    //    modal: !this.state.modal,
                    //    modalTitle: 'Error',
                    //    modalBody: result.data.message,
                    //    loading: false
                    //});
                    this.setState({ 
                        // authError: true, error: result.data.message, 
                        loading: false });
                        toast.error(result.data.message)
                }
            }).catch(error => {
                console.log(error);
                this.setState({ 
                    // authError: true, error: error.message, 
                    loading: false });
                    toast.error(error.message)
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            //{ return <Redirect from="/" to="/" /> } 

            if (this.state.usertype == 1) { return <Redirect from="/" to="/patient/dashboard" /> } //Patient
            else if (this.state.usertype == 2) { return <Redirect from="/" to="/practitioner/dashboard" /> }  //Practitioner
            else if (this.state.usertype == 3) { return <Redirect from="/" to="/institute/dashboard" /> } //Institution
            else if (this.state.usertype == 6) { return <Redirect from="/" to="/manufacturer/dashboard" /> } //Manufacturer
            else { return <Redirect from="/" to="/laboratory/dashboard" /> }//NeoLab

        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
       
        return (
            <div className="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--transparent kt-aside--enabled kt-aside--fixed kt-page--loading"style={{ backgroundImage: 'url(./assets/media/bg/bg-3.jpg)' }}>
                <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
                    <div id="kt_header" className="kt-header kt-grid__item  kt-header--fixed left-0">
                        <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand" kt-hidden-height="65">
                            <div className="kt-aside__brand-logo">
                                <Link to="/">
                                    <img alt="Logo" src="./assets/media/logos/logo-small.png" className="width-40" />
                                </Link>
                            </div>
                        </div>
                        <button className="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-header-menu-wrapper opacity-1" id="kt_header_menu_wrapper">
                            <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
                            </div>
                        </div>
                        <div className="kt-header__topbar">
                            <div className="kt-header__topbar-item kt-header__topbar-item--user logo-menu">
                            <div className="kt-header__topbar-wrapper">{/*data-toggle="dropdown" data-offset="0px,0px"*/}
                                    <div className="kt-header__topbar-user">
                                        <Link to="/Services">Services</Link>
                                    </div>
                                </div>
                                <div className="kt-header__topbar-wrapper">{/*data-toggle="dropdown" data-offset="0px,0px"*/}
                                    <div className="kt-header__topbar-user">
                                        <Link to="/HealthcareProfessional">Healthcare Professional</Link>
                                    </div>
                                </div>
                                <div className="kt-header__topbar-wrapper"> {/*data-toggle="dropdown" data-offset="0px,0px"*/}
                                    <div className="kt-header__topbar-user">
                                        <Link to="/Caregivers">Patient and Caregivers</Link>
                                    </div>
                                </div>

                                <div className="kt-header__topbar-wrapper"> {/*data-toggle="dropdown" data-offset="0px,0px"*/}
                                    <div className="kt-header__topbar-user">
                                        <Link to="/login" className="btn btn-brand btn-pill btn-elevate">Login</Link>
                                    </div>
                                </div>
                                <div className="kt-header__topbar-wrapper"> {/*data-toggle="dropdown" data-offset="0px,0px"*/}
                                    <div className="kt-header__topbar-user">
                                        <Link to="/register" className="btn btn-brand btn-pill btn-elevate">Register</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    </div>
                    
                {this.loading()}
               
                <h3 style={{padding: "80px 24px 38px 1000px"}}>Contact Us</h3>
                <div className="span" style={{padding: "34px"}} >
                    <table>
                <tr><p ><span>539 W. Commerce &nbsp;St #2886&nbsp;</span></p></tr>
        <p ><span>Dallas, Texas 75208</span></p>
        <p ><span>Tel: +1-469-619-7252</span></p>
        </table>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                
                <footer className="footer-section section-gap-half">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 footer-left">
                                <Link to="/">
                                    <img src="img/header-logo.jpg" alt="" />
                                </Link>
                                <p><i className="fa fa-map-marker"></i> 539 W. Commerce St #2886 <br /> &nbsp;&nbsp;&nbsp;&nbsp;Dallas, TX 75208 </p>
                                {/* <a href="mailto:office@neo7logix.com">
                                    <p><i className="ti-email"></i> office@neo7logix.com</p>
                                </a>
                                <a href="tel:+12067185467">
                                    <p><i className="ti-mobile"></i>+1-206-718-5467</p>
                                </a> */}
                                <p className="copyright-text"> Copyright © 2020 Neo7Logix LLC- All Rights Reserved.</p>
                            </div>
                            <div className="col-lg-7 footer-right">
                            <div className="footer-title">
                                    <h2>WE'RE HERE TO HELP</h2>
                                    <a href="tel:+(469)6197252">
                                        <p style={{padding: "0px 29px"}}><i className="fa fa-phone"></i> +(469) 619-7252</p>
                                </a>
                                    <a href="mailto:office@neo7logix.com">
                                        <p><i className="fa fa-envelope"></i> office@neo7logix.com</p>
                                </a>
                                </div>
                                <ul className="footer-menu">
                                    <li>
                                        <Link to="/ContactUs">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/Sitemap" >Sitemap</Link>
                                    </li>
                                    <li>
                                        <Link to="/TermsOfUse" >Terms of Use</Link>
                                    </li>
                                    <li>
                                        <Link to="/PrivacyStatements" >Privacy Statement</Link>
                                    </li>
                                    <li>
                                        <Link href="/Achievements">Achievements</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                
                </div>
            
            );
        }
    }