import React, { Component } from 'react';
import {
    Collapse, Container, FormGroup, Badge, Label, Table, Navbar, NavbarBrand, NavbarToggler,
    NavItem, Form, NavLink, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter
    , Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import downloadIcon from '../../../assets/svg/download.svg';
import closeIcon from '../../../assets/svg/x.svg';
//import MyModal from './../../CustomModal/CustomModal';

export class InstitutePayment extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            patientpayments: [],
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',

            preview: false,
            url: "",

            checkbox: [],
            patientId: "",
            patientSampleId: "",
            AllSamples: [],
            showSample: false,
            errors: {
                patientSampleId: '',
            },
            currentPage: 0,
            currentIndex: 0,
            pagesCount: 0,
            pageSize: window.$TotalRecord
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //}

    componentDidMount() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            this.getListData(0);
        }
        else {
            return <Redirect to="/" />
        }
    }

    getListData(pageNo) {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let pid = (userToken.userId == null ? 0 : userToken.userId);
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientPayment/getPatientPaymentByInstituteIdPaging';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            Id: pid,
            pageNo: pageNo,
            totalNo: window.$TotalRecord,
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;

                    this.setState({
                        patientpayments: rData.patientPaymentData,
                        AllSamples: rData.sampleTypeData,
                        pagesCount: Math.ceil(result.data.totalRecord / window.$TotalRecord),
                        loading: false
                    });
                }
                else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //pagination
    handleClick(e, index, currIndex) {
        e.preventDefault();
        var pgcount = this.state.pagesCount - 1;
        var pgCurr = (index >= pgcount ? pgcount : index);
        this.setState({
            loading: true,
            currentPage: pgCurr,
            currentIndex: currIndex
        }, function () { this.getListData(pgCurr); });
    }

    //add Sample
    handleCloseSample = () => {
        this.setState({
            showSample: false
        });
    }

    handleShowSample = (pid, sids) => {
        this.setState({
            patientId: pid,
            showSample: true,
            checkbox: (sids != "" && sids != null ? sids.split(",") : [])
        });
    }

    handleSampleInputChange(event) {
        if (event != null) {
            let nCheckbox = this.state.checkbox.slice(); // create a new copy of state value
            if (this.isValueExist(nCheckbox, event.target.value)) { // check if the same value is preexisted in the array
                const index = nCheckbox.indexOf(event.target.value);
                nCheckbox.splice(index, 1); // removing the preexciting value 
            } else {
                nCheckbox.push(event.target.value); // inserting the value of checkbox in the array
            }
            this.setState({
                checkbox: nCheckbox,
                patientSampleId: nCheckbox.join(',')
            });
        }
    }

    //edit time set checkbox selected
    setCheckbox(id) {
        let nCheckbox = this.state.checkbox.slice(); // create a new copy of state value 
        if (this.isValueExist(nCheckbox, id)) { // check if the same value is preexisted in the array
            return true;
        } else {
            return false; // inserting the value of checkbox in the array
        }
    }

    isValueExist(data, event) {
        if (data.length == 0) {
            return false;
        }
        for (let i = 0; i <= data.length; i++) {
            if (event == data[i]) {
                return true;
            }
        }
        return false;
    }

    AddPatientSample(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let errors = this.state.errors;

        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }
        if (this.state.patientSampleId != null && this.state.patientSampleId != "") {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/PatientSample/Save';

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                SampleTypeIds: this.state.patientSampleId,
                PatientSampleId: 0,
                SampleTypeId: 0,
                createdBy: uid,
                createdByFlag: "I"
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false,
                        showSample: false,
                        patientSampleId: '',
                        patientId: '',
                    }, this.getListData(0));
                    toast.success(result.data.message)
                }
                else {
                    errors.patientSampleId = result.data.message;
                    this.setState({ loading: false });
                }
            })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false, showSample: false
                    });
                    toast.error(error.message)
                });
        }
        else {
            errors.patientSampleId = "Please select Sample.";
            this.setState({ loading: false });
        }
    }
    //end add Sample

    //file preview
    previewToggle (e, file) {
        this.setState({
        preview: !this.state.preview,
        url: file
        })
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, patientpayments, error, errorType, authError, url, preview,
            AllSamples, patientSampleId, patientId, showSample, errors,
            currentPage, currentIndex, pagesCount, pageSize} = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                            Payments
                           </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/institute/patient/payment" className="kt-subheader__breadcrumbs-link">
                                                    Payments                        </Link>
                                            </div> */}
                                        </div>
                                        {/*<div className="kt-subheader__main">*/}
                                        {/*    <Link to="/institute/patient/payment/detail" className="btn btn-primary btnBorder">*/}
                                        {/*        Make Payment*/}
                                        {/*    </Link>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        {/* {authError ?
                                            <div>
                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                    <div className="alert-text">{error}</div>
                                                    <div className="alert-close">
                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                                        <div className="kt-portlet__body">
                                            <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                <thead>
                                                    <tr>
                                                        <th>Patient</th>
                                                        <th>Transaction Id</th>
                                                        <th>Amount</th>
                                                        <th>Remark</th>
                                                        <th>Status</th>
                                                        {/*<th>Sample</th>*/}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    // !loading ?
                                                        (patientpayments.length > 0 ? (
                                                        patientpayments
                                                            .map((data, i) => {
                                                                return (<tr key={i}>
                                                                    <td>{data.patientName}<br />{data.accessionNo.replace(/-/g, "")}</td>
                                                                    <td>{data.transactionId}<br />
                                                                        {data.paymentReceipt != "" && data.paymentReceipt != null ?
                                                                            <a style={{ cursor: "pointer" }} onClick={e => this.previewToggle(e, data.paymentReceipt)}>
                                                                                <i className="flaticon2-download"></i> Receipt
                                                                        </a>
                                                                            : null}
                                                                    </td>
                                                                    <td>${data.transactionAmount}</td>
                                                                    <td>{data.remark}</td>
                                                                    <td><Badge color="success">{data.transactionStatus}</Badge></td>
      {/*                                                              <td>*/}
      {/*                                                                  {data.paymentMilestone == "Analysis" && data.transactionStatus == "Paid" ?*/}
      {/*                                                                      <Button className="btn btn-bold btn-label-brand btn-sm" onClick={() => this.handleShowSample(data.patientId, data.sampleTypeIds)}>*/}
      {/*                                                                          Add Sample*/}
      {/*</Button>*/}
      {/*                                                                      : null}*/}
      {/*                                                              </td>*/}
                                                                </tr>
                                                                )
                                                            })) : (
                                                                <tr>
                                                                    <td colSpan="6" className="tdCenter">No payment transactions found.</td></tr>
                                                            )
                                                            // ) : (
                                                            // <tr>
                                                            //     <td colSpan="6" className="tdCenter">Loading...</td></tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            {pagesCount > 0 ?
                                                <Pagination aria-label="Page navigation example" className="customPagination">
                                                    <PaginationItem disabled={currentIndex - 4 <= 0}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage - 5, currentIndex - 5)} previous href="#">
                                                            Prev
                    </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(pagesCount)].slice(currentIndex, currentIndex + 5).map((page, i) =>
                                                        <PaginationItem active={currentIndex + i === currentPage} key={currentIndex + i}>
                                                            <PaginationLink onClick={e => this.handleClick(e, currentIndex + i, currentIndex)} href="#">
                                                                {currentIndex + i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    )}
                                                    <PaginationItem disabled={currentIndex + 5 >= pagesCount}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage + 5, currentIndex + 5)} next href="#">
                                                            Next
                    </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal isOpen={showSample} className="modal-dialog modal-lg">
                        <ModalHeader>
                            Add Sample
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label"><b>Select Sample</b></label>
                                {AllSamples.map((data, i) => {
                                    return (
                                        <FormGroup check row key={i}>
                                            {this.setCheckbox(data.sampleTypeId) ? (
                                                <Input className="form-check-input" type="checkbox" id={"chk" + data.sampleTypeId} checked name="inline-checkbox1" value={data.sampleTypeId} onChange={this.handleSampleInputChange.bind(this)} />
                                            ) : (
                                                    <Input className="form-check-input" type="checkbox" id={"chk" + data.sampleTypeId} name="inline-checkbox1" value={data.sampleTypeId} onChange={this.handleSampleInputChange.bind(this)} />
                                                )
                                            }
                                            <Label className="form-check-label" check htmlFor={" chk" + data.sampleTypeId}>{data.sampleTypeName}</Label>
                                        </FormGroup>
                                    )
                                })}
                                {errors.patientSampleId.length > 0 && <span className='error'>{errors.patientSampleId}</span>}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleCloseSample}>
                                Close
          </Button>
                            <Button color="primary" onClick={this.AddPatientSample.bind(this)}>
                                Add
          </Button>
                        </ModalFooter>
                    </Modal>

                </div>

                {this.loading()}
                {preview &&
                    <>
                        <div className='preview-popup'>
                            <div className='preview-popup-modal'>
                                <div className='preview-popup-header'>
                                    {url.split(".").splice(-1)[0] === "pdf" ? null : 
                                        <a href={url} download target={`_blank`}>
                                            <img src={downloadIcon} style={{ margin:"0 12px", cursor: "pointer" }} alt='download' />
                                        </a>
                                    }
                                    <img src={closeIcon} style={{ cursor: "pointer" }} alt='close' onClick={e => this.previewToggle(e, "")} />
                                </div>
                                <iframe src={url} title="previewFile" width="100%" height="90%" />
                            </div>
                        </div>
                    </>
                }
            </React.Fragment >
        );
    }
}
