import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Moment from 'moment';
//import MyModal from './../../CustomModal/CustomModal';

export class PratitionerPaymentDetail extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            milestones: [],
            patients: [],
            PatientId: 0, PatientAccessionId: 0,
            PatientPaymentId: 0,
            patientMilestoneId: "",
            TransactionId: "",
            TransactionAmount: "",
            TransactionDate: Moment(new Date())._d,
            TransactionType: "",
            PaymentReceipt: "",
            PaymentReceiptFile: "",
            Remark: "",
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            AccountHolderName: '',
            RoutingNumber: '',
            AccountNumber: '',
            DiseasCategoryId: '',
            DiseaseCategory: [],
            errors: {
                patientMilestoneId: '',
                TransactionId: '',
                TransactionAmount: '',
                Remark: '',
                PatientId: '',
                TransactionDate: '',
                TransactionType: '',
                PaymentReceiptFile: '',
                AccountHolderName: '',
                RoutingNumber: '',
                AccountNumber: '',
                DiseasCategoryId: ''
            },
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/patient/insurance/list');
    //    }
    //}

    componentDidMount() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.practitionerId == null ? 0 : userToken.practitionerId);
        }
        else {
            return <Redirect to="/" />
        }

        const apiroute = window.$APIPath;
        /* const url = apiroute + '/api/PractitionerPatient/GetPatientsByPractitionerId?id=' + id;*/
        const url = apiroute + '/api/PractitionerPatient/getPatientsProfileCompletedByPractitionerId?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        patients: result.data.outdata, loading: false
                    }, this.GetDiseasCategory());//this.getMilestone());
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //Get Disease Category
    //GetDiseaseCategopry
    GetDiseasCategory() {
        // var userToken = JSON.parse(localStorage.getItem('AUserToken'));
        // let userId = (userToken.userId == null ? 0 : userToken.userId);

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/BE_DiseaseCategory/GetAll';
        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            Id: 0
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                var rData = result.data.outdata;

                this.setState({
                    DiseaseCategory: rData
                });
            }
            else {
                this.setState({
                    modal: !this.state.modal, modalTitle: 'Error', modalBody: result.data.message, loading: false
                });
            }
        })
            .catch(modalBody => {
                this.setState({
                    modal: !this.state.modal, modalTitle: 'Error', modalBody: modalBody.message, loading: false
                });
            });
    }

    //getMilestone() {
    //    var userToken = JSON.parse(localStorage.getItem('Usertoken'));
    //    let userId = (userToken.userId == null ? 0 : userToken.userId);

    //    const apiroute = window.$APIPath;
    //    const url = apiroute + '/api/PaymentMilestone/GetAll';

    //    let data = JSON.stringify({
    //        isDeleted: true,
    //        searchString: '',
    //        id: userId
    //    });

    //    axios.post(url, data, {
    //        headers: {
    //            'Content-Type': 'application/json; charset=utf-8'
    //        }
    //    })
    //        .then(result => {
    //            if (result.data.flag) {
    //                var rData = result.data.outdata;

    //                this.setState({
    //                    milestones: rData,
    //                }, () => {
    //                    const param = this.props.match.params;

    //                    if (param.id != undefined) {
    //                        this.getData(param.id);
    //                    }
    //                    else {
    //                        this.setState({ loading: false });
    //                    }
    //                });
    //            }
    //            else {
    //                this.setState({
    //                    authError: true, errorType: 'danger', error: result.data.message, loading: false
    //                });
    //            }
    //        })
    //        .catch(error => {
    //            this.setState({
    //                authError: true, errorType: 'danger', error: error.message, loading: false
    //            });
    //        });
    //}

    //GetMilestone By disease category
    //GetMilestoneByDiseaseCategory
    GetMilestoneByDisease(value) { //event
        debugger;
        //const target = event.target;
        //const value = target.value;
        //const name = target.name;
        //let errors = this.state.errors;
        //errors.DiseasCategoryId = (!value) ? 'Please select disease category.' : '';
        //this.setState({
        //    [name]: value
        //});
        // var userToken = JSON.parse(localStorage.getItem('AUserToken'));
        // let userId = (userToken.userId == null ? 0 : userToken.userId);

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PaymentMilestone/GetMilestoneByDiseseCategory';

        let data = JSON.stringify({

            Id: (value != '') ? parseInt(value) : 0,
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;

                    this.setState({
                        milestones: rData
                    });
                }
                else {
                    this.setState({
                        modal: !this.state.modal, modalTitle: 'Error', modalBody: result.data.message, loading: false
                    });
                }
            })
            .catch(modalBody => {
                this.setState({
                    modal: !this.state.modal, modalTitle: 'Error', modalBody: modalBody.message, loading: false
                });
            });
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientPayment/GetById?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata.insuranceData;
                    var dData = result.data.outdata.docData;
                    this.setState({
                        PatientId: rData.patientId, PatientAccessionId: rData.PatientAccessionId, patientMilestoneId: rData.patientMilestoneId, TransactionId: rData.transactionId,
                        TransactionAmount: rData.transactionAmount, Remark: rData.remark, PatientPaymentId: rData.patientPaymentId,
                        TransactionDate: Moment(rData.transactionDate)._d, TransactionType: rData.transactionType,
                        PaymentReceipt: rData.paymentReceipt,
                        loading: false
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    handleFileInputChange(event) {
        const target = event.target;
        const value = target.files[0];
        const name = target.name;
        //alert(target.files[0]);
        this.setState({
            PaymentReceiptFile: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'PaymentReceiptFile':
                errors.PaymentReceiptFile = this.state.TransactionType == "Cash" ? ((!value) ? 'Please select receipt file to upload.' : '') : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {

        })
    }

    handleTransactionDateChange(date) {
        let errors = this.state.errors;
        errors.TransactionDate = this.state.TransactionType == "Cash" ? ((!date) ? 'Please enter transaction date.' : '') : '';
        this.setState({ TransactionDate: date })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name == "patientMilestoneId") {
            var userToken = JSON.parse(localStorage.getItem('Usertoken'));
            let isDis = false;
            if (userToken != null) {
                isDis = (userToken.isDiscount == null ? false : userToken.isDiscount);
            }
            if (value != "0") {
                let milstoneamount = this.state.milestones.filter(ml => ml.paymentMilestoneId === parseInt(value));
                if (milstoneamount != '') {
                    if (isDis == true) {
                        this.setState({
                            TransactionAmount: milstoneamount[0].discountAmount
                        });
                    } else {
                        this.setState({
                            TransactionAmount: milstoneamount[0].amount
                        });
                    }
                }
                else {
                    this.setState({
                        TransactionAmount: ''
                    });
                }
            }
            else {
                if (isDis == true) {
                    let milstoneamount = this.state.milestones.reduce(function (sum, tax) {
                        return sum + parseFloat(tax.discountAmount);
                    }, 0);
                    this.setState({
                        TransactionAmount: milstoneamount
                    });
                } else {
                    let milstoneamount = this.state.milestones.reduce(function (sum, tax) {
                        return sum + parseFloat(tax.amount);
                    }, 0);
                    this.setState({
                        TransactionAmount: milstoneamount
                    });
                }
            }
        }

        let errors = this.state.errors;

        switch (name) {
            case 'TransactionId':
                errors.TransactionId = this.state.TransactionType == "Cash" ? ((!value) ? 'Please enter transaction id.' : '') : '';
                break;
            case 'patientMilestoneId':
                errors.patientMilestoneId = (!value) ? 'Please select milestone.' : '';
                errors.TransactionAmount = '';

                break;
            case 'TransactionAmount':
                errors.TransactionAmount = (!value) ? 'Please enter transaction amount.' : '';
                break;
            case 'TransactionDate':
                errors.TransactionDate = this.state.TransactionType == "Cash" ? ((!value) ? 'Please enter transaction date.' : '') : '';
                break;
            case 'TransactionType':
                errors.TransactionType = (!value) ? 'Please select transaction type.' : '';
                break;
            //case 'Remark':
            //    errors.Remark = (!value) ? 'Please enter remark.' : '';
            //    break;
            case 'PatientId':
                errors.PatientId = (!value) ? 'Please select patient.' : '';
                break;
            case 'AccountHolderName':
                errors.AccountHolderName = this.state.TransactionType == "Online Payment" ? ((!value) ? 'Please enter account holder name.' : '') : '';
                break;
            case 'AccountNumber':
                errors.AccountNumber = this.state.TransactionType == "Online Payment" ? ((!value) ? 'Please enter account number.' : '') : '';
                break;
            case 'RoutingNumber':
                errors.RoutingNumber = this.state.TransactionType == "Online Payment" ? ((!value) ? 'Please enter routing number.' : '') : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {
            if (name == "PatientId") {
                if (value != "") {
                    let patientFilter = this.state.patients.filter(ml => ml.patientId === parseInt(value));
                    if (patientFilter != '') {
                        let diseaseCat = patientFilter[0].diseaseCategoryId
                        this.setState({
                            DiseasCategoryId: diseaseCat
                        }, () => { this.GetMilestoneByDisease(diseaseCat) });
                    } else {
                        this.setState({
                            DiseasCategoryId: '', milestones: []
                        });
                    }
                } else {
                    this.setState({
                        DiseasCategoryId: '', milestones: []
                    });
                }
            }
        })
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.patientMilestoneId == undefined || this.state.patientMilestoneId == '') {
            errors.patientMilestoneId = 'Please select milestone.';
        }
        if (this.state.TransactionAmount == undefined || this.state.TransactionAmount == '') {
            errors.TransactionAmount = 'Please enter transaction amount.';
        }
        //if (this.state.Remark == undefined || this.state.Remark == '') {
        //    errors.Remark = 'Please enter remark.';
        //}
        if (this.state.PatientId == undefined || this.state.PatientId == '') {
            errors.PatientId = 'Please select patient.';
        }
        if (this.state.TransactionType == undefined || this.state.TransactionType == '') {
            errors.TransactionType = 'Please select transaction type.';
        }
        if (this.state.TransactionType == "Online Payment") {
            if (this.state.AccountHolderName == undefined || this.state.AccountHolderName == '') {
                errors.AccountHolderName = 'Please enter account holder name.';
            }
            if (this.state.AccountNumber == undefined || this.state.AccountNumber == '') {
                errors.AccountNumber = 'Please enter account number.';
            }
            if (this.state.RoutingNumber == undefined || this.state.RoutingNumber == '') {
                errors.RoutingNumber = 'Please enter routing number.';
            }
        }
        else if (this.state.TransactionType == "Cash") {
            if (this.state.TransactionId == undefined || this.state.TransactionId == '') {
                errors.TransactionId = 'Please enter transaction id.';
            }
            if (this.state.TransactionDate == undefined || this.state.TransactionDate == '') {
                errors.TransactionDate = 'Please enter transaction date.';
            }
            //if (this.state.PaymentReceiptFile == undefined || this.state.PaymentReceiptFile == '') {
            //    errors.PaymentReceiptFile = 'Please select receipt file to upload.';
            //}
        }

        if (this.state.DiseasCategoryId == undefined || this.state.DiseasCategoryId == '') {
            errors.DiseasCategoryId = 'Please select disease category.';
        }

        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );

        if (!valid) {
            this.scrollToTop();
        }
        return valid;
    }

    //handleSubmit(e) {
    //    e.preventDefault();
    //    this.setState({ loading: true });
    //    let path = "";
    //    const apiroute = window.$APIPath;
    //    var userToken = JSON.parse(localStorage.getItem('Usertoken'));
    //    let userId = userToken.userId;

    //    if (this.validateForm(this.state.errors)) {

    //        if (this.state.PaymentReceiptFile != "") {
    //            //upload
    //            let rurl = apiroute + '/api/PatientPayment/UploadFile';
    //            let files = this.state.PaymentReceiptFile;
    //            const data = new FormData();
    //            data.append(`file`, files);
    //            axios.post(rurl, data, {
    //                // receive two    parameter endpoint url ,form data
    //            }).then(result => {
    //                console.log(result);
    //                if (result.data.flag) {
    //                    path = result.data.outdata.dbPath;
    //                }
    //                this.AddUpdatePayment(path);
    //            });
    //        } else
    //            this.AddUpdatePayment(path);
    //    } else {
    //        this.setState({ loading: false });
    //    }
    //    //end upload


    //    //this.setState({ loading: false });
    //}
    uploadFile(accessionNo, msg) {
        debugger;

        const apiroute = window.$APIPath;

        if (this.state.PaymentReceiptFile != "") {
            //upload
            let rurl = apiroute + '/api/PatientPayment/UploadFile';
            let files = this.state.PaymentReceiptFile;
            const data = new FormData();
            data.append(`file`, files);
            data.append(`AccessionNo`, accessionNo); //passsing patient ID
            axios.post(rurl, data, {
                // receive two    parameter endpoint url ,form data
            }).then(result => {
                console.log(result);
                this.setState({
                    loading: false
                });
                toast.success(msg);

                setTimeout(() => this.setState({ redirect: true }), 2000);
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            loading: true
        });

        const apiroute = window.$APIPath;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let userId = userToken.userId;
        let url = "";
        let data = [];
        //insert or update
        if (this.state.PatientPaymentId == 0) {
            url = apiroute + '/api/PatientPayment/Save';
        }
        else {
            url = apiroute + '/api/PatientPayment/Update';
        }

        if (this.validateForm(this.state.errors)) {
            if (parseInt(this.state.patientMilestoneId) == 0) {
                let milestonesData = this.state.milestones;
                for (let i = 0; i < milestonesData.length; i++) {
                    let dt = {
                        PatientId: parseInt(this.state.PatientId),
                        PatientAccessionId: null,
                        PaymentMilestoneId: parseInt(milestonesData[i].paymentMilestoneId),
                        TransactionAmount: parseFloat(milestonesData[i].amount),
                        TransactionId: this.state.TransactionId,
                        TransactionDate: (this.state.TransactionDate == '' ? '1999-01-01' : this.state.TransactionDate),
                        TransactionType: this.state.TransactionType,
                        TransactionStatus: "Paid",
                        PaymentReceipt: "",
                        Remark: this.state.Remark,
                        AccountHolderName: this.state.AccountHolderName,
                        AccountNumber: this.state.AccountNumber,
                        RoutingNumber: this.state.RoutingNumber,
                        CreatedBy: parseInt(userId)
                    }
                    data.push(dt);
                }
                console.log(data);
                axios.post(url, data, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                })
                    .then(result => {
                        //tcount = tcount + 1;
                        if (result.data.flag) {
                            //eType = 'success';
                            //msg = result.data.message;
                            if (this.state.PaymentReceiptFile != "") {
                                this.uploadFile(result.data.outdata, result.data.message);
                            } else {
                                this.setState({
                                    loading: false
                                });
                                toast.success(result.data.message);

                                setTimeout(() => this.setState({ redirect: true }), 2000);
                            }
                        }
                        else {
                            //eType = 'danger';
                            //msg = result.data.message;
                            this.setState({
                                // authError: true,
                                // errorType: 'danger',
                                // error: result.data.message,
                                loading: false
                            });
                            toast.error(result.data.message)
                        }
                    })
                    .catch(error => {
                        //eType = 'danger';
                        //msg = error.message;
                        this.setState({
                            // authError: true, errorType: 'danger', error: error.message, 
                            loading: false
                        });
                        toast.error(error.message)
                        // this.setState({ authError: true, error: error });
                    });
            }
            //if (tcount == 4) {
            //    this.setState({
            //        errorType: eType,
            //        error: msg,
            //        authError: true,
            //        loading: false
            //    });
            //    setTimeout(() => this.setState({ redirect: true }), 2000);
            //}

            else {
                let dt = {
                    PatientId: parseInt(this.state.PatientId),
                    PatientAccessionId: null,
                    PaymentMilestoneId: parseInt(this.state.patientMilestoneId),
                    TransactionAmount: parseFloat(this.state.TransactionAmount),
                    TransactionId: this.state.TransactionId,
                    TransactionDate: (this.state.TransactionDate == '' ? '1999-01-01' : this.state.TransactionDate),
                    TransactionType: this.state.TransactionType,
                    TransactionStatus: "Paid",
                    PaymentReceipt: "",
                    Remark: this.state.Remark,
                    AccountHolderName: this.state.AccountHolderName,
                    AccountNumber: this.state.AccountNumber,
                    RoutingNumber: this.state.RoutingNumber,
                    CreatedBy: parseInt(userId)
                };
                data.push(dt);
                console.log(data);
                axios.post(url, data, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                })
                    .then(result => {
                        if (result.data.flag) {
                            //msg = result.data.message;
                            if (this.state.PaymentReceiptFile != "") {
                                this.uploadFile(result.data.outdata, result.data.message);
                            } else {
                                this.setState({
                                    loading: false
                                });
                                toast.success(result.data.message);

                                setTimeout(() => this.setState({ redirect: true }), 2000);
                            }
                        }
                        else {
                            this.setState({
                                // authError: true,
                                // errorType: 'danger',
                                // error: result.data.message,
                                loading: false
                            });
                            toast.error(result.data.message)
                        }
                    })
                    .catch(error => {
                        this.setState({
                            // authError: true, errorType: 'danger', error: error.message, 
                            loading: false
                        });
                        toast.error(error.message)
                        //this.setState({ authError: true, error: error });
                    });
            }
        } else {
            this.setState({ loading: false });
        }
        //end insert or update
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/practitioner/patient/payment" />
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    }

    render() {
        const { loading, patients, PatientId, patientMilestoneId, milestones, TransactionId,
            TransactionAmount, Remark, errors, error, errorType, authError, TransactionDate,
            PaymentReceiptFile, TransactionType, PaymentReceipt, AccountHolderName, DiseaseCategory, DiseasCategoryId,
            RoutingNumber, AccountNumber } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                                <Link to="/practitioner/patient/payment" className="kt-subheader__title">
                                                    Payments
                                                </Link>
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="/practitioner/patient/payment/detail" className="kt-subheader__breadcrumbs-link">
                                                    Payment Detail                       </Link>
                                            </div>
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/practitioner/patient/payment" className="btn btn-primary btnBorder">
                                                Payments
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Patient<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="select" tabIndex="1" name="PatientId" value={PatientId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Patient</option>
                                                                        {patients
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.patientId}>{data.firstName + " " + data.lastName}</option>);
                                                                                { { /*"+  (" + data.accessionNo + ")"*/ } }
                                                                            })}
                                                                    </Input>
                                                                    {errors.PatientId.length > 0 && <span className='error'>{errors.PatientId}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Disease Category<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input disabled type="select" name="DiseasCategoryId" value={DiseasCategoryId}> {/* onChange={this.GetMilestoneByDisease.bind(this)}*/}
                                                                        <option value="">Select Disease Category</option>
                                                                        {DiseaseCategory
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.diseaseCategoryId}>{data.diseaseCategoryName + " (" + data.productName + ")"}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.DiseasCategoryId.length > 0 && <span className='error'>{errors.DiseasCategoryId}</span>}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Purchase Option<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="select" tabIndex="2" name="patientMilestoneId" value={patientMilestoneId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Purchase Option</option>
                                                                        <option value="0">All</option>
                                                                        {milestones
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.paymentMilestoneId}>{data.paymentMilestoneName}</option>); //{data.paymentMilestoneName + " ($ " + data.amount + ")"}
                                                                            })}
                                                                    </Input>
                                                                    {errors.patientMilestoneId.length > 0 && <span className='error'>{errors.patientMilestoneId}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Transaction Type<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="select" tabIndex="3" name="TransactionType" value={TransactionType} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Transaction Type</option>
                                                                        {/*<option value="Online Payment">Online Payment</option>*/}
                                                                        <option value="Cash">A Cheque</option>
                                                                    </Input>
                                                                    {errors.TransactionType.length > 0 && <span className='error'>{errors.TransactionType}</span>}
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="form-group row my-4">

                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Transaction Amount<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input type="number" className="form-control here" maxLength="50" tabIndex="4" name="TransactionAmount" placeholder="Enter a transaction amount" value={TransactionAmount} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.TransactionAmount.length > 0 && <span className='error'>{errors.TransactionAmount}</span>}
                                                                </div>
                                                            </div>
                                                            {TransactionType == "Cash" ? (
                                                                <div className="col-md-6">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label className="col-12 col-form-label">Transaction Id<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                            <div className="col-12">
                                                                                <Input type="text" name="TransactionId" tabIndex="5" maxLength="50" className="form-control" value={TransactionId} onChange={this.handleInputChange.bind(this)} placeholder="Enter a transaction id" />
                                                                                {errors.TransactionId.length > 0 && <span className='error'>{errors.TransactionId}</span>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="col-12 col-form-label">Transaction Date<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                            <div className="col-12">
                                                                                {/* <Input type="date" className="form-control here" max="9999-12-31" maxLength="50" tabIndex="6" name="TransactionDate" placeholder="Enter a transaction date" value={TransactionDate} onChange={this.handleInputChange.bind(this)} /> */}
                                                                                <DatePicker
                                                                                    selected={TransactionDate}
                                                                                    onChange={this.handleTransactionDateChange.bind(this)}
                                                                                    dateFormat="MM/dd/yyyy"
                                                                                    placeholderText="mm/dd/yyyy"
                                                                                    className="form-control here"
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                />
                                                                                {errors.TransactionDate.length > 0 && <span className='error'>{errors.TransactionDate}</span>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : TransactionType == "Online Payment" ? (
                                                                <div className="form-group row my-4">
                                                                    <div className="col-md-4">
                                                                        <label className="col-12 col-form-label">Account Holder Name<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                        <div className="col-12">
                                                                            <Input type="text" name="AccountHolderName" tabIndex="5" maxLength="150" className="form-control" value={AccountHolderName} onChange={this.handleInputChange.bind(this)} placeholder="Enter a account holder name" />
                                                                            {errors.AccountHolderName.length > 0 && <span className='error'>{errors.AccountHolderName}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label className="col-12 col-form-label">Routing Number<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                        <div className="col-12">
                                                                            <Input type="number" className="form-control here" maxLength="20" tabIndex="6" name="RoutingNumber" placeholder="Enter a routing number" value={RoutingNumber} onChange={this.handleInputChange.bind(this)} />
                                                                            {errors.RoutingNumber.length > 0 && <span className='error'>{errors.RoutingNumber}</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label className="col-12 col-form-label">Account Number<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                        <div className="col-12">
                                                                            <Input type="number" className="form-control here" maxLength="20" tabIndex="7" name="AccountNumber" placeholder="Enter a account number" value={AccountNumber} onChange={this.handleInputChange.bind(this)} />
                                                                            {errors.AccountNumber.length > 0 && <span className='error'>{errors.AccountNumber}</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Remark</label>{/*<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span>*/}
                                                                <div className="col-12">
                                                                    <Input type="textarea" className="form-control here" maxLength="250" tabIndex="8" name="Remark" placeholder="Enter a remark" value={Remark} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.Remark.length > 0 && <span className='error'>{errors.Remark}</span>}
                                                                </div>
                                                            </div>
                                                            {TransactionType == "Cash" ? (
                                                                <>
                                                                    <div className="col-md-6">
                                                                        <label className="col-12 col-form-label">Receipt</label>el>{/*<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span>*/}
                                                                        <div className="col-12">
                                                                            <Input type="file" name="PaymentReceiptFile" tabIndex="9" id="File" className="form-control" onChange={this.handleFileInputChange.bind(this)} />
                                                                            {errors.PaymentReceiptFile.length > 0 && <span className='error'>{errors.PaymentReceiptFile}</span>}
                                                                        </div>
                                                                    </div>
                                                                    {PaymentReceipt != null && PaymentReceipt != "" ?
                                                                        <div className="col-md-6">
                                                                            <a href={PaymentReceipt} download>
                                                                                <i className="flaticon2-download"></i>
                                                                            </a>
                                                                        </div>
                                                                        : null}
                                                                </>) : null}
                                                        </div>

                                                        {loading ?
                                                            <button tabIndex="10" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Proceed</button>
                                                            :
                                                            <button tabIndex="10" type="submit" className="btn btn-primary">Proceed</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
                    {this.loading()}
                </div>
            </React.Fragment >
        );
    }
}
