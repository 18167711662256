import React, { Component } from 'react';
import {
    Collapse, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Navbar,
    NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input, Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import downloadIcon from '../../../assets/svg/download.svg';
import closeIcon from '../../../assets/svg/x.svg';
//import MyModal from './../../CustomModal/CustomModal';


export class LaboratoryDashboard extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            patients: [],
            sampletypes: [],
            patientRefNo: "",
            NGSLaboratoryPatientId: "",
            // authError: false,
            error: '',
            errorType: '',
            tablecolumnLength: 7,
            errors: {
                patientRefNo: ''
            },
            show: false,
            showInvoice: false,
            patientId: '',
            patientAccessionId: '',
            invoiceNo: '',
            patientPaymentId: '',
            invoicefile: '',
            showInvoiceErr: '',

            preview: false,
            url: "",
            number: "",

            showSample: false,
            SampleTypeId: '',
            SampleRefNo: '',
            showSampleErr: '',
            currentPage: 0,
            currentIndex: 0,
            pagesCount: 0,
            pageSize: window.$TotalRecord,

            deleteId: '',
            modal: false
        };
        this.state = this.initialState;
        this.toggle = this.toggle.bind(this);

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //}
    handleClose = () => {
        this.setState({
            show: false
        });
    }

    handleShow = (id) => {
        this.setState({
            NGSLaboratoryPatientId: id,
            show: true,
        });
    }

    componentDidMount() {
        this.getListData(0);
    }

    //get detail(for update)
    getListData(pageNo) {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);
        }
        else {
            return <Redirect to="/" />
        }

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/NGSLaboratoryPatient/getNGSLaboratoryPatientsPaging';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            Id: parseInt(id),
            pageNo: pageNo,
            totalNo: window.$TotalRecord,
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    console.log(result.data.outdata);
                    let rData = result.data.outdata;
                    let slength = rData.sampleTypeData.length;
                    this.setState({
                        patients: rData.ngsPatientData,
                        sampletypes: rData.sampleTypeData,
                        tablecolumnLength: (7 + parseInt(slength)),
                        pagesCount: Math.ceil(result.data.totalRecord / window.$TotalRecord),
                        loading: false
                    });
                } else {
                    console.log(result.data);
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //pagination
    handleClick(e, index, currIndex) {
        e.preventDefault();
        var pgcount = this.state.pagesCount - 1;
        var pgCurr = (index >= pgcount ? pgcount : index);
        this.setState({
            loading: true,
            currentPage: pgCurr,
            currentIndex: currIndex
        }, function () { this.getListData(pgCurr); });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'patientRefNo':
                errors.patientRefNo = (!value) ? "Please enter patient ref number." : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {

        })
    }

    //Add Patient ref no
    AddPatientRefNo() {

        this.setState({
            loading: true
        });
        let errors = this.state.errors;

        if (this.state.patientRefNo != null && this.state.patientRefNo != "") {
            var userToken = JSON.parse(localStorage.getItem('Usertoken'));
            let lid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);

            const apiroute = window.$APIPath;
            let url = apiroute + '/api/NGSLaboratoryPatient/AddPatientRefNo';

            let data = JSON.stringify({
                ngsLaboratoryId: parseInt(lid),
                patientRefNo: this.state.patientRefNo,
                NGSLaboratoryPatientId: parseInt(this.state.NGSLaboratoryPatientId),
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            show: false
                        }, this.getListData());
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false,
                            show: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false, show: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        }
        else {
            errors.patientRefNo = "Please enter patient ref number.";
            this.setState({ loading: false });
        }
    }

    UpdatePaymentReq(e, id) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        const apiroute = window.$APIPath;
        url = apiroute + '/api/NGSLabPayment/InsertPaymentReq?id=' + id + '';

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData());
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
                //this.setState({ authError: true, error: error });
            });
    }

    handleInvoiceClose = () => {
        this.setState({
            showInvoice: false
        });
    }

    handleInvoiceShow = (e, id, aid, pid) => {
        this.setState({
            patientId: id,
            patientAccessionId: aid,
            patientPaymentId: pid,
            showInvoice: true,
        });
    }

    handleFileInputChange(event) {
        const target = event.target;
        const value = target.files[0];
        //alert(target.files[0]);
        this.setState({
            invoicefile: value
        });
    }

    handleInvoiceInputChange(event) {
        this.setState({ showInvoiceErr: '' });

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            showInvoiceErr: (!value) ? "Please enter invoice number." : ''
        });
    }

    UpdateInvoice(e) {
        e.preventDefault();
        this.setState({ loading: true, showInvoiceErr: '' });

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let lid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);
        let pid = this.state.patientId;
        let paid = this.state.patientAccessionId;
        let ppid = this.state.patientPaymentId;

        //alert(this.state.analysisdatafile)
        if (this.state.invoicefile != "" && this.state.invoiceNo != "") {

            const apiroute = window.$APIPath;
            //let url = apiroute + '/api/NGSLabPayment/InsertInvoice?pid=' + pid + '&lid=' + lid + '&ppid=' + ppid + '';
            let url = apiroute + '/api/NGSLabPayment/InsertInvoiceNew';
            let files = this.state.invoicefile;

            const data = new FormData();
            data.append(`PatientId`, pid);
            data.append(`PatientAccessionId`, paid);
            data.append(`NGSLaboratoryId`, lid);
            data.append(`PatientPaymentId`, ppid);
            data.append(`InvoiceNo`, this.state.invoiceNo);
            data.append(`file`, files);
            //for (let i = 0; i < files.length; i++) {
            //    data.append(`files[${i}]`, files[i])
            //}
            axios.post(url, data, {
                // receive two    parameter endpoint url ,form data
            }).then(result => {
                console.log(result);
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false,
                        patientId: "",
                        patientPaymentId: "",
                        patientAccessionId: "",
                        invoicefile: "",
                        showInvoice: false,
                    }, this.getListData());
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        showInvoiceErr: result.data.message,
                        loading: false
                    });
                }
            }).catch(error => {
                this.setState({
                    // authError: true, errorType: 'danger', showInvoiceErr: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
        }
        else {
            if (this.state.invoiceNo == "") {
                this.setState({
                    showInvoiceErr: "Please enter invoice number.",
                    loading: false
                });
            } else {
                this.setState({
                    showInvoiceErr: "Please select invoice file to upload.",
                    loading: false
                });
            }

        }
    }

    //delete invoice file
    DeleteInvoice(e, id) {
        e.preventDefault();

        this.setState({ loading: true });

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/NGSLabPayment/DeleteInvoiceFile?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false
                    }, this.getListData());
                    toast.success(result.data.message)
                    this.toggle()
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    handleSampleClose = () => {
        this.setState({
            showSample: false
        });
    }

    handleSampleShow = (e, id, pid) => {
        this.setState({
            patientId: id,
            SampleTypeId: pid,
            showSample: true,
        });
    }

    handleSampleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'SampleRefNo':
                errors.showSampleErr = (!value) ? "Please enter sample ref number." : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {

        })
    }

    UpdateSample(e) {
        e.preventDefault();
        this.setState({ loading: true, showSampleErr: '' });

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let lid = (userToken.ngsLaboratoryId == null ? 0 : userToken.ngsLaboratoryId);
        let pid = this.state.patientId;
        let nsid = this.state.SampleTypeId;

        if (this.state.SampleRefNo != "" && this.state.SampleRefNo != null) {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/BE_NGSLaboratorySample/Update';

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                SampleTypeId: parseInt(nsid),
                NGSLaboratorySampleId: 0
            })

            console.log(data);
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false,
                        showSample: false,
                        patientId: '',
                        SampleRefNo: '',
                        SampleTypeId: ''
                    }, this.getListData());
                    toast.success(result.data.message)
                }
                else {
                    this.setState({ loading: false, showSampleErr: result.data.message });
                }
            })
                .catch(error => {
                    this.setState({ loading: false, showSampleErr: error.message });
                });
        }
        else {
            this.setState({ loading: false, showSampleErr: "Please enter sample ref no." });
        }
    }

    //download PO/Invoice
    DownloadFile(e, filepath, filename) {
        //alert(filename);
        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        axios({
            url: apiroute + '/api/CognitoUserStore/downloadFile?fileName=' + filepath + '',
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            console.log(response);
            var fname = filepath.substring(filepath.lastIndexOf('/') + 1);
            //alert(fname);
            var fext = fname.substring(fname.lastIndexOf('.'));
            //alert(fext);
            filename = filename + fext;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            //const blob = new Blob([response.data], { type: 'application/pdf' });
            //const url = window.URL.createObjectURL(blob);
            //const link = document.createElement('a');
            //link.href = url;
            //link.setAttribute('download', filename);
            //document.body.appendChild(link);
            //link.click();
            //link.remove();
            //window.URL.revokeObjectURL(url);
            this.setState({ loading: false });
        }).catch(error => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    //file preview
    previewToggle(e, file, number) {
        this.setState({
            preview: !this.state.preview,
            url: window.$FileUrl + file,
            number: number
        })
    }

    toggle(e, id) {
        this.setState({
            modal: !this.state.modal,
            deleteId: id
        });
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, show, patientId, patients, patientRefNo, NGSLaboratoryPatientId,
            error, errorType, authError, errors, tablecolumnLength, patientPaymentId, showInvoice,
            invoicefile, showInvoiceErr, sampletypes, showSample, SampleTypeId, number, url, preview,
            SampleRefNo, showSampleErr, currentPage, currentIndex, pagesCount, pageSize, modal, deleteId } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                                Dashboard
                                            </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/laboratory/dashboard" className="kt-subheader__breadcrumbs-link">
                                                    Dashboard                       </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        {/* {authError ?
                                            <div>
                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                    <div className="alert-text">{error}</div>
                                                    <div className="alert-close">
                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                                        <div className="kt-portlet__body">
                                            <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">
                                                <thead>
                                                    <tr>
                                                        <th>Patient Accession Number</th>
                                                        {/*<th>Patient Name</th>
                                                        <th>Diesease</th>
                                                        <th>Practioner Name</th>
                                                        <th>Lab Ref No</th>*/}
                                                        <th>Quotation</th>
                                                        <th>PO</th>
                                                        {/*<th>Payment</th>*/}
                                                        {/*<th>Email <br /> Mobile</th>*/}
                                                        {sampletypes.length > 0 ? (
                                                            sampletypes
                                                                .map((data, i) => {
                                                                    return (
                                                                        <th key={i}>{data.sampleTypeName}</th>
                                                                    )
                                                                }
                                                                )) : (null)}
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        // !loading ? (
                                                        //employees.map(function (data,i) {
                                                        patients.length > 0 ? (
                                                            patients
                                                                .map((data, i) => {
                                                                    return (<tr key={i}>
                                                                        <td>
                                                                            <span style={{ "color": "#0379DB" }}><b>{data.accessionNo.replace(/-/g, "")}</b></span>
                                                                            {/*<Link to={'/laboratory/patient/' + data.patientId}>{data.accessionNo}</Link>*/}
                                                                        </td>
                                                                        {/*<td>{data.firstName} {data.middleName != null && data.middleName != "" ? "(" + data.middleName + ")" : ""} {"(" + data.lastName + ")"} </td>*/}
                                                                        {/*<td>{data.diseaseName}<br />{"(" + data.diseaseCode + ")"}</td>*/}
                                                                        {/*<td>{data.practionerName}</td>
                                                                        <td>{data.patientRefNo == "" || data.patientRefNo == null
                                                                            ?
                                                                            <React.Fragment>
                                                                                <Button className="btn btn-bold btn-label-brand btn-sm" onClick={() => this.handleShow(data.ngsLaboratoryPatientId)}>
                                                                                    Add
      </Button>
                                                                            </React.Fragment>
                                                                            : data.patientRefNo
                                                                        }</td>*/}
                                                                        <td>
                                                                            {(data.paymentStatus != "Payment Pending" && !data.invoiceFile ?
                                                                                <a className="btn btn-outline-info btn-pd" onClick={e => { if (window.confirm('Are you sure want to send invoice?')) this.handleInvoiceShow(e, data.patientId, data.patientAccessionId, data.patientPaymentId) }}>Send Invoice</a>
                                                                                :
                                                                                //(data.paymentStatus == "Invoice Send" ?
                                                                                (data.invoiceFile ?
                                                                                    <React.Fragment>
                                                                                        <Link style={{ "cursor": "pointer", "color": "#20A8D8" }} onClick={e => this.previewToggle(e, data.invoiceFile, data.invoiceNo)}>{data.invoiceNo ? "Invoice - " + data.invoiceNo : "Invoice"}</Link>
                                                                                        <br />
                                                                                        <a className="btn btn-outline-info btn-pd" onClick={e => this.toggle(e, data.ngsLabPaymentId)}>Delete Invoice</a>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    null
                                                                                    //(data.paymentStatus == "PO Send" ?
                                                                                    //    <a href={data.invoiceFile} download>{"Invoice - " + data.invoiceNo}</a>
                                                                                    //    :
                                                                                    //    (data.paymentStatus == "Payment Requested" ?
                                                                                    //        <a href={data.invoiceFile} download>{"Invoice - " + data.invoiceNo}</a>
                                                                                    //        :
                                                                                    //        <React.Fragment>
                                                                                    //            <a href={data.invoiceFile} download>{"Invoice - " + data.invoiceNo}</a>
                                                                                    //        </React.Fragment>
                                                                                    //    )
                                                                                    //)
                                                                                )
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {(data.paymentStatus == "Payment Pending" ?
                                                                                <span className="kt-shape-bg-color-4" style={{ "padding": "3px 4px", "color": "#fff" }}>Patient Payment Pending</span>
                                                                                :
                                                                                (data.paymentStatus == "Patient Paid" ?
                                                                                    <span className="kt-shape-bg-color-4" style={{ "padding": "3px 4px", "color": "#fff" }}>PO Pending</span>
                                                                                    :
                                                                                    //(data.paymentStatus == "Invoice Send" ?
                                                                                    //    <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>PO Pending</span>
                                                                                    //    :
                                                                                    (data.paymentStatus == "PO Send" ?
                                                                                        <React.Fragment>
                                                                                            <Link style={{ "cursor": "pointer", "color": "#20A8D8" }} onClick={e => this.previewToggle(e, data.poFile, data.poNo)} data-toggle="tooltip" title={data.poNo ? "PO - " + data.poNo : "PO"}>
                                                                                                {/* {data.poNo ? "PO - " + data.poNo : "PO"} */}
                                                                                                <svg id="color" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.456-.477-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .998.791.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z" fill="#00bcd4" />
                                                                                                    <path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z" fill="#607d8b" />
                                                                                                    <path d="m12 2h-1.25c-.689 0-1.25.561-1.25 1.25v5.75h-2.75c-.659 0-.998.791-.542 1.268l5.25 5.5c.141.148.337.232.542.232z" fill="#00a4b9" />
                                                                                                    <path d="m12 18h-10.25c-.965 0-1.75.785-1.75 1.75v.5c0 .965.785 1.75 1.75 1.75h10.25z" fill="#546d79" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                            <br />  	{/*&nbsp;*/}
                                                                                            <a className="btn btn-outline-info btn-pd" onClick={e => { if (window.confirm('Are you sure want to send payment request?')) this.UpdatePaymentReq(e, data.ngsLabPaymentId) }}>Send Payment Req</a>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        (data.paymentStatus == "Payment Requested" ?
                                                                                            <span className="kt-shape-bg-color-4" style={{ "padding": "3px 4px", "color": "#fff" }}>Payment Requested</span>
                                                                                            :
                                                                                            <React.Fragment>
                                                                                                <Link style={{ "cursor": "pointer", "color": "#20A8D8" }} onClick={e => this.previewToggle(e, data.poFile, data.poNo)} data-toggle="tooltip" title={data.poNo ? "PO - " + data.poNo : "PO"}>
                                                                                                    {/* {data.poNo ? "PO - " + data.poNo : "PO"} */}
                                                                                                    <svg id="color" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.456-.477-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .998.791.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z" fill="#00bcd4" />
                                                                                                        <path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z" fill="#607d8b" />
                                                                                                        <path d="m12 2h-1.25c-.689 0-1.25.561-1.25 1.25v5.75h-2.75c-.659 0-.998.791-.542 1.268l5.25 5.5c.141.148.337.232.542.232z" fill="#00a4b9" />
                                                                                                        <path d="m12 18h-10.25c-.965 0-1.75.785-1.75 1.75v.5c0 .965.785 1.75 1.75 1.75h10.25z" fill="#546d79" />
                                                                                                    </svg>
                                                                                                </Link>
                                                                                                <br />  {/*	&nbsp;*/}
                                                                                                <span className="kt-shape-bg-color-4" style={{ "padding": "3px 4px", "color": "#fff" }}>Payment Done</span>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    )
                                                                                    //)
                                                                                )
                                                                            )}
                                                                        </td>

                                                                        {(data.ngsLaboratoryPatientActivity.length > 0 && sampletypes.length > 0 ? (
                                                                            sampletypes
                                                                                .map((sdata, j) =>
                                                                                (data.ngsLaboratoryPatientActivity.filter(f => f.sampleTypeId == sdata.sampleTypeId).length > 0 ?
                                                                                    data.ngsLaboratoryPatientActivity.filter(f => f.sampleTypeId == sdata.sampleTypeId).map((ngsdata, n) => {
                                                                                        return (
                                                                                            ngsdata.currentStatus == "Data File Ready" || ngsdata.currentStatus == "Analysis Data And File Transferred" ?
                                                                                                <td key={i}>
                                                                                                    {data.dataFileStatus ?
                                                                                                        <Link to={"/laboratory/datafile/" + data.patientAccessionId} style={{ "color": "rgb(96, 125, 139)" }} data-toggle="tooltip" title="Upload Datafile">
                                                                                                                <i class="fa fa-upload fa-lg" aria-hidden="true"></i>
                                                                                                            </Link>
                                                                                                        : null}
                                                                                                    <br />
                                                                                                    {ngsdata.sampleRefNo != null && ngsdata.sampleRefNo != "" ?
                                                                                                        <React.Fragment>
                                                                                                            <span>Ref: <b>{ngsdata.sampleRefNo}</b></span>
                                                                                                            <br />
                                                                                                        </React.Fragment>
                                                                                                        : null}
                                                                                                    {ngsdata.currentStatus == "Data File Ready" ?
                                                                                                        <span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor": "#4DBD74" }}>{ngsdata.currentStatus}</span>
                                                                                                        :
                                                                                                        <span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor": "#529546", "textAlign":"center" }}>{ngsdata.currentStatus}</span>
                                                                                                        }

                                                                                                </td>
                                                                                                :
                                                                                                <td key={i}>
                                                                                                    {ngsdata.sampleRefNo != null && ngsdata.sampleRefNo != "" ?
                                                                                                        <React.Fragment>
                                                                                                            <span>Ref: <b>{ngsdata.sampleRefNo}</b></span>
                                                                                                            <br />
                                                                                                        </React.Fragment>
                                                                                                        : null}

                                                                                                    {ngsdata.currentStatus == "Not Available" ?
                                                                                                        <span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor": "#F86C6B" }}>{ngsdata.currentStatus}</span>
                                                                                                        :
                                                                                                        <span className="kt-shape-bg-color-3" style={{ "padding": "3px 4px", "color": "#fff" }}>{ngsdata.currentStatus}</span>
                                                                                                    }
                                                                                                </td>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    <td key={j}><span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor":"#F86C6B" }}>Not Assigned</span></td>
                                                                                )
                                                                                )) :
                                                                            (data.ngsLaboratoryPatientActivity.length <= 0 && sampletypes.length > 0 ?
                                                                                sampletypes
                                                                                    .map((sdata, j) => {
                                                                                        return (
                                                                                            <td key={j}><span style={{ "padding": "3px 4px", "color": "#fff", "backgroundColor": "#F86C6B" }}>Not Available</span></td>
                                                                                        )
                                                                                    })
                                                                                : (null)))
                                                                            //:(null))
                                                                        }


                                                                        <td>{
                                                                            (data.ngsLaboratoryPatientActivity.length > 0 ?
                                                                                /*data.patientRefNo != "" && data.patientRefNo != null &&
                                                                                 * data.dataFileStatus ?
                                                                                    <a href={"/laboratory/datafile/" + data.patientId} className="btn btn-outline-info btn-pd">
                                                                                        Upload Datafile</a>
                                                                                    :
                                                                                    */
                                                                                <Link to={"/laboratory/analysis/" + data.patientAccessionId} className="btn btn-outline-info btn-pd">
                                                                                    Edit Status</Link>
                                                                                : null)
                                                                        }

                                                                        </td>

                                                                        {/*old payment code <td>
                                                                            {(data.paymentStatus == "Patient Paid" ?
                                                                                <a className="btn btn-outline-info btn-pd" onClick={e => { if (window.confirm('Are you sure want to send invoice?')) this.handleInvoiceShow(e, data.patientId, data.patientPaymentId) }}>Send Invoice</a>
                                                                                :
                                                                                (data.paymentStatus == "Invoice Send" ?
                                                                                    <React.Fragment>
                                                                                        <a href={data.invoiceFile} download>Invoice File</a>
                                                                                        <a className="btn btn-outline-info btn-pd" onClick={e => { if (window.confirm('Are you sure want to delete invoice?')) this.DeleteInvoice(e, data.ngsLabPaymentId) }}>Delete Invoice</a>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    (data.paymentStatus == "PO Send" ?
                                                                                        <React.Fragment>
                                                                                            <a href={data.poFile} download>PO File</a>
                                                                                            <a className="btn btn-outline-info btn-pd" onClick={e => { if (window.confirm('Are you sure want to send payment request?')) this.UpdatePaymentReq(e, data.ngsLabPaymentId) }}>Send Payment Req</a>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        (data.paymentStatus == "Payment Requested" ?
                                                                                            <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Payment Requested</span>
                                                                                            :
                                                                                            <React.Fragment>
                                                                                                <span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>Paid</span>
                                                                                                <br />
                                                                                                <a href={data.invoiceFile} download>Invoice File</a>
                                                                                                <br />
                                                                                                <a href={data.poFile} download>PO File</a>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )}
                                                                        </td>*/}
                                                                        {/*<td>{data.email}<br />{data.mobile}</td>*/}
                                                                        {/*old status code 2{*/}
                                                                        {/*    //(data.patientRefNo != "" && data.patientRefNo != null ?*/}
                                                                        {/*    (data.ngsLaboratoryPatientActivity.length > 0 && sampletypes.length > 0 ? (*/}
                                                                        {/*        sampletypes*/}
                                                                        {/*            .map((sdata, j) =>*/}
                                                                        {/*                data.ngsLaboratoryPatientActivity*/}
                                                                        {/*                    .map((ngsdata, n) => {*/}
                                                                        {/*                        return (*/}
                                                                        {/*                            (sdata.sampleTypeId == ngsdata.sampleTypeId ?*/}
                                                                        {/*                                (ngsdata.currentStatus == "Data File Ready" || ngsdata.currentStatus == "Analysis Data And File Transferred" ?*/}
                                                                        {/*                                    <td key={i}>*/}
                                                                        {/*                                        */}{/* <br /> */}
                                                                        {/*                                        {data.dataFileStatus ?*/}
                                                                        {/*                                            <React.Fragment>*/}
                                                                        {/*                                                */}{/* <br /> */}{/* &nbsp;*/}
                                                                        {/*                                                <Link to={"/laboratory/datafile/" + ngsdata.ngsLaboratoryPatientActivityId} style={{ "color": "rgb(96, 125, 139)" }} data-toggle="tooltip" title="Upload Datafile"*/}
                                                                        {/*                                                // style={{ "marginTop": "5px" }} className="btn btn-outline-info btn-pd"*/}
                                                                        {/*                                                >*/}
                                                                        {/*                                                    */}{/* Upload Datafile */}
                                                                        {/*                                                    <i class="fa fa-upload fa-lg" aria-hidden="true"></i>*/}
                                                                        {/*                                                </Link>*/}
                                                                        {/*                                            </React.Fragment>*/}
                                                                        {/*                                            : null}*/}
                                                                        {/*                                        <br />*/}
                                                                        {/*                                        {ngsdata.sampleRefNo != null && ngsdata.sampleRefNo != "" ?*/}
                                                                        {/*                                            <React.Fragment>*/}
                                                                        {/*                                                <span>Ref: <b>{ngsdata.sampleRefNo}</b></span>*/}
                                                                        {/*                                                <br />*/}
                                                                        {/*                                            </React.Fragment>*/}
                                                                        {/*                                            : null}*/}
                                                                        {/*                                        <span className="kt-shape-bg-color-3" style={{ "padding": "2px 4px", "color": "#fff" }}>{ngsdata.currentStatus}</span>*/}

                                                                        {/*                                    </td>*/}
                                                                        {/*                                    :*/}
                                                                        {/*                                    <td key={i}>*/}
                                                                        {/*                                        {ngsdata.sampleRefNo != null && ngsdata.sampleRefNo != ""  ?*/}
                                                                        {/*                                            <React.Fragment>*/}
                                                                        {/*                                                <span>Ref: <b>{ngsdata.sampleRefNo}</b></span>*/}
                                                                        {/*                                                <br />*/}
                                                                        {/*                                            </React.Fragment>*/}
                                                                        {/*                                            : null}*/}

                                                                        {/*                                        <span className="kt-shape-bg-color-3" style={{ "padding": "2px 4px", "color": "#fff" }}>{ngsdata.currentStatus}</span>*/}
                                                                        {/*                                    </td>)*/}
                                                                        {/*                                :*/}
                                                                        {/*                                /*<td key={i} className="tdCenter">Not Available.</td>*/}
                                                                        {/*                                null*/}
                                                                        {/*                            ))*/}
                                                                        {/*                    })*/}
                                                                        {/*            )) :*/}
                                                                        {/*        (data.ngsLaboratoryPatientActivity.length <= 0 && sampletypes.length > 0 ?*/}
                                                                        {/*            sampletypes*/}
                                                                        {/*                .map((sdata, j) => {*/}
                                                                        {/*                    return (*/}
                                                                        {/*                        <td key={j}>Not Available</td>*/}
                                                                        {/*                    )*/}
                                                                        {/*                })*/}
                                                                        {/*            : (null)))*/}
                                                                        {/*    //:(null))*/}
                                                                        {/*}*/}

                                                                        {/* 
                                                                         * old status code 1
                                                                            (data.patientRefNo != "" && data.patientRefNo != null ?
                                                                                (data.ngsLaboratoryPatientActivity.length > 0 && data.patientSample.length > 0 ? (
                                                                                    data.patientSample
                                                                                        .map((sdata, j) =>
                                                                                            data.ngsLaboratoryPatientActivity
                                                                                                .map((ngsdata, n) => {
                                                                                                    return (
                                                                                                        (sdata.sampleTypeId == ngsdata.sampleTypeId ?
                                                                                                            <td key={i}><span className="kt-shape-bg-color-3" style={{ "padding": "4px", "color": "#fff" }}>{ngsdata.currentStatus}</span></td>
                                                                                                            : <td key={i}>Not Available</td>
                                                                                                        ))
                                                                                                })
                                                                                        )) :
                                                                                    (data.ngsLaboratoryPatientActivity.length <= 0 && data.patientSample.length > 0 ?
                                                                                        data.patientSample
                                                                                            .map((sdata, j) => {
                                                                                                return (
                                                                                                    <td key={j}>Not Available</td>
                                                                                                )
                                                                                            })
                                                                                        : (null))) :
                                                                                (null))
                                                                        */}
                                                                    </tr>);
                                                                })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={tablecolumnLength} className="tdCenter">No patients found.</td>
                                                            </tr>
                                                            // )) : (
                                                            // <tr>
                                                            //     <td colSpan={tablecolumnLength} className="tdCenter">Loading...</td></tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            {pagesCount > 0 ?
                                                <Pagination aria-label="Page navigation example" className="customPagination">
                                                    <PaginationItem disabled={currentIndex - 4 <= 0}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage - 5, currentIndex - 5)} previous href="#">
                                                            Prev
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(pagesCount)].slice(currentIndex, currentIndex + 5).map((page, i) =>
                                                        <PaginationItem active={currentIndex + i === currentPage} key={currentIndex + i}>
                                                            <PaginationLink onClick={e => this.handleClick(e, currentIndex + i, currentIndex)} href="#">
                                                                {currentIndex + i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    )}
                                                    <PaginationItem disabled={currentIndex + 5 >= pagesCount}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage + 5, currentIndex + 5)} next href="#">
                                                            Next
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {modal &&
                        <div style={{ marginLeft: "36%" }}>
                            <Modal isOpen={modal} style={{ width: "500px" }} >
                                <ModalHeader>Confirm</ModalHeader>
                                <ModalBody>Are you sure want to delete this invoice?</ModalBody>
                                <ModalFooter>
                                    <Button outline color="danger" onClick={e => this.DeleteInvoice(e, deleteId)}>Delete</Button>
                                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    }

                    <Modal isOpen={show} className="modal-dialog modal-sm">
                        <ModalHeader>
                            Add Patient Ref number
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Reference No:</label>
                                <input type="text" className="form-control" value={patientRefNo} name="patientRefNo" id="patientRefNo" onChange={this.handleInputChange.bind(this)} />
                                {errors.patientRefNo.length > 0 && <span className='error'>{errors.patientRefNo}</span>}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleClose}>
                                Close
                            </Button>
                            <Button color="primary" onClick={this.AddPatientRefNo.bind(this)}>
                                Add
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={showInvoice} className="modal-dialog modal-lg">
                        <ModalHeader>
                            Upload Invoice
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Invoice No</label>
                                <Input type="text" name="invoiceNo" id="invoiceNo" className="form-control" tabIndex="1" onChange={this.handleInvoiceInputChange.bind(this)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Invoice File</label>
                                <Input type="file" name="invoicefile" id="File" className="form-control" multiple="multiple" tabIndex="2" onChange={this.handleFileInputChange.bind(this)} accept=".pdf,.doc,.docx" />
                            </div>
                            {showInvoiceErr != "" &&
                                <div>
                                    <span className='error'>{showInvoiceErr}</span>
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleInvoiceClose}>
                                Close
                            </Button>
                            <Button color="primary" onClick={this.UpdateInvoice.bind(this)}>
                                Add
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={showSample} className="modal-dialog modal-sm">
                        <ModalHeader>
                            Sample Ref No
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group">
                                <label htmlFor="recipient-name" className="form-control-label">Sample Ref No</label>
                                <Input type="test" name="SampleRefNo" id="SampleRefNo" className="form-control" tabIndex="1" onChange={this.handleSampleInputChange.bind(this)} />
                            </div>
                            {showSampleErr != "" &&
                                <div>
                                    <span className='error'>{showSampleErr}</span>
                                </div>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleSampleClose}>
                                Close
                            </Button>
                            <Button color="primary" onClick={this.UpdateSample.bind(this)}>
                                Add
                            </Button>
                        </ModalFooter>
                    </Modal>

                    {this.loading()}
                </div>
                {preview &&
                    <>
                        <div className='preview-popup'>
                            <div className='preview-popup-modal'>
                                <div className='preview-popup-header'>
                                    {url.split(".").splice(-1)[0] === "pdf" ? null :
                                        // <a href={url} download target={`_blank`}>
                                        <img src={downloadIcon} style={{ margin: "0 12px", cursor: "pointer" }} alt='download' onClick={e => this.DownloadFile(e, url, number)} />
                                        // </a>
                                    }
                                    <img src={closeIcon} style={{ cursor: "pointer" }} alt='close' onClick={e => this.previewToggle(e, "", "")} />
                                </div>
                                <iframe src={url} title="previewFile" width="100%" height="90%" />
                            </div>
                        </div>
                    </>
                }

            </React.Fragment >
        );
    }
}
