import React, { Component } from 'react';
import {
    Collapse, Table, Pagination, PaginationItem, Row,
    PaginationLink, Fade, Col, Card, CardHeader, CardBody, Button, 
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
//import MyModal from './../../CustomModal/CustomModal';
import Moment from 'react-moment';
import { toast } from 'react-toastify';

export class InstituteDashboard extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            patients: [],
            // authError: false,
            error: '',
            errorType: '',
            patientId: "",
            patientdiseaseId: "",
            Alldiseases: [],
            showdisease: false,
            errors: {
                patientdiseaseId: '',
            },
            diseaseNotCount: 0,
            currentPage: 0,
            currentIndex: 0,
            pagesCount: 0,
            pageSize: window.$TotalRecord,
            collapseId: 0
        };
        this.state = this.initialState;

    }

    componentDidMount() {
        this.getListData(0);
    }

    getListData(pageNo) {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.instituteId == null ? 0 : userToken.instituteId);
        }
        else {
            return <Redirect to="/" />
        }

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/InstitutePatient/getInstitutePatientsPaging';

        let data = JSON.stringify({
            isDeleted: true,
            searchString: '',
            Id: parseInt(id),
            pageNo: pageNo,
            totalNo: window.$TotalRecord,
        });

        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    
                    this.setState({
                        patients: result.data.outdata.institutePatientData,
                        Alldiseases: result.data.outdata.diseaseData,
                        pagesCount: Math.ceil(result.data.totalRecord / window.$TotalRecord),
                        loading: false
                    });

                    //let rData = result.data.outdata.institutePatientData;
                    //let noDiseaseCount = rData.filter(
                    //    ml => ml.diseaseName == "" || ml.diseaseName == null
                    //);
                    //this.setState({
                    //    diseaseNotCount: noDiseaseCount.length
                    //});
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //pagination
    handleClick(e, index, currIndex) {
        e.preventDefault();
        var pgcount = this.state.pagesCount - 1;
        var pgCurr = (index >= pgcount ? pgcount : index);
        this.setState({
            loading: true,
            currentPage: pgCurr,
            currentIndex: currIndex
        }, function () { this.getListData(pgCurr); });
    }

    //delete(active/inactive) button click
    deleteRow(e, id) {
        e.preventDefault();

        this.setState({ loading: true });
        let uid = 0;

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/InstitutePatient/DeletePatient?id=' + id + '&userId=' + uid + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false,
                    }, this.getListData(0));
                    toast.success(result.data.message)
                }
                else {
                    this.setState({
                        // authError: true,
                        // errorType: 'danger',
                        // error: result.data.message,
                        loading: false
                    });
                    toast.error(result.data.message)
                }
            })
            .catch(error => {
                //console.log(error);
                this.setState({
                    // authError: true, errorType: 'danger', error: error.message, 
                    loading: false
                });
                toast.error(error.message)
            });
    }

    //add disease
    handleCloseDisease = () => {
        this.setState({
            showdisease: false
        });
    }

    handleShowDisease = (pid) => {
        this.setState({
            patientId: pid,
            showdisease: true,
        });
    }

    handleDiseaseInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        switch (name) {
            case 'patientdiseaseId':
                errors.patientdiseaseId = (!value) ? "Please select disease." : '';
                break;
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors, [name]: value }, () => {

        })
    }

    AddPatientDisease(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let errors = this.state.errors;

        let uid = 0;
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            uid = (userToken.userId == null ? 0 : userToken.userId);
        }

        if (this.state.patientdiseaseId != null && this.state.patientdiseaseId != "") {
            const apiroute = window.$APIPath;
            let url = apiroute + '/api/PatientDisease/Save';

            let data = JSON.stringify({
                PatientId: parseInt(this.state.patientId),
                DiseaseId: parseInt(this.state.patientdiseaseId),
                PatientDiseaseId: 0,
                createdBy: uid,
                createdByFlag: "I"
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(result => {
                if (result.data.flag) {
                    this.setState({
                        // authError: true,
                        // errorType: 'success',
                        // error: result.data.message,
                        loading: false,
                        showdisease: false,
                        patientdiseaseId: '',
                        patientId: ''
                    }, this.getListData(0));
                    toast.success(result.data.message)
                }
                else {
                    errors.patientdiseaseId = result.data.message;
                    this.setState({ loading: false });
                }
            })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false, showdisease: false
                    });
                    toast.error(error.message)
                });
        }
        else {
            errors.patientdiseaseId = "Please select disease.";
            this.setState({ loading: false });
        }
    }
    //end add disease

    setCollapse(cid) {
        let currentCid = this.state.collapseId;
        if (currentCid == cid) {
            this.setState({ collapseId: -1 });
        }
        else {
            this.setState({ collapseId: cid });
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, patients, error, errorType, authError, diseaseNotCount, errors, Alldiseases,
            patientdiseaseId, patientId, showdisease, currentPage, currentIndex, pagesCount, pageSize, collapseId } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                
                                   <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3 className="kt-subheader__title">
                                            Dashboard
                           </h3>
                                            {/* <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link href="#" className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                <span className="kt-subheader__breadcrumbs-separator"></span>
                                                <Link to="/institute/dashboard" className="kt-subheader__breadcrumbs-link">
                                                    Dashboard                       </Link>
                                            </div> */}
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/institute/patient/detail" className="btn btn-primary btnBorder">
                                                Add Patient
                                            </Link>
                                        </div>
                                    </div>
                              
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        {/* {authError ?
                                            <div>
                                                <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                    <div className="alert-text">{error}</div>
                                                    <div className="alert-close">
                                                        <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : null} */}
                                        <div className="kt-portlet__body">
                                            {
                                            // !loading ? (
                                                patients.length > 0 ? (
                                                    patients.map((data, i) => {
                                                        return (
                                                            <Col xs="12" sm="12" md="12" style={{ "margin-top": "20px " }}>
                                                                <Card style={{ "border": "1px solid #1C3A84" }}>
                                                                    <CardHeader style={{ "backgroundColor": "#1C3A84", "color": "white" }} >
                                                                        <Row style={{ "fontSize": "16px" }} key={i} onClick={() => this.setCollapse(i)}>
                                                                            <Col md="6">
                                                                                <b>
                                                                                    <span>{data.firstName + " " + (data.middleName != null && data.middleName != "" ? "(" + data.middleName + ")" : "") + data.lastName + "(" + data.email + ")"}</span>
                                                                                    <br /><span>{data.mobile}</span>
                                                                                </b>
                                                                            </Col>
                                                                            <Col md="5">
                                                                                {data.dateOfBirth == null ? "" :
                                                                                    <Moment format="Do MMMM YYYY">{data.dateOfBirth}</Moment>}<br /><b>{data.dateOfBirth == null ? "" : "" + data.age + " Years"}</b>
                                                                            </Col>
                                                                            <Col md="1">
                                                                                <Link style={{ "color": "white" }} to={'/institute/patient/modify/' + data.institutePatientId}><i className="flaticon2-edit" /></Link>{" "}
                                                                            </Col>
                                                                        </Row>
                                                                    </CardHeader>
                                                                    <Fade timeout={this.state.timeout} in={this.state.fadeIn}>
                                                                        <Collapse isOpen={i == collapseId} id="collapseExample">
                                                                            <CardBody>
                                                                                <Row>
                                                                                    <Table responsive bordered >
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Accession No</th>
                                                                                                <th>Disease</th>
                                                                                                {/*<th>Health Index Report</th>*/}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {data.patientAccessionMappings.length > 0 ? (
                                                                                                data.patientAccessionMappings
                                                                                                    .map((adata, i) => {
                                                                                                        return (<tr key={i}>
                                                                                                            <td>
                                                                                                                {adata.accessionNo != null && adata.accessionNo != "" ?
                                                                                                                    <Link to={'/institute/patient/profile/' + adata.patientAccessionId}><b>{adata.accessionNo.replace(/-/g, "")}</b></Link>
                                                                                                                    :
                                                                                                                    "Not Assigned"
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {adata.diseaseName != null && adata.diseaseName != "" ?
                                                                                                                    adata.diseaseName
                                                                                                                    :
                                                                                                                    "-"
                                                                                                                    //                                                                          <Button className="btn btn-bold btn-label-brand btn-sm" onClick={() => this.handleShowDisease(adata.patientId)}>
                                                                                                                    //                                                                              Add Disease
                                                                                                                    //</Button>
                                                                                                                }
                                                                                                            </td>
                                                                                                            {/*<td>*/}
                                                                                                                {/*<Link to={'/practitioner/patient/modify/' + adata.practitionerPatientId}><i className="flaticon2-edit" /></Link>{" "}*/}
                                                                                                                {/*<Link to="#" onClick={e => this.deleteRow(e, adata.practitionerPatientId)}><i className="flaticon2-delete" /></Link>*/}
                                                                                                            {/*</td>*/}
                                                                                                        </tr>);
                                                                                                    })
                                                                                            ) : (
                                                                                                <tr>
                                                                                                    <td colSpan="4" className="tdCenter">No accession no generated..</td></tr>
                                                                                            )}
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </Row>
                                                                            </CardBody>
                                                                        </Collapse>
                                                                    </Fade>
                                                                </Card>
                                                            </Col>
                                                        );
                                                    })
                                                ) : (
                                                    <Col xs="12" sm="12" md="12" style={{ "fontSize": "1.2rem", "textAlign": "center", "margin": "15px " }}>No patient assigned or added..</Col>
                                                // )) : (
                                                // <Col xs="12" sm="12" md="12" style={{ "fontSize": "1.2rem", "textAlign": "center", "margin": "15px " }}>Loading....</Col>
                                            )}
                                            <br />

                                            {pagesCount > 0 ?
                                                <Pagination aria-label="Page navigation example" className="customPagination">
                                                    <PaginationItem disabled={currentIndex - 4 <= 0}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage - 5, currentIndex - 5)} previous href="#">
                                                            Prev
                    </PaginationLink>
                                                    </PaginationItem>
                                                    {[...Array(pagesCount)].slice(currentIndex, currentIndex + 5).map((page, i) =>
                                                        <PaginationItem active={currentIndex + i === currentPage} key={currentIndex + i}>
                                                            <PaginationLink onClick={e => this.handleClick(e, currentIndex + i, currentIndex)} href="#">
                                                                {currentIndex + i + 1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    )}
                                                    <PaginationItem disabled={currentIndex + 5 >= pagesCount}>
                                                        <PaginationLink onClick={e => this.handleClick(e, currentPage + 5, currentIndex + 5)} next href="#">
                                                            Next
                    </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination> : null}
                                            {/*{diseaseNotCount > 0 ?
                                                <span className='error' style={{ "textAlign": "center" }}>Add disease to generate patient accession number*</span>
                                                :""}*/}
      {/*                                      <table className="table table-striped- table-bordered table-hover table-checkable" id="kt_table_1">*/}
      {/*                                          <thead>*/}
      {/*                                              <tr>*/}
      {/*                                                  <th>Patient</th>*/}
      {/*                                                  <th>Diesease</th>*/}
      {/*                                                  <th>Email <br /> Mobile</th>*/}
      {/*                                                  <th>Date of Birth <br /> Age</th>*/}
      {/*                                                  <th>Action</th>*/}
      {/*                                              </tr>*/}
      {/*                                          </thead>*/}
      {/*                                          <tbody>*/}
      {/*                                              {!loading ? (*/}
      {/*                                                  //employees.map(function (data,i) {*/}
      {/*                                                  patients.length > 0 ? (*/}
      {/*                                                      patients*/}
      {/*                                                          .map((data, i) => {*/}
      {/*                                                              return (<tr key={i}>*/}
      {/*                                                                  <td>*/}
      {/*                                                                      {data.accessionNo != null && data.accessionNo != "" ?*/}
      {/*                                                                          <Link to={'/institute/patient/profile/' + data.patientId}>{data.accessionNo}</Link>*/}
      {/*                                                                          :*/}
      {/*                                                                          "Not Assigned"*/}
      {/*                                                                      }*/}
      {/*                                                                      <br />*/}
      {/*                                                                      {data.firstName} {data.middleName != null && data.middleName != "" ? "(" + data.middleName + ")" : ""} {"(" + data.lastName + ")"}*/}
      {/*                                                                  </td>*/}
      {/*                                                                  */}{/*<td>{data.firstName} {data.middleName != null && data.middleName != "" ? "(" + data.middleName + ")" : ""} {"(" + data.lastName + ")"} </td>*/}
      {/*                                                                  <td>*/}
      {/*                                                                      {data.diseaseName != null && data.diseaseName != "" ?*/}
      {/*                                                                          data.diseaseName*/}
      {/*                                                                          :*/}
      {/*                                                                          <Button className="btn btn-bold btn-label-brand btn-sm" onClick={() => this.handleShowDisease(data.patientId)}>*/}
      {/*                                                                              Add Disease*/}
      {/*</Button>*/}
      {/*                                                                      }*/}
      {/*                                                                      <br />*/}
      {/*                                                                      {data.diseaseCode != null && data.diseaseCode != "" ?*/}
      {/*                                                                          "(" + data.diseaseCode + ")"*/}
      {/*                                                                          : ""}</td>*/}
      {/*                                                                  <td>{data.email}<br />{data.mobile}</td>*/}
      {/*                                                                  <td>*/}
      {/*                                                                      {data.dateOfBirth == null ? "" :*/}
      {/*                                                                          <Moment format="DD MMM YYYY">{data.dateOfBirth}</Moment>}<br />{data.dateOfBirth == null ? "" :"(" + data.age + ")"}*/}
      {/*                                                                  </td>*/}
      {/*                                                                  <td>*/}
      {/*                                                                      <Link to={'/institute/patient/modify/' + data.institutePatientId}><i className="flaticon2-edit" /></Link>{" "}*/}
      {/*                                                                      */}{/*<Link to="#" onClick={e => this.deleteRow(e, data.institutePatientId)}><i className="flaticon2-delete" /></Link>*/}
      {/*                                                                  </td>*/}
      {/*                                                              </tr>);*/}
      {/*                                                          })*/}
      {/*                                                  ) : (*/}
      {/*                                                          <tr>*/}
      {/*                                                              <td colSpan="5" className="tdCenter">No patients.</td></tr>*/}
      {/*                                                      )) : (*/}
      {/*                                                      <tr>*/}
      {/*                                                          <td colSpan="5" className="tdCenter">Loading...</td></tr>*/}
      {/*                                                  )}*/}
      {/*                                          </tbody>*/}
      {/*                                      </table>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                    </div>

          {/*          <Modal isOpen={showdisease} className="modal-dialog modal-sm">*/}
          {/*              <ModalHeader>*/}
          {/*                  Add Disease*/}
          {/*              </ModalHeader>*/}
          {/*              <ModalBody>*/}
          {/*                  <div className="form-group">*/}
          {/*                      <label htmlFor="recipient-name" className="form-control-label">Disease</label>*/}
          {/*                      <Input type="select" className="custom-select mb-3" name="patientdiseaseId" value={patientdiseaseId} onChange={this.handleDiseaseInputChange.bind(this)}>*/}
          {/*                          <option value="">Select Disease</option>*/}
          {/*                          {Alldiseases*/}
          {/*                              .map((data, i) => {*/}
          {/*                                  return (<option key={i} value={data.diseaseId}>{data.diseaseName}</option>);*/}
          {/*                              })}*/}
          {/*                      </Input>*/}
          {/*                      {errors.patientdiseaseId.length > 0 && <span className='error'>{errors.patientdiseaseId}</span>}*/}
          {/*                  </div>*/}
          {/*              </ModalBody>*/}
          {/*              <ModalFooter>*/}
          {/*                  <Button color="secondary" onClick={this.handleCloseDisease}>*/}
          {/*                      Close*/}
          {/*</Button>*/}
          {/*                  <Button color="primary" onClick={this.AddPatientDisease.bind(this)}>*/}
          {/*                      Add*/}
          {/*</Button>*/}
          {/*              </ModalFooter>*/}
          {/*          </Modal>*/}
                </div>
                {this.loading()}

            </React.Fragment >
        );
    }
}
