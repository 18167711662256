import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, Form, NavLink, Input, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import MyModal from './../../CustomModal/CustomModal';
import { toast } from 'react-toastify';

export class PatientTreatment extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            ptreatment: [],
            slDelete: false,
            authError: false,
            error: '',
            modal: false,
            modalTitle: '',
            modalBody: '',
            deleteId: ''
        };
        this.state = this.initialState;
        this.toggle = this.toggle.bind(this);

    }

    //modal close button event
    handleModalClose = () => {
        this.setState({
            modal: false,
            modalTitle: '',
            modalBody: ''
        });
    }

    componentDidMount() {
        this.getListData();
    }

    //get detail(for update)
    getListData() {
        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        let id = 0;
        if (userToken != null) {
            id = (userToken.patientId == null ? 0 : userToken.patientId);
        }

        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientTreatment/GetByPatientId?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        ptreatment: result.data.outdata, loading: false
                    });
                    //console.log(this.state);
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, authError: true, error: error });
            });
    }

    //delete(active/inactive) button click
    deleteRow(e, id) {
        e.preventDefault();

        this.setState({ loading: true });
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PatientTreatment/Delete?id=' + id + '';

        axios.delete(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({
                        loading: false,
                        // modal: !this.state.modal,
                        // modalTitle: 'Success',
                        // modalBody: result.data.message
                    });
                    toast.success(result.data.message)
                    this.getListData();
                    this.toggle()
                }
            })
            .catch(error => {
                //console.log(error);
                // this.setState({
                //     modal: !this.state.modal,
                //     modalTitle: 'Error',
                //     modalBody: error.message
                // });
                toast.error(error.message)
                this.setState({ loading: false, authError: true, error: error });
            });
    }

    toggle(e, id) {
        this.setState({
          modal: !this.state.modal,
          deleteId: id
        });
      }

    render() {
        const { loading, ptreatment, authError, error, modal, deleteId } = this.state;

        return (

            <div className="mt-100">
                <div className="profile-pg">
                    <div className="container-fluid">
                      
                        <div className="row">
                            <div className="col-md-3">
                                <div className="user-profile-inner">
                                    <div className="list-group ">

                                        <a href="/patient/dashboard" className="list-group-item list-group-item-action">Dashboard</a>
                                        <a href="/patient/profile" className="list-group-item list-group-item-action">Patient Information</a>
                                        <a href="/patient/diagnostic/list" className="list-group-item list-group-item-action">Diagnostic Information</a>
                                        <a href="/patient/disease/list" className="list-group-item list-group-item-action">Disease Information</a>
                                        <a href="/patient/treatment/list" className="list-group-item list-group-item-action active">Treatment Information</a>
                                        <a href="/patient/prescription/list" className="list-group-item list-group-item-action">Prescription Information</a>
                                        <a href="/patient/emergency/list" className="list-group-item list-group-item-action">Emergency Contact</a>
                                        <a href="/patient/insurance/list" className="list-group-item list-group-item-action">Insurance Information</a>
                                        <a href="/patient/payment/detail" className="list-group-item list-group-item-action">Payment Details</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="user-profile-inner">
                                    <div className="widgets-main bootstrap snippet p-3 m-0">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <h4>Patient Treatment List</h4>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <a href="/patient/treatment/detail" className="primary-btn addButton">Add</a>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <Table responsive bordered key="tblStandards">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Treatment Date</th>
                                                                            <th>Detail</th>
                                                                            <th>Description</th>
                                                                            <th className="thNone">Id</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                        // !loading ? (
                                                                            //standards.map(function (data,i) {
                                                                            ptreatment.length > 0 ? (
                                                                                ptreatment
                                                                                    .map((data, i) => {
                                                                                        return (<tr key={i}>
                                                                                            <td>{data.treatmentDate == null ? "" : data.treatmentDate.slice(0, 10)}</td>
                                                                                            <td>{data.treatmentDetail}</td>
                                                                                            <td>{data.teatmentDescription}</td>
                                                                                            <td className="thNone">{data.PatientTreatmentReportId}</td>
                                                                                            <td>
                                                                                                <Link to={'/patient/treatment/modify/' + data.patientTreatmentReportId}><Button outline color="info" size="sm"><i className="fa fa-edit" />Edit</Button></Link>{" "}
                                                                                                <Link to="#" onClick={e => this.toggle(e, data.patientTreatmentReportId)}><Button outline color="danger" size="sm"><i className="fa fa-trash" />Delete</Button></Link>
                                                                                            </td>
                                                                                        </tr>);
                                                                                    })
                                                                            ) : (
                                                                                    <tr>
                                                                                        <td colSpan="3" className="tdCenter">No treatment information found.</td></tr>
                                                                                // )) : (
                                                                                // <tr>
                                                                                //     <td colSpan="3" className="tdCenter">Loading...</td></tr>
                                                                            )}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {modal && 
                        <div style={{ marginLeft: "36%" }}>    
                            <Modal isOpen={modal} style={{ width: "500px" }} >
                                <ModalHeader>Confirm</ModalHeader>
                                <ModalBody>Are you sure want to delete this treatment?</ModalBody>
                                <ModalFooter>
                                    <Button outline color="danger" onClick={e => this.deleteRow(e, deleteId)}>Delete</Button>
                                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    }
                {/* <MyModal
                    handleModal={this.handleModalClose.bind(this)}
                    //modalAction={this.state.modalAction}
                    isOpen={this.state.modal}
                    modalBody={this.state.modalBody}
                    modalTitle={this.state.modalTitle}
                    modalOptions={this.state.modalOptions}
                /> */}
            </div>
        );
    }
}
