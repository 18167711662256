import React, { Component } from 'react';
import { Form, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import '../../../custom.css'
import '../../../style-new.css'
import axios from 'axios';
import { toast } from 'react-toastify';
//import MyModal from './../../CustomModal/CustomModal';

export class PractitionerMedicalLicenseDetail extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            loading: true,
            practitionerId: 0,
            practitionerMedicalId: 0,
            licenseNo: "",
            countryId: "",
            countries: [],
            stateId: "",
            states: [],
            redirect: false,
            // authError: false,
            error: '',
            errorType: '',
            errors: {
                licenseNo: '',
                countryId: '',
                stateId: '',
            },
        };
        this.state = this.initialState;

    }

    ////modal close button event
    //handleModalClose = () => {
    //    this.setState({
    //        modal: false,
    //        modalTitle: '',
    //        modalBody: ''
    //    });
    //    if (this.state.redirect) {
    //        this.props.history.push('/patient/insurance/list');
    //    }
    //}

    componentDidMount() {
        const param = this.props.match.params;

        if (param.id != undefined) {
            this.getData(param.id);
        }
        else {
            this.setState({ loading: false });
        }

        var userToken = JSON.parse(localStorage.getItem('Usertoken'));
        if (userToken != null) {
            this.getCountry();
        }
        else {
            return <Redirect to="/" />
        }
    }

    getCountry() {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/CognitoUserStore/getallcountry';
        let data = JSON.stringify({
            isDeleted: false,
            searchString: ''
        });
        axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(result => {
            if (result.data.flag) {
                this.setState({
                    countries: result.data.outdata
                });
            }
            else {
                this.setState({ loading: false });
            }
        }).catch(error => {
            this.setState({ loading: false });
        });
    }

    //get detail(for update)
    getData(id) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/PractitionerMedical/GetById?id=' + id;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    var rData = result.data.outdata;
                    this.setState({
                        practitionerId: rData.practitionerId, 
                        licenseNo: rData.licenseNo, 
                        countryId: rData.countryId,
                        stateId: rData.stateId, 
                        practitionerMedicalId: rData.practitionerMedicalId,
                        loading: false, 
                    }, () => {
                        if (rData.countryId != null) {
                            this.getStateData(rData.countryId, rData.stateId)
                        }
                        else {
                            this.setState({ loading: false });
                        }
                    });
                } else { this.setState({ loading: false }); }
            })
            .catch(error => {
                this.setState({ loading: false });
            });
    }

    getStateData(countryId, stateId) {
        const apiroute = window.$APIPath;
        const url = apiroute + '/api/CognitoUserStore/getallstate?Id=' + countryId;

        axios.post(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(result => {
                if (result.data.flag) {
                    this.setState({ states: result.data.outdata, stateId: stateId });
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                this.setState({ loading: false });
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let errors = this.state.errors;

        const validNumericRegex = RegExp(/^[0-9]+$/);

        switch (name) {
            
            case 'licenseNo':
                errors.licenseNo = (!value) ? 'Please enter licenseNo.' : (validNumericRegex.test(value) ? '' : 'Only numbers allowed.');
                this.setState({ licenseNo: value.replace(/[^0-9]+$/, '') })
                break;
            
            default:
                //(!value) ? '' :'This standard is required.'
                break;
        }


        this.setState({ errors }, () => {

            if (name == 'countryId') {
                if (value != '') {
                    this.getStateData(value, "");
                }
                else {
                    this.setState({ states: [], stateId: "" });
                }
            }

        })
    }

    //form validation
    validateForm = (errors) => {
        let valid = true;

        if (this.state.licenseNo == undefined || this.state.licenseNo == '') {
            errors.licenseNo = 'Please enter licenseNo.';
        }
        
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        let url = "";

        if (this.validateForm(this.state.errors)) {
            var userToken = JSON.parse(localStorage.getItem('Usertoken'));
            let pid = (userToken.practitionerId == null ? 0 : userToken.practitionerId);
            let uid = (userToken.userId == null ? 0 : userToken.userId);

            const apiroute = window.$APIPath;
            if (this.state.practitionerMedicalId == 0) {
                url = apiroute + '/api/PractitionerMedical/Save';
            }
            else {
                url = apiroute + '/api/PractitionerMedical/Update';
            }

            let data = JSON.stringify({
                practitionerId: parseInt(pid),
                licenseNo: this.state.licenseNo,
                countryId: ((this.state.countryId == "" || this.state.countryId == 0) ? null : parseInt(this.state.countryId)),
                stateId: ((this.state.stateId == "" || this.state.stateId == 0) ? null : parseInt(this.state.stateId)),
                practitionerMedicalId: parseInt(this.state.practitionerMedicalId),
                createdBy: parseInt(uid),
            })

            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(result => {
                    if (result.data.flag) {
                        this.setState({
                            // authError: true,
                            // errorType: 'success',
                            // error: result.data.message,
                            loading: false,
                            redirect: true
                        });
                        toast.success(result.data.message)
                    }
                    else {
                        this.setState({
                            // authError: true,
                            // errorType: 'danger',
                            // error: result.data.message,
                            loading: false
                        });
                        toast.error(result.data.message)
                    }
                })
                .catch(error => {
                    this.setState({
                        // authError: true, errorType: 'danger', error: error.message, 
                        loading: false
                    });
                    toast.error(error.message)
                    //this.setState({ authError: true, error: error });
                });
        } else {
            this.setState({ loading: false });
        }
    }

    renderRedirect() {
        if (this.state.redirect) {
            return <Redirect from="/" to="/practitioner/medical/list" />
        }
    }

    loading() {
        if (this.state.loading) {
            return <div id="loader-wrapper">
                <div id="loader"></div>
                <div className="loader-section section-left"></div>
                <div className="loader-section section-right"></div>
            </div>;
        }
    }

    render() {
        const { loading, licenseNo, states, countries, countryId, stateId, errors, error, errorType, authError } = this.state;

        return (
            <React.Fragment>
                <div className="kt-grid kt-grid--hor kt-grid--root">
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                        <button className="kt-aside-close " id="kt_aside_close_btn"><i className="la la-close"></i></button>
                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                                <div className="kt-subheader   kt-grid__item" id="kt_subheader">
                                    <div className="kt-container  kt-container--fluid ">
                                        <div className="kt-subheader__main">
                                            <h3>
                                                <Link to="/practitioner/medical/list" className="kt-subheader__title">
                                                    Medical License
                                                </Link>
                                            </h3>
                                            <span className="kt-subheader__separator kt-hidden"></span>
                                            <div className="kt-subheader__breadcrumbs">
                                                <Link className="kt-subheader__breadcrumbs-home"><i className="flaticon2-arrow"></i></Link>
                                                {/*<span className="kt-subheader__breadcrumbs-separator"></span>*/}
                                                <Link to="/practitioner/medical/detail" className="kt-subheader__breadcrumbs-link">
                                                    Medical License Detail                      
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="kt-subheader__main">
                                            <Link to="/practitioner/medical/list" className="btn btn-primary btnBorder">
                                                Medical License
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__body">
                                            <Form onSubmit={this.handleSubmit.bind(this)}>
                                                {/* {authError ?
                                                    <div>
                                                        <div className={"alert alert-" + errorType + " alert-dismissible"} role="alert">
                                                            <div className="alert-text">{error}</div>
                                                            <div className="alert-close">
                                                                <i className="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null} */}

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">License no.<span style={{ "color": "red", "font-size": "16px", "margin-left": "3px" }}>*</span></label>
                                                                <div className="col-12">
                                                                    <Input className="form-control here" type="text" maxLength="120" tabIndex="1" placeholder="Enter license no" name="licenseNo" value={licenseNo} onChange={this.handleInputChange.bind(this)} />
                                                                    {errors.licenseNo.length > 0 && <span className='error'>{errors.licenseNo}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">Country</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="2" name="countryId" value={countryId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select Country</option>
                                                                        {countries
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.countryId.length > 0 && <span className='error'>{errors.countryId}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-4">
                                                            <div className="col-md-6">
                                                                <label className="col-12 col-form-label">State</label>
                                                                <div className="col-12">
                                                                    <Input type="select" className="custom-select mb-3" tabIndex="3" name="stateId" value={stateId} onChange={this.handleInputChange.bind(this)}>
                                                                        <option value="">Select State</option>
                                                                        {states
                                                                            .map((data, i) => {
                                                                                return (<option key={i} value={data.id}>{data.name}</option>);
                                                                            })}
                                                                    </Input>
                                                                    {errors.stateId.length > 0 && <span className='error'>{errors.stateId}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {loading ?
                                                            <button tabIndex="4" type="submit" className="btn btn-primary kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light" disabled="disabled">Submit</button>
                                                            :
                                                            <button tabIndex="4" type="submit" className="btn btn-primary">Submit</button>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderRedirect()}
                    {this.loading()}
                </div>
            </React.Fragment >
        );
    }
}
